import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { datasets, datasetsimple } from 'src/types/event';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})
export class MarketingComponent implements OnInit{
  AccessAllowed: boolean = true;
  CompletedLoading: boolean = true;
  buttonDesc: string = '';
  basicOptions: any;
  chartData: any;
  chartData2: any;
  chartData3: any;


  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService, private globalService: GlobalService
  ) {
  }

  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);

    this.basicOptions = {
      plugins: {
          legend: {
              display: false
          }
      },
      responsive: true,
      scales: {
          y: {
              ticks: {
                  display: false,
              },
              grid: {
                  display: false
              }
          },
          x: {
              ticks: {
                  display: false,
              },
              grid: {
                  display: false
              }
          }
      }
    };

    let newLabels: string[] = [];
    newLabels.push("Emails Send:");
    newLabels.push("Emails Opened:");
    newLabels.push("Links Clicked:");

    let newData: number[] = [];
    newData.push(2045);
    newData.push(746);
    newData.push(240);

    let newDataSet: datasetsimple = {
      label: "",
      data: newData
    }

    this.chartData = {
      labels: newLabels,
      datasets: [newDataSet]
    }

    let newLabels1: string[] = [];
    newLabels1.push("Emails Send:");
    newLabels1.push("Emails Bought:");

    let newData1: number[] = [];
    newData1.push(2045);
    newData1.push(2500);

    let newDataSet2: datasetsimple = {
      label: "",
      data: newData1
    }

    this.chartData2 = {
      labels: newLabels1,
      datasets: [newDataSet2]
    }


    let newLabels2: string[] = [];
    newLabels2.push("SMS Send:");
    newLabels2.push("SMS Bought:");

    let newData2: number[] = [];
    newData2.push(1042);
    newData2.push(2500);

    let newDataSet3: datasetsimple = {
      label: "",
      data: newData2
    }

    this.chartData3 = {
      labels: newLabels2,
      datasets: [newDataSet3]
    }
  }

  buyMore()
  {
    this.router.navigateByUrl("/subscriptions");
  }

  setText(inType){
    if(inType == 'Birthday')
    {
      this.buttonDesc = "<br><b>Send a Birthday Reminder</b><br>Lorem ipsum dolor sit amet, qui an mollis insolens quaerendum. Usu cu aperiam intellegam cotidieque. Et vidit sonet lucilius eam, ei facilisis sententiae ius. Eu volumus dignissim mel, quo ea homero tincidunt. His no alia eros mucius, odio dicta in nec, sea te partem volutpat molestiae.<br><br>Sea ex quem quaeque aliquid. Populo insolens adipisci ea duo, ut exerci nullam fastidii nam, id mei luptatum rationibus liberavisse. Iisque volutpat no vis, eu clita efficiantur usu, sit ea labore everti legimus. Reque soluta tacimates qui no, ad graece malorum iuvaret eum.";
    }

    if(inType == 'Missing')
    {
      this.buttonDesc = "<br><b>Send a Message to customer who haven't been for a while</b><br>Sit et commodo nostrud, cu dicat reprehendunt sit. Veritus tacimates vel ei, ex qui vero congue appetere. Eum no intellegam conclusionemque, minim tamquam et vix. No officiis molestiae abhorreant vim.<br><br>Sed ex euismod appareat molestiae, duo ne eros wisi facer. Ea nostro interpretaris mei, nonumy civibus eos in. Ea vocent democritum ius. Sit ut noluisse ocurreret, id usu modus assentior. Eam corpora comprehensam eu, ex vix consetetur assueverit, duo eirmod audire intellegam ut.";
    }

    if(inType == 'Customers')
    {
      this.buttonDesc = "<br><b>Send a Message to a group of customers based on criteria</b><br>Vel nostrud sapientem ei. In vocent saperet ius, cu vix feugiat omittantur, eu fugit timeam eam. Ad nam munere platonem. Et impedit definiebas pro, tantas euismod accusam no quo.<br><br>Facilis ancillae adversarium qui eu, elit quas diceret eu qui. Eum at denique voluptua, eos cu partem tempor veritus. Semper imperdiet quo in, ex his rebum nostrum. In usu eirmod fabulas, ex clita honestatis pri, mei no graecis appareat honestatis. Ad pro quot munere epicuri, facete utamur nec ei, sit doming fabellas posidonium cu. Ne autem iuvaret platonem eos, pro equidem adolescens efficiantur et.";
    }

    if(inType == 'Abandoned')
    {
      this.buttonDesc = "<br><b>Send a Message to customers who didn't complete their booking</b><br>Ne duo commodo adversarium, voluptua voluptatibus ex sed, qui illud tritani constituto id. His no graecis blandit, eius placerat reprehendunt sed ne. Cum ne stet justo, pro ut reque gubergren, sea in timeam ocurreret aliquando. Mea electram consequat ex, nostrud constituto id mel, his putent consulatu no. Vix an congue elaboraret, mel nisl enim forensibus ea.<br><br>Omnes persius vis ne, id sententiae ullamcorper pro, eum no scripta philosophia. Mazim utinam nusquam ut eam. An vel tollit facilis evertitur. Adhuc ludus voluptua nec cu, laudem eirmod facilis est te. At prima reque mei. Cu animal efficiantur duo, atqui oblique urbanitas pri ei. Magna iusto mei ea, rebum zril animal no quo.";
    }

    if(inType == '')
    {
      this.buttonDesc = "";
    }
  }

  doBirthday(){
    this.router.navigateByUrl("/marketingbirthday");
  }

  doMissing(){
    this.router.navigateByUrl("/marketingmissing");
  }

  doGroup(){
    this.router.navigateByUrl("/marketinggroup");
  }

  doAbandoned(){
    this.router.navigateByUrl("/marketingabandoned");
  }

  viewMoreRevenue(){
    this.router.navigateByUrl("/marketingrevenue");
  }
  
  viewMoreEmails(){
    this.router.navigateByUrl("/marketingemails");
  }

  viewMoreEngagement(){
    this.router.navigateByUrl("/marketingengagement");
  }

}
