import { Component, OnInit } from '@angular/core';
import { bmxurl, getAccess } from 'src/globals';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AvailableTimes, Package, AvailabilityReq, Admission, SelectedAdmission, BookingReq, Package_returndata, Payment, BookingReq_returndata, Customer, Resource } from '../../PackageClasses';
import { customer } from 'src/types/customer';
import { booking, cBooking, cBookingExtras } from 'src/types/booking';
import { booking_returndata } from 'src/types/booking_returndata';
import { MessageService } from 'primeng/api';
import { cExtra } from 'src/types/extra';
import { cPackageExtra } from 'src/types/packageExtra';

import { cDepositpolicy } from 'src/types/depositpolicies';
import { formatDate } from '@angular/common';
import { cResource, resource } from 'src/types/resource';
import { v4 as uuidv4  } from 'uuid';
import { BusyService } from '../_services/busy.service';
import { admission } from 'src/types/admission';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { paymenttypes_returndata } from 'src/types/paymenttypes_returndata';
import { bookingsummary, cBookingsummary } from 'src/types/bookingsummary';
import { DomSanitizer } from '@angular/platform-browser';
import { packagegroups_returndata } from 'src/types/packagegroup_returndata';
import { cPackagegroups } from 'src/types/packagegroups';
import { cPackage } from 'src/types/package';
import { cDiscountvalidate, cOpenDiscountvalidate } from 'src/types/discountvailidate';
import { conf_returndata } from 'src/types/conf_returndata';
import { payment_returndata } from 'src/types/payment_returndata';
import { history_returndata } from 'src/types/history_returndata';
import { history } from 'src/types/history';
import { cRefund, refund } from 'src/types/refund';
import { ViewportScroller } from '@angular/common';
import { elementMatches } from '@fullcalendar/core/internal';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { users_returndata } from 'src/types/user_returndata';
import { birthdayanswers, questionanswers } from 'src/types/questionanswers';
import { bookingquestionitem } from 'src/types/bookingquestion';
import { emailtemplate_returndata } from 'src/types/emailtemplate_returndata';
import { bookingquestionnaire_returndata } from 'src/types/bookingquestionnaire_returndata';
import { bookingquestionnaire } from 'src/types/bookingquestionnaire';
import { GlobalService } from '../_services/global.service';
import { cVouchervalidate } from 'src/types/vouchervalidate';
import { activesubscriptions_returndata } from 'src/types/activesubscriptions_returndata';

@Component({
  selector: 'app-editbooking',
  templateUrl: './editbooking.component.html',
  styleUrls: ['./editbooking.component.css']
})

export class EditbookingComponent implements OnInit {

//#region Variables
  id: string;
  stateOptions: any[];
  statePayOptions: any[];
  showDiscount: boolean = false;

  PackageHeader: string = "Select a Package";
  CalendarHeader: string = "Select a Date";
  AdmissionHeader: string = "Select your Admissions";
  TimeHeader: string = "Select a Time";
  CustomerHeader: string = "Create a Customer";
  PaymentHeader: string = "Add a Payment";
  ExtrasHeader: string = "Select Extras";
  SelResourceHeader: string = "Please Select";

  packageSelected: Boolean;
  admissionsSelected: Boolean;
  dateSelected: Boolean;
  timeSelected: Boolean;
  customerSelected:Boolean;
  paymentSelected:Boolean;
  extraSelected: Boolean;
  notesSelected: Boolean;
  partyroomsSelected: Boolean;

  packageActive: Boolean;
  admissionsActive: Boolean;
  dateActive: Boolean;
  timeActive: Boolean;
  customerActive:Boolean;
  paymentActive:Boolean;
  extraActive: Boolean;
  notesActive: Boolean;
  partyroomsActive: Boolean;
  partyroomsVisible: Boolean = false;

  SelectedDate: Date;
  packages: any;
  SelectedPackage: any;
  searchCriteria: string;
  customersResult: any;
  ShowCustomerDetails: boolean = false;
  ShowCustomerSearch: boolean = false;

  BookingSummary: bookingsummary;

  VenueID: string = localStorage.getItem("SmartVenueID")!;
  NewBooking = {} as any;

  firstName: string = "";
  lastName: string = "";
  address1: string = "";
  address2: string = "";
  town: string = "";
  postCode: string = "";
  email: string = "";
  mobile: string = "";
  dob: Date = new Date(1900, 1, 1);

  firstNameValid: boolean = true;
  lastNameValid: boolean = true;
  Address1Valid: boolean = true;
  TownValid: boolean = true;
  PostCodeValid: boolean = true;
  EmailValid: boolean = true;
  MobileValid: boolean = true;
  EmailFormatValid: boolean = true;
  MobileFormatValid: boolean = true;

  firstNameEditValid: boolean = true;
  lastNameEditValid: boolean = true;
  Address1EditValid: boolean = true;
  TownEditValid: boolean = true;
  PostCodeEditValid: boolean = true;
  EmailEditValid: boolean = true;
  MobileEditValid: boolean = true;
  EmailFormatEditValid: boolean = true;
  MobileFormatEditValid: boolean = true;
  
  CustomerHasBeenEdited: boolean = false;

  AllValid: boolean =true;
  CustomerAllValid: boolean =true;

  ProvideMinimalPersonalData: boolean = false;
  OutstandingBalance: number;
  TotalBalance: number;
  PaymentButton: string = "Add New Payment";
  OBText:string = "";
  displayModalRefund: boolean = false;
  RefundPayment: number;
  RefundReason: string;
  RefundPaymentId: string;

  packageExtras: any[];
  paymentAmount: number;

  historyItems: history[];

  disgnosticsMode: boolean = true;
  Admissions: Admission[];
  SelectedAdmissions: admission[];

  packages1: any[];
  packageExtrasNew: any[];

  RefundPaymentValid: boolean = true;
  RefundTextValid: boolean = true;
  SelectedSeletableResource: any[] = [];
  SelectedSeletableResourceBool: boolean[] = [];

  customerSelectedResources: any[] = [];
  includesComplexResources: boolean = false;
  includesOptionalResources: boolean = false;

  systemChoosesAllResources: boolean = true;

  validselection: boolean[] = [];

  SelectedResources: any[] = [];
  SelectedResourcesDisplay: any[] = [];

  paymentValueValid: boolean = true;
  paymentTypeValid: boolean = true;
  paymentValueExceededValid: boolean = true;
  paymentDepositValid: boolean = true;
  validPaymentAmount: number = 0;

  selectedPayment: any;
  PaymentTypes: any = [];

  BookingResponse: any; 
  BookingPayments: Payment[] = [];

  discountCode: string = "";

  BookingFeeAdddedOn: number = 0;
  DiscountTakenOff: number = 0;

  showPrintConfirmations: boolean = false;
  confHTML: string = "";

  TotalExtras: number = 0;
  minimumDiscount: number = 0;

  totalPayments: number;

  httpResponse: any;

  maxPayment: number;

  MinimumValidPayment:number = 0;

  totalAdmissions: number;
  PackageHasExtras: boolean;

  totalAdmissionInRange: boolean;
  ShowAdmissions: boolean = true;

  startTimes: any;
  startTimesFiltered: any;
  startTimeCheck: any;

  ShowSaveButton: boolean = false;

  activeIndex: number = 0;
  BookingResources: any[] = [];
  PossibleResources: any [] = [];

  OptionalResources:any [] = [];

  SelectedPossibleResources:any [];
  showDisableReason: Boolean = false;
  disableNotes: string = "";

  showReturnToSearch: boolean = false;
  hubConnection: HubConnection;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  birthdayitems: birthdayanswers[] = [];

  mustanswerallergies: boolean[] = [];
  mustanswerbirthdays: boolean[] = [];
  mustanswernumber: boolean[] = [];
  mustanswerfreetext: boolean[] = [];
  mustanswermultichoicesingleanswer: boolean[] = [];
  mustanswermultichoicemultianswer: boolean[] = [];
  mustanswerdate: boolean[] = [];

  dateAnswer: Date[] = [];

  questionAnswers: questionanswers[] = [];
  questionAnswer: questionanswers;

  bookingFreeTextAnswer: string = "";
  bookingallergyanswer: string = "";
  bookingnumberanswer: number = 0;
  bookingdateanswer: Date = null;
  allergyAnswer:boolean[] = [];
  bookingmultichoicesingleanswer: bookingquestionitem = null;
  bookingmultichoicemultianswer: bookingquestionitem[] = [];

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  FromEvents: boolean = false;
  SummaryItinerary: boolean = false;

  allQuestionsValid: boolean = true;

  allTasks: any[] = [];
  bookingTasks: any[] = [];

  SelectedTask: any;

  showTaskError: boolean = false; 

  EmailTemplates: any[] = [];
  selectedEmailTemplate: any;

  templateValid: boolean = true;

  paymentButtonLocked: boolean = false;

  chosenDiscountAmount: number = 0;

  stateOptionOpenDiscount: any[];
  stateGenderOptions: any[];

  stateOptionAllergy: any[];

  openDiscount: boolean = false;
  totalDiscount: number = 0;
  startdate: string = "";

  caldays: any[] = [];
  calmonths: any[] = [];
  calyears: any[] = [];

  DOBDay: any[] = [];
  DOBMonth: any[] = [];
  DOBYear: any[] = [];

  DOBIsValid: boolean = true;
  dobage: any[] = [];

  ShowAge: boolean = false;
  ShowDOB: boolean = false;
  ShowGender: boolean = false;
  ShowInterests: boolean = false;
  bookingQuestionnaire: any;

  ShowCheckButton: boolean = false;
  showVoucher: boolean = false;
  voucherCode: any;
  voucherpin: string;

  showSubscriptionlWarning: boolean = false;

//#endregion 

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private busyService: BusyService,
    private viewportScroller: ViewportScroller,
    private auth: AuthService, private globalService: GlobalService) { 
      
      this.stateOptions = [{ label: 'Use full personal details', value: false }, { label: 'Use limited personal details', value: true }];      
      this.SummaryItinerary = localStorage.getItem("SummaryItinerary") == "true" ? true : false;
      
      this.stateOptionOpenDiscount = [{ label: 'Enter Discount Value', value: true }, { label: 'Enter Discount Code', value: false}]; 
      this.stateGenderOptions =  [{ label: 'Not Stated', value: 'NOT STATED' }, { label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}]; 
      
      this.stateOptionAllergy = [{ label: 'Yes', value: true }, { label: 'No', value: false}];  

      this.dobage = [
        {name:'0 to 3 months', value:'0 to 3 months'},
        {name:'4 to 6 months', value:'4 to 6 months'},
        {name:'6 to 12 months', value:'6 to 12 months'},
        {name:'1', value:'1'},
        {name:'2', value:'2'},
        {name:'3', value:'3'},
        {name:'4', value:'4'},
        {name:'5', value:'5'},
        {name:'6', value:'6'},
        {name:'7', value:'7'},
        {name:'8', value:'8'},
        {name:'9', value:'9'},
        {name:'10', value:'10'},
        {name:'11', value:'11'},
        {name:'12', value:'12'},
        {name:'13', value:'13'},
        {name:'14', value:'14'},
        {name:'15', value:'15'},
        {name:'16', value:'16'},
        {name:'17', value:'17'},
        {name:'18', value:'18'},
        {name:'Over 18', value:'Over 18'}
      ];

      this.caldays =  [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'},
        {name: '13', value:'13'},
        {name: '14', value:'14'},
        {name: '15', value:'15'},
        {name: '16', value:'16'},
        {name: '17', value:'17'},
        {name: '18', value:'18'},
        {name: '19', value:'19'},
        {name: '20', value:'20'},
        {name: '21', value:'21'},
        {name: '22', value:'22'},
        {name: '23', value:'23'},
        {name: '24', value:'24'},
        {name: '25', value:'25'},
        {name: '26', value:'26'},
        {name: '27', value:'27'},
        {name: '28', value:'28'},
        {name: '29', value:'29'},
        {name: '30', value:'30'},
        {name: '31', value:'31'}
      ];
          
      
      this.calmonths = [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'}
      ];
      
      for(let i = 2050; i > 1920; i--)
      {
        let thisYear = new Date().getFullYear();
        if(i <= thisYear)
        {
          this.calyears.push({name: i.toString(), value: i.toString()});
        }
      }
  }

  displayVoucher(){
    this.showVoucher = !this.showVoucher;
  }

  saveTask()
  {
    if(this.SelectedTask != null) 
      {
        this.showTaskError = false;
        this.http.get(bmxurl + "/addtask/" + this.id + "/" + this.SelectedTask.tasktypeid).subscribe(response => {
          this.httpResponse = response;
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Task added'});
            this.loaddata();
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Failure', detail: this.httpResponse.messages[0]});
          }
        });
      }
      else
      {
        this.showTaskError = true;
      }
  }

  deleteTask(task){
    this.showTaskError = false;
        this.http.get(bmxurl + "/deletebookingtask/" + this.id + "/" + task.bookingtaskid).subscribe(response => {
          this.httpResponse = response;
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Task removed'});
            this.loaddata();
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Failure', detail: this.httpResponse.messages[0]});
          }
        });
  }

  genRandomNum()
  {
    return Math.floor((Math.random()*10000)+1).toString();
  }

  saveBookingQuestions()
  {
    let AllValid = true;

    this.NewBooking.questionnaire.bookingtoken = null;

    this.NewBooking.questionnaire.bookingquestions.forEach(question => {
      if(question.questiontype == "ALLERGY")
      {
        if(question.answers.allergyanswer.allergypresent == true)
        {
          if(question.answers.allergyanswer.allergytext == "" && question.mandatory)
          {
            AllValid = false;
            this.mustanswerallergies[question.listorder] = false;
          }
          else
          {
            this.mustanswerallergies[question.listorder] = true;
          }
        }
        else
        {
          this.mustanswerallergies[question.listorder] = true;
        }
      }
      else if(question.questiontype == "BIRTHDAY")
      {

        this.mustanswerbirthdays[question.listorder] = true;

        this.birthdayitems.forEach(birthday => {

          if(birthday.showdob)
          {
            let Year = birthday.dobyear;
            let Month = birthday.dobmonth;
            let Day = birthday.dobday;
    
            birthday.birthdate = birthday.dobday + "/" + birthday.dobmonth + "/" + birthday.dobyear;
          }
          else
          {
            birthday.birthdate = "";
          }

          if(question.mandatory)
            {
              if(birthday.name == "" || ((birthday.interests == "" || birthday.interests == null)  && birthday.showinterests) || ((birthday.age == "" || birthday.age == null)  && birthday.showage) || ((birthday.birthdate == "" || birthday.birthdate == null) && birthday.showdob))
              {
                AllValid = false;
                this.mustanswerbirthdays[question.listorder] = false;
              }
            }
        });

      }
      else if(question.questiontype == "NUMBER")
      {
        if(question.answers.numberanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswernumber[question.listorder] = false
        }
        else
        {
          this.mustanswernumber[question.listorder] = true
        }
      }
      else if(question.questiontype == "FREETEXT")
      {
        if(question.answers.freetextanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswerfreetext[question.listorder] = false;
        }
        else
        {
          this.mustanswerfreetext[question.listorder] = true;
        }
      }
      else if(question.questiontype == "DATE")
      {
        if(question.answers.dateanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswerdate[question.listorder] = false;
        }
        else
        {
          this.mustanswerdate[question.listorder] = true;
        }
      }
      else if(question.questiontype == "MULTICHOICESINGLE")
      {
        if(question.answers.multichoicesingleanswer.id == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicesingleanswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicesingleanswer[question.listorder] = true;
        }

      }
      else if(question.questiontype == "MULTICHOICEMULTIPLE")
      {
        if(question.answers.multichoicemultianswer.length == 0 && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicemultianswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicemultianswer[question.listorder] = true;
        }

      }
    });
   
    if(AllValid)  
    {
      this.NewBooking.questionnaire.bookingid = this.NewBooking.bookingId;
      
      this.NewBooking.questionnaire.bookingquestions.forEach(question => {
        if(question.questiontype == "DATE")
          question.answers.dateanswer = this.dateAnswer[question.listorder];
        if(question.questiontype == "BIRTHDAY")
          question.answers.birthdayanswers = this.birthdayitems;
      });

      this.http.post<booking_returndata>(bmxurl + "/questionanswers", this.NewBooking.questionnaire).subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded == true) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Your answers have been saved' });
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
      })
    }
  }

  addBirthdayRow(){

    let INDEX = this.birthdayitems.length;

    this.http.get(bmxurl + "questionnaire/" + this.VenueID + "/" + this.SelectedPackage.questionnaireid).subscribe(response => {
      this.httpResponse = (response as bookingquestionnaire_returndata)
      if (this.httpResponse.succeeded)
      {
        this.bookingQuestionnaire = {
          id: (this.httpResponse.data as bookingquestionnaire).id,
          bookingid: (this.httpResponse.data as bookingquestionnaire).bookingid,
          questionnaireid: (this.httpResponse.data as bookingquestionnaire).questionnaireid,
          venueid: (this.httpResponse.data as bookingquestionnaire).venueid,
          name: (this.httpResponse.data as bookingquestionnaire).name,
          bookingquestions: (this.httpResponse.data as bookingquestionnaire).bookingquestions,
          disabled: (this.httpResponse.data as bookingquestionnaire).disabled,
        };

        this.bookingQuestionnaire.bookingquestions.forEach(element => {
          if(element.questiontype == "BIRTHDAY")
          {
            this.ShowAge = element.showage;
            this.ShowDOB = element.showdob;
            this.ShowGender = element.showgender;
            this.ShowInterests = element.showinterests;
          }
        });

        this.birthdayitems.push({
          index: INDEX, 
          id: uuidv4(), 
          name: "", 
          age: "", 
          birthdate: "",
          gender: "",
          interests: "",
          showage: this.ShowAge,
          showdob: this.ShowDOB,
          showgender: this.ShowGender,
          showinterests: this.ShowInterests,
          dobday: "",
          dobmonth: "",
          dobyear: ""
      });


      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    });
  }


  deleteBirthdayRow(inId){
    let index = 0;  
    this.SelectedPackage.question.answers.birthdayanswers.forEach(element => {
      if(element.id == inId)
      {
        this.SelectedPackage.question.answers.birthdayanswers.splice(index, 1);
      }
      index++;
    });
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    this.startdate = this.route.snapshot.paramMap.get('startdate');

    let userid = localStorage.getItem("SmartUserID");

    this.FromEvents = localStorage.getItem("FromEvents") == "TRUE" ? true : false;

      this.busyService.busy();

      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("MAKEABOOKING", user)){
            this.AccessAllowed = true;

            this.ProvideMinimalPersonalData = true;
        
            let qssearchparam = "";
            this.route.queryParams
              .subscribe(params => {
                console.log(params); 
                qssearchparam = params.fs;
        
                if(qssearchparam == "true")
                {
                  this.showReturnToSearch = true;
                }
                else{
                  this.showReturnToSearch = false;
                }
        
              }
            );

            this.loaddata();

            this.http.get(bmxurl + "/EmailTemplateList/" + this.VenueID + "/false").subscribe(response => {
              this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.EmailTemplates = (response as emailtemplate_returndata).data;
              }
              else
              {
                this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates, error: ' + error.messages});
            });
        
            let qsparam = "";
            this.route.queryParams
              .subscribe(params => {
                console.log(params); 
                qsparam = params.jumpto;
        
                if(qsparam == "admissions")
                {
                  this.activeIndex = 0;
                }
                else if(qsparam == "payment")
                {
                  this.activeIndex = 3;
                }
              }
            );
        
        
            this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
              this.hubConnection = new HubConnectionBuilder()
              .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
              accessTokenFactory: () => tokenReturned 
            }).build();
        
            this.hubConnection.start();
        
            this.hubConnection.on("bookingEvent", data => {
              console.log(data);
              this.http.get(bmxurl + "booking/" + this.VenueID + "/" + this.id).subscribe(response => { 
                this.NewBooking = (response as booking_returndata).data;
              }, error => {
                this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Booking.'});
              })
            });
        
            });
          }
  
          this.CompletedLoading = true;

        }
      }, error => {
        this.busyService.idle();
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });
  }

  loaddata(){
    this.id = this.route.snapshot.paramMap.get('id');
            
    if(this.id == "0")
    {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Booking.'});
      this.busyService.idle();
    }
    else
    {
      this.http.get(bmxurl + "booking/" + this.VenueID + "/" + this.id).subscribe(response => {
        
        this.NewBooking = (response as booking_returndata).data;

        if(this.NewBooking.questionnaire != null)
        {
          for(var i = 0; i < this.NewBooking.questionnaire.bookingquestions.length; i++)
          {
            this.mustanswerallergies[i] = true;
            this.mustanswerbirthdays[i] = true;
            this.mustanswernumber[i] = true;  
            this.mustanswerfreetext[i] = true;
            this.mustanswermultichoicesingleanswer[i] = true;
            this.mustanswermultichoicemultianswer[i] = true;
            this.mustanswerdate[i] = true;
            this.allergyAnswer[i] = true;  

            if(this.NewBooking.questionnaire.bookingquestions[i].answers.dateanswer != null)
              this.dateAnswer[i] = new Date(this.NewBooking.questionnaire.bookingquestions[i].answers.dateanswer)
            
            if(this.NewBooking.questionnaire.bookingquestions[i].questiontype == "BIRTHDAY")
            {
              this.NewBooking.questionnaire.bookingquestions[i].answers.birthdayanswers.forEach(element => {
                
              let newBA = {
                index: element.index,
                id: element.id, 
                name: element.name, 
                age: element.age, 
                birthdate: "-*-",
                interests: element.interests,
                gender: element.gender,
                showage: element.showage,
                showdob: element.showdob,
                showgender: element.showgender,
                showinterests: element.showinterests,
                dobday: element.birthdate.split("/")[0],
                dobmonth: element.birthdate.split("/")[1],
                dobyear: element.birthdate.split("/")[2]

              };

              this.birthdayitems.push(newBA);
              });
            }
          }
        }

        //Get Tasks for this Venue

        this.bookingTasks = [];

        this.http.get(bmxurl + "bookingtasklist/" + this.VenueID + "/" + this.id + "/false").subscribe(response => {
          this.allTasks = (response as any).data;
          
        }, error => {
          this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Tasks.'});
        })

        this.http.get(bmxurl + "TasktypeList/" + this.VenueID + "/false").subscribe(response => {
          this.bookingTasks = (response as any).data;
        });


        this.validPaymentAmount = this.NewBooking.outstanding;

        this.DiscountTakenOff = this.NewBooking.totaldiscounts;
        
        this.SelectedDate  = new Date(this.NewBooking.startTime);
       
        this.http.get(bmxurl + "packagelist/" + this.VenueID + "/true")
        .subscribe(response => {
          this.packages = (response as Package_returndata).data

          let activepackages = [];
          this.packages.forEach(element => {
            if(element.disabled == false || element.packageId == this.NewBooking.packageId)
            {
              element.disabled = false;
              activepackages.push(element);
            }
          });

          this.packages = [];
          this.packages = activepackages;

          this.packages.forEach(element => {
            if(element.packageId == this.NewBooking.packageId)
            {
               this.SelectedPackage = element;
            }
          });

          this.packageExtrasNew = this.SelectedPackage.extras

          this.Admissions = this.SelectedPackage.admissions;
          this.SelectedAdmissions = this.NewBooking.resources[0].admissions;

          this.TotalBalance = this.NewBooking.total;
          //this.OutstandingBalance = this.NewBooking.outstanding;

          this.packageExtras = this.NewBooking.extras;
        
          this.PaymentButton = "Add New Payment";

          //Assign Resource from Booking to Temp
          this.BookingResources = this.NewBooking.resources;

          //Possible Resources
          this.setupPossibleResources();

        }, error => {
          this.busyService.idle();
          console.log(error);
        })

        this.packageExtras = this.NewBooking.extras
        this.SelectedAdmissions = this.NewBooking.resources[0].admissions;

        this.dosummary();

        this.setDate();

        this.setPackage();

        this.doPaymentTypes();

        this.doLinkedAdmissions();

        this.doLinkedDiscounts();
        
        this.busyService.idle();
      }, error => {
        this.busyService.idle();
        this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Booking.'});
      })
    }
  }

  sendEmailToBooking(){

        this.http.get<activesubscriptions_returndata>(bmxurl + "activesubscriptions/" + this.VenueID).subscribe(Response => {
          let activeSubscriptions = Response.data;
    
          let hasEmailSubscription = false;
          if(activeSubscriptions != null && activeSubscriptions.length != 0)
          {
              activeSubscriptions.forEach(element => {
              {
                if(element.type == "EMAIL" && element.disabled == false)
                {
                  hasEmailSubscription = true;
                }
              }
            });
          }
    
        if(hasEmailSubscription)
        {

          if(this.selectedEmailTemplate == null)
            {
              this.templateValid = false
            }
            else
            {
              this.http.get<any>(bmxurl + "sendbookingemail/" + this.VenueID + "/" + this.NewBooking.bookingId + "/" + this.selectedEmailTemplate.emailtemplateid).subscribe(response => {
                this.httpResponse = response;
      
                if(!this.httpResponse.succeeded)
                {
                  this.messageService.add({severity:'error', summary:'Failure', detail:'An error occurred whilst trying to send the Email, error:' + this.httpResponse.messages[0]});
                }
                else
                {
                  this.messageService.add({severity:'success', summary:'Success', detail: 'Email sent'});
                }
      
              }, error => {
                this.messageService.add({severity:'error', summary:'Failure', detail:'An error occurred whilst trying to send the Email, error:' + error.messages[0]});
              })
            }
        }
        else
        {
          //show popup to ask to subcribe
          this.showSubscriptionlWarning = true;
        }
        },
          error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Subscriptions' });
        });

  }

  sendPaymentLink(){

    this.http.get<any>(bmxurl + "sendpaymentlink/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      this.httpResponse = response;

      if(!this.httpResponse.succeeded)
      {
        this.messageService.add({severity:'error', summary:'Failure', detail:'An error occurred whilst trying to send the Email, error:' + this.httpResponse.messages[0]});
      }
      else
      {
        if(this.NewBooking.payments.length > 0)
        {
          this.messageService.add({severity:'success', summary:'Success', detail: 'Payment link sent'});
        }
        else
        {
          this.messageService.add({severity:'success', summary:'Success', detail: 'Payment link sent, the customer will have 4 hours to respond to this link otherwise the booking will be cancelled. Cancelled bookings cannot be paid.'});
        }
      }

    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'An error occurred whilst trying to send the Email, error:' + error.messages[0]});
    })
  }
  
  returnToSearch(){
    this.router.navigateByUrl("/search");
  }

  returnToList(){
    if(localStorage.getItem("voucherScreen") == "TRUE")
    {
      this.router.navigateByUrl("/voucherlist");
    }
    else if(localStorage.getItem("listScreen") == "TRUE")
    {
      this.router.navigateByUrl("/bookinglist");
    }
    else
    {
      this.router.navigateByUrl("/home/" + this.startdate);
    }
  }

  returnToEvents(){
    this.FromEvents = false;
    localStorage.removeItem("FromEvents");
    this.router.navigateByUrl("/event");
  }

  setupPossibleResources()
  {
          //Possible Resources
          this.PossibleResources = this.SelectedPackage.resources;

          this.PossibleResources.forEach(PR => {
            if (PR.mode == 'COMPLEX')
            {
              PR.resources.forEach(innerPR => {
                let index = 0;
                this.BookingResources.forEach(BR => {
                  if(index == PR.orderindex)
                  {
                    if(innerPR.id == BR.id)
                    {
                      innerPR.ignore = true; //temporarily using the ignore flag to mark these resources.
                    }
                  }
                  index++;
                });
              });

              PR.selectableresources.forEach(innerPR => {
                let index = 0;
                this.BookingResources.forEach(BR => {
                  if(index == PR.orderindex)
                  {
                    if(innerPR.id == BR.id)
                    {
                      innerPR.ignore = true; //temporarily using the ignore flag to mark these resources.
                    }
                  }
                  index++;
                });
              });
            }
          });

          if(this.PossibleResources != null)
          {
            this.PossibleResources.forEach(PosRes => {
              let foundContainer = false;
              this.BookingResources.forEach(BookRes => {
                if(PosRes.id == BookRes.originatingid){
                  foundContainer = true;
                }
              });

              if(foundContainer)
                this.OptionalResources.push(true);
              else
                this.OptionalResources.push(false);

              if(PosRes.mode == "COMPLEX")
              {
                this.includesComplexResources = true;
              }
              else{
                this.includesComplexResources = false;
              }
              if(PosRes.selectionmode != "SYSTEMANY" && PosRes.selectionmode != "SYSTEMLIST")
              {
                this.systemChoosesAllResources = false;
              }
              else
              {
                this.systemChoosesAllResources = true;
              }
        
              if(PosRes.optional)
              {
                this.includesOptionalResources = true;
              }
            });
          }

          if(!this.includesComplexResources || this.systemChoosesAllResources)
          {
            this.customerSelectedResources = this.SelectedPackage.resources;
          }
      
          if(this.includesComplexResources)
          {
            this.partyroomsVisible = true;
          }
          else
          {
            this.partyroomsVisible = false;
          }
  }

  toggleButton(a,r){

    this.ShowCheckButton = true;
    a.selectableresources .forEach(element => {
      element.ignore = false;
    });

    a.selectableresources.forEach(element => {
      if(element.id != r.id)
      {
        element.ignore = false;
      }
      else
      {
        element.ignore = true;
      }
    });

    // this.customerSelectedResources.forEach(element => {
    //   if(element.originatingid == a.id)
    //   {
    //     a.resources.forEach(innerElement => {
    //         if(innerElement.ignore == true)
    //         {
    //           element.resources = innerElement;
    //         }
    //       });
    //   }
    // });

    this.isTimeAvailable(this.NewBooking.startTime)
  }

  showDisableMessage()
  {
    this.showDisableReason = true;
  }

  // disableBooking(){
  //   this.NewBooking.Disabled = true;

  //   this.http.put<string>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
  //     this.BookingResponse = response 
  //     if(this.BookingResponse.succeeded)
  //     {
  //       this.NewBooking = this.BookingResponse.data.bookingObj
  //       this.messageService.add({severity:'success', summary:'Success', detail: 'The booking was disabled'});

  //       let HistoryEntry: any = {
  //         bookingid: this.NewBooking.bookingId,
  //         historyitem: "This Booking was disabled for the following reason: " + this.disableNotes
  //       }
    
  //       this.http.post<any>(bmxurl + "/History", HistoryEntry).subscribe(response => {
    
  //         if(response.succeeded)
  //         {
  //           this.disableNotes = "";
  //           this.showDisableReason = false;
  //         }
          
  //       }, error => {
  //         this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to save history item' });
  //       })

  //       this.disableNotes = "";
  //       this.showDisableReason = false;

  //     }
  //     else
  //     {
  //       this.messageService.add({severity:'error', summary:'Failure', detail: 'The booking could not be disabled'});
  //     }
  //   }, error => {
  //     console.log(error);
  //   })
  // }

  disableBooking()
  {
    this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      this.BookingResponse = response
      if(this.BookingResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking successfully disabled'});
        
        this.NewBooking.disabled = true;

        let HistoryEntry: any = {
          bookingid: this.NewBooking.bookingId,
          historyitem: "This Booking was disabled for the following reason: " + this.disableNotes
        }
    
        this.http.post<any>(bmxurl + "/History", HistoryEntry).subscribe(response => {
    
          if(response.succeeded)
          {
            this.disableNotes = "";
            this.showDisableReason = false;

            this.loaddata();
          }
          
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to save history item' });
        })

        this.disableNotes = "";
        this.showDisableReason = false;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking'});
    })
}

  enableBooking(){
    this.NewBooking.Disabled = false;
    this.NewBooking.salestype = 0;

    this.http.put<string>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.BookingResponse = response 

      if(this.BookingResponse.succeeded)
      {
        this.NewBooking = this.BookingResponse.data.bookingObj
        this.messageService.add({severity:'success', summary:'Success', detail: 'The booking was re-enabled'});

        this.loaddata();
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'The booking could not be re-enabled'});
      }
    }, error => {
      console.log(error);
    })
  }

  checkTab()
  {
    if(this.activeIndex == 5)
    {
      this.dohistory();
    }
  }

  displayDiscount(){
    this.showDiscount = !this.showDiscount;
  }

  doneVoucher()
  {  
    this.busyService.busy();

    let newCustomer: any;

    newCustomer = this.NewBooking.customer;
    
    let dv: cVouchervalidate = {
      booking: this.NewBooking,
      vouchercode: this.voucherCode.trim(),
      voucherpin : this.voucherpin.trim()
    }

    this.http.post<booking_returndata>(bmxurl + "/VoucherValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Voucher Added', detail: 'Your voucher has been added' });

        this.showVoucher = false;
        this.voucherCode = "";

        this.NewBooking = (response as booking_returndata).data;

        this.BookingPayments = this.NewBooking.payments;
        this.NewBooking.totaladmissions = this.NewBooking.totaladmission;

        this.validPaymentAmount = this.NewBooking.outstanding;

        this.totalPayments = 0;
        this.BookingPayments.forEach(element => {
          this.totalPayments += element.amount;
        });

        this.selectedPayment = null;

        this.dosummary();

        this.busyService.idle();

      }
      else
      {
        this.busyService.idle();
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.busyService.idle();
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.error.messages[0] });
    })
  }

  doneDiscount(){
    //send discount to server
    let dv: cDiscountvalidate = {
      booking: this.NewBooking,
      discountcode: this.discountCode
    }

    this.http.post<booking_returndata>(bmxurl + "/DiscountValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Discount Added', detail: 'Your discount has been added' });

        this.showDiscount = false;
        this.discountCode = "";

        this.NewBooking = (response as booking_returndata).data;
        this.DiscountTakenOff = this.NewBooking.totaldiscounts;
        this.NewBooking.totaladmissions = (response as booking_returndata).data.totaladmission;

        //this.CalcDeposit(this.BookingResponse.bookingObj.total);
        this.NewBooking.totalvenuedeposit = (response as booking_returndata).data.totalvenuedeposit;
        this.NewBooking.totalpublicdeposit  = (response as booking_returndata).data.totalpublicdeposit ;

        this.validPaymentAmount = this.NewBooking.outstanding;

        this.dosummary();
      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
    })
  }

  doneOpenDiscount(){
    //send discount to server
    let dv: cOpenDiscountvalidate = {
      booking: this.NewBooking,
      value : this.chosenDiscountAmount
    }

    this.http.post<booking_returndata>(bmxurl + "/opendiscount", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Discount Added', detail: 'Your discount has been added' });

        this.showDiscount = false;
        this.discountCode = "";

        this.NewBooking = (response as booking_returndata).data;

        if(this.NewBooking.PublicBooking)
        {
          this.validPaymentAmount = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.validPaymentAmount = this.NewBooking.totalvenuedeposit;
        }

      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
    })
}

  removeDiscount()
  {
    this.busyService.busy();

    this.NewBooking.discounts = null;
    this.NewBooking.salestype = 0;

    this.http.put<booking_returndata>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.httpResponse = (response as booking_returndata);     

      this.BookingResponse = this.httpResponse.data

        this.NewBooking = this.BookingResponse.bookingObj;

        this.validPaymentAmount = this.NewBooking.outstanding;
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;
        let NumPeople: number = 0;
        this.NewBooking.resources[0].admissions.forEach(adm => {
          NumPeople += adm.noofpeople;
        });
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;
        //this.OutstandingBalance = this.BookingResponse.bookingObj.outstanding;
        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: this.CurrencySymbol});
  
        this.startTimes = null;
        this.startTimesFiltered = null;
    
        this.messageService.add({ severity: 'success', summary: 'Discount Removed', detail: 'Your discount has been removed' });

        this.dosummary();
        this.busyService.idle();

    }, error => {
      console.log(error);
      this.busyService.idle();
    })
  }

  ChangeTime(){
    this.viewportScroller.scrollToPosition([0, 900]);

    this.startTimes = [];

    this.donePartyRooms();
    this.getAvailability();
  }

  checkchanges(){

    this.ShowCheckButton = false;

    this.startTimes = [];

    this.donePartyRooms();
    this.getAvailability();
  }

  donePartyRooms()
  {   
    this.ShowCheckButton = true;

    this.startTimes = [];
    this.customerSelectedResources = [];

    let ResourcesToProcess = [...this.PossibleResources]
    ResourcesToProcess.forEach(PR => {
      if(PR.selectionmode == 'LIST' || PR.selectionmode == 'SYSTEMLIST')
      {
          PR.selectableresources.forEach(innerPR => {
            if(innerPR.ignore)
            {
              let chosenResource: resource = {
                groupname: PR.groupname,
                id: PR.id,
                resourceId: PR.resourceId,   
                name: PR.name, 
                useClasses: PR.useClasses,
                exclusive: PR.exclusive,
                eventid: PR.eventid,
                concurrentUses: PR.concurrentUses,
                maxConcurrent: PR.maxConcurrent,
                disabled: PR.disabled, 
                venueId: PR.venueId,  
                colour: PR.colour,
                orderindex: PR.orderindex,
                length: PR.length,
                classes: PR.classes,
                showonbooking:PR.showonbooking,
                resources: [],
                isresourcecontainer: PR.isresourcecontainer,
                isconstructed: PR.isconstructed,
                resourcegroupname: PR.resourcegroupname,
                price: PR.price,
                fixedprice: PR.fixedprice,
                tags: PR.tags,
                resourcegroup: PR.resourcegroup,
                selectionmode: PR.selectionmode,
                selectableresources: PR.selectableresources, 
                startwhen: PR.startwhen,
                numofminutes: PR.numofminutes,
                optional: PR.optional,
                quantityselector: PR.quantityselector,
                quantity: PR.quantity,
                mode: PR.mode,
                inline: PR.inline,
                ignore: PR.ignore,
                admissions: PR.admissions,
                inProgress: PR.inProgress,
                originatingid: PR.originatingid,
                wasoptional: PR.wasoptional,
                wasselected: PR.wasselected,
              }
              chosenResource.resources.push(innerPR);
  
              this.customerSelectedResources.push(chosenResource);
            }
          });
      }
      else if (PR.selectionmode == 'ANY' || PR.selectionmode == 'SYSTEMANY')
      {
        // PR.selectableresources.forEach(innerPR => {
        //   if(innerPR.ignore)
        //   {
        //     let chosenResource: resource = {
        //       groupname: PR.groupname,
        //       id: PR.id,
        //       resourceId: PR.resourceId,   
        //       name: PR.name, 
        //       useClasses: PR.useClasses,
        //       exclusive: PR.exclusive,
        //       eventid: PR.eventid,
        //       concurrentUses: PR.concurrentUses,
        //       maxConcurrent: PR.maxConcurrent,
        //       disabled: PR.disabled, 
        //       venueId: PR.venueId,  
        //       colour: PR.colour,
        //       orderindex: PR.orderindex,
        //       length: PR.length,
        //       classes: PR.classes,
        //       showonbooking:PR.showonbooking,
        //       resources: [],
        //       isresourcecontainer: PR.isresourcecontainer,
        //       isconstructed: PR.isconstructed,
        //       resourcegroupname: PR.resourcegroupname,
        //       price: PR.price,
        //       fixedprice: PR.fixedprice,
        //       tags: PR.tags,
        //       resourcegroup: PR.resourcegroup,
        //       selectionmode: PR.selectionmode,
        //       selectableresources: PR.selectableresources, 
        //       startwhen: PR.startwhen,
        //       numofminutes: PR.numofminutes,
        //       optional: PR.optional,
        //       quantityselector: PR.quantityselector,
        //       quantity: PR.quantity,
        //       mode: PR.mode,
        //       inline: PR.inline,
        //       ignore: PR.ignore,
        //       admissions: PR.admissions,
        //       inProgress: PR.inProgress,
        //       originatingid: PR.originatingid,
        //       wasoptional: PR.wasoptional,
        //       wasselected: PR.wasselected,
        //     }
        //     chosenResource.resources.push(innerPR);

        //     this.customerSelectedResources.push(chosenResource);
        //   }
        // });
        this.customerSelectedResources.push(PR);
      }
      else
      {
        this.customerSelectedResources.push(PR);
      }
    });

    //ResourcesToProcess.forEach(PR => {
      // if(PR.selectionmode == 'LIST')
      // {
      //   PR.resources.forEach(innerPR => {
      //     if(innerPR.ignore)
      //     {
      //       let chosenResource: resource = {
      //         groupname: PR.groupname,
      //         id: PR.id,
      //         resourceId: PR.resourceId,   
      //         name: PR.name, 
      //         useClasses: PR.useClasses,
      //         exclusive: PR.exclusive,
      //         eventid: PR.eventid,
      //         concurrentUses: PR.concurrentUses,
      //         maxConcurrent: PR.maxConcurrent,
      //         disabled: PR.disabled, 
      //         venueId: PR.venueId,  
      //         colour: PR.colour,
      //         orderindex: PR.orderindex,
      //         length: PR.length,
      //         classes: PR.classes,
      //         showonbooking:PR.showonbooking,
      //         resources: [],
      //         isresourcecontainer: PR.isresourcecontainer,
      //         isconstructed: PR.isconstructed,
      //         resourcegroupname: PR.resourcegroupname,
      //         price: PR.price,
      //         fixedprice: PR.fixedprice,
      //         tags: PR.tags,
      //         resourcegroup: PR.resourcegroup,
      //         selectionmode: PR.selectionmode,
      //         selectableresources: PR.selectableresources, 
      //         startwhen: PR.startwhen,
      //         numofminutes: PR.numofminutes,
      //         optional: PR.optional,
      //         quantityselector: PR.quantityselector,
      //         quantity: PR.quantity,
      //         mode: PR.mode,
      //         inline: PR.inline,
      //         ignore: PR.ignore,
      //         admissions: PR.admissions,
      //         inProgress: PR.inProgress,
      //         originatingid: PR.originatingid,
      //         wasoptional: PR.wasoptional,
      //         wasselected: PR.wasselected,
      //       }
      //       chosenResource.resources.push(innerPR);

      //       this.customerSelectedResources.push(chosenResource);
      //     }
      //   });
      // }
    //});

    // ResourcesToProcess.forEach(PR => {
    //   if(PR.mode == 'SIMPLE' || PR.mode == null)
    //   {
    //     this.customerSelectedResources.push(PR);
    //   }
    //   else
    //   {
    //     if(PR.resources != null && PR.resources.length > 0)
    //     {
    //       PR.resources.forEach(innerPR => {
    //         //if(innerPR.ignore)
    //         //{
    //           let chosenResource: resource = {
    //             groupname: PR.groupname,
    //             id: PR.id,
    //             resourceId: PR.resourceId,   
    //             name: PR.name, 
    //             useClasses: PR.useClasses,
    //             exclusive: PR.exclusive,
    //             eventid: PR.eventid,
    //             concurrentUses: PR.concurrentUses,
    //             maxConcurrent: PR.maxConcurrent,
    //             disabled: PR.disabled, 
    //             venueId: PR.venueId,  
    //             colour: PR.colour,
    //             orderindex: PR.orderindex,
    //             length: PR.length,
    //             classes: PR.classes,
    //             showonbooking:PR.showonbooking,
    //             resources: [],
    //             isresourcecontainer: PR.isresourcecontainer,
    //             isconstructed: PR.isconstructed,
    //             resourcegroupname: PR.resourcegroupname,
    //             price: PR.price,
    //             fixedprice: PR.fixedprice,
    //             tags: PR.tags,
    //             resourcegroup: PR.resourcegroup,
    //             selectionmode: PR.selectionmode,
    //             selectableresources: PR.selectableresources, 
    //             startwhen: PR.startwhen,
    //             numofminutes: PR.numofminutes,
    //             optional: PR.optional,
    //             quantityselector: PR.quantityselector,
    //             quantity: PR.quantity,
    //             mode: PR.mode,
    //             inline: PR.inline,
    //             ignore: PR.ignore,
    //             admissions: PR.admissions,
    //             inProgress: PR.inProgress,
    //             originatingid: PR.originatingid,
    //             wasoptional: PR.wasoptional,
    //             wasselected: PR.wasselected,
    //           }
    //           chosenResource.resources.push(innerPR);

    //           this.customerSelectedResources.push(chosenResource);
    //         //}
    //       });
    //     }
    //     else{
    //       this.customerSelectedResources.push(PR);
    //     }
    //   }
    // });


  }

  // doLinkedAdmissions()
  // {
  //   let totalNumberOfLinked = 0;

  //   this.SelectedAdmissions.forEach(adm => {

  //     if(adm.linkedadmissions != null)
  //     {
  //       adm.linkedadmissions.forEach(ladm => {
  //         let linkedAdm = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

  //         if(linkedAdm != null)
  //         {
  //           totalNumberOfLinked += Number(linkedAdm.noofpeople);
  //         }
  //       });

  //       if(adm.onlysetmax)
  //       {
  //         adm.max = Number(totalNumberOfLinked);
  //         if (adm.max < adm.min)
  //           adm.max = adm.min;
  //         adm.fixed = false;
  //       }
  //       else
  //       {
  //         adm.noofpeople = Number(totalNumberOfLinked);
  //         adm.fixed = true;
  //       }
  //     }
  //   });

  // }

  changeAdmissions()
  {
    this.doLinkedAdmissions();
    this.isTimeAvailable(this.NewBooking.startTime);
  }

  saveExtras(){

    this.allQuestionsValid = true;

    // this.packageExtras.forEach(element => {
    //   if(element.question != null && element.qty > 0 && (element.answer == null || element.answer == "")) 
    //   {
    //     this.allQuestionsValid = false;
    //   }

    //   if (element.qty == 0)
    //   {
    //     element.answer = "";
    //   }
    // });

    this.packageExtras.forEach(element => {
      if(element.question != null)
      {
        if((element.question != "" && element.qty > 0) && (element.answer == null || element.answer == "")) 
        {
          this.allQuestionsValid = false;
        }
      }
      if (element.qty == 0)
      {
        element.answer = "";
      }
    });

    if(this.allQuestionsValid)
    {
      let be: cBookingExtras = {
        BookingId: this.NewBooking.bookingId,
        venueid: this.VenueID,
        extras: this.packageExtras
      }

      let httpResponse: any = null;

      this.http.post<any>(bmxurl + "/BookingExtra", be).subscribe(response => {
        let httpResponse = response;

        if(response.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Success', detail: 'Extras updated'});

          this.NewBooking = response.data;
          this.dosummary();
        }
        else
        {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not add extra to this booking'});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Failure', detail: httpResponse.messages[0]});
      })
    }
  }

  dosummary(){

    let admissionsText: string = "";
    let extrasText: string = "";

    this.NewBooking.resources[0].admissions.forEach(adm => {
      if(adm.noofpeople > 0)
        admissionsText += adm.noofpeople + " x " +  adm.name + ", ";
    });

    if(admissionsText.length > 2)
    admissionsText = admissionsText.substring(0, admissionsText.length - 2);

    if(this.NewBooking.extras != null)
    {
      if(this.NewBooking.extras.length > 0)
      {
        this.NewBooking.extras.forEach(element => {
          if(element.qty > 0)
            extrasText += element.name + " x " + element.qty + ", ";
        });
      }
    }

    if(extrasText.length > 2)
    {
      extrasText = extrasText.substring(0, extrasText.length - 2);
    }

    let FullCustomer = "";

    if(this.NewBooking.customer != null)
      {
        let CustomerName = "";
        let CustomerAddress1 = "";
        let CustomerAddress2 = "";
        let CustomerTownCity = "";
        let CustomerPostCode = "";

        CustomerName = this.NewBooking.customer.fullName.trim();

        if (this.NewBooking.customer.address1 != null && this.NewBooking.customer.address1 != "") 
        {
          CustomerAddress1 = this.NewBooking.customer.address1;
        }
        
        if (this.NewBooking.customer.address2 != null && this.NewBooking.customer.address2 != "") 
        {
          CustomerAddress2 = this.NewBooking.customer.address2;
        }

        if (this.NewBooking.customer.townCity != null && this.NewBooking.customer.townCity != "") 
        {
          CustomerTownCity = this.NewBooking.customer.townCity;
        }

        if (this.NewBooking.customer.postCode != null && this.NewBooking.customer.postCode != "") 
        {
          CustomerPostCode = this.NewBooking.customer.postCode;
        }

        FullCustomer = CustomerName + CustomerAddress1 + CustomerAddress2 + CustomerTownCity + CustomerPostCode;
      }

    let customerEmail = "";
    let customerMobile = "";
    let customerFullName = "";

    if(this.NewBooking.customer != null)
    {
      customerFullName = this.NewBooking.customer.fullName
      customerMobile = this.NewBooking.customer.mobile
      customerEmail = this.NewBooking.customer.email
    }

    let newBookingSummary:cBookingsummary = {
      Disabled: this.NewBooking.disabled,
      bookingId: this.NewBooking.bookingId,
      bookingIdHR: this.NewBooking.bookingIdHR,
      admissions: admissionsText,
      startTime: this.NewBooking.startTime,
      endTime: this.NewBooking.endTime,
      name: this.NewBooking.name,
      arrival: this.NewBooking.arrival,
      guests: this.NewBooking.guests,
      total: this.NewBooking.total,
      outstanding: this.NewBooking.outstanding,
      paid: Number(this.NewBooking.total) - Number(this.NewBooking.outstanding),
      totaldiscounts: Number(this.NewBooking.totaldiscounts),
      bookingfee: Number(this.NewBooking.bookingfee),
      customerName: customerFullName,
      customerAddress: FullCustomer,
      customerMobile: customerMobile,
      customerEmail:  customerEmail,
      packageName:  this.NewBooking.packageName,
      publicBooking: this.NewBooking.publicBooking,
      extras: extrasText,
      itinerary: this.NewBooking.itinerary,
      notes: this.NewBooking.notes
    }

    this.BookingSummary = newBookingSummary;

  }

  setDate(){
    this.SelectedDate  = new Date(this.NewBooking.startTime);
    this.CalendarHeader = "You selected: " + this.SelectedDate.toDateString();
  }

  showCustomerSearch()
  {
    this.ShowCustomerSearch = true;
    this.CustomerHasBeenEdited = true;
  }

  setPackage(){
    this.http.get(bmxurl + "packagelist/" + this.VenueID + "/false/")
    .subscribe(response => {

      if((response as Package_returndata).succeeded){
        this.packages = (response as Package_returndata).data

        this.packages.forEach(element => {
          if(element.packageId == this.NewBooking.packageId)
          {
            this.SelectedPackage = element;
            this.PackageHeader = "You selected: " + this.SelectedPackage.name;
          }
        });
      }
      else{
        this.messageService.add({severity:'error', summary:'Failure', detail: (response as Package_returndata).messages[0]});
      }

    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Packages.'});
    })
  }

  packageChanged()
  {
    this.startTimes = [];
    this.ShowCheckButton = true;
  }

  admissionsChanged()
  {
    this.startTimes = [];
    this.ShowCheckButton = true;
  }


  doneDate(){

    this.ShowCheckButton = true;

    this.startTimes = [];

    //If Date changes check whether package is still available
    this.http.get(bmxurl + "packagelist/" + this.VenueID + "/false/" + 
    ('00' + this.SelectedDate.getDate()).slice(-2)+
    ('00' + (Number(this.SelectedDate.getMonth() + 1).toString())).slice(-2)
    +this.SelectedDate.getFullYear().toString().substr(-2))
    .subscribe(response => {
      let PackageCheck:any[] = (response as Package_returndata).data
      let FoundPackage:boolean = false;

      PackageCheck.forEach(packageCheck => {
        if(this.SelectedPackage.packageId == packageCheck.packageId)
          {
            FoundPackage = true;
          }
      });

      if(!FoundPackage)
      {
        this.messageService.add({severity:'warn', summary:'Package not available', detail: 'The Package previously chosen is not available on the date selected.'});
        this.SelectedPackage = null;
        this.Admissions = null;
        this.ShowAdmissions = false;
        this.NewBooking.startTime = null;
        this.ShowSaveButton = false;
      }
      else{
        //Check everything is still available
        //this.isTimeAvailable(this.NewBooking.startTime)
      }

    }, error => {
      console.log(error);
    })
  }

  dohistory(){

    var httpResponse: any

    this.http.get(bmxurl + "history/" + this.id).subscribe(response => {
     httpResponse = (response as history_returndata)
     if(httpResponse.succeeded == true)
     {
      this.historyItems = httpResponse.data;
     }
     else
     {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not get history for this booking'});
     }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: httpResponse.messages[0]});
    })
  }

  donePackage(){
    this.startTimes = [];

    this.customerSelectedResources = [];
    this.SelectedResources = [];
    
    this.SelectedAdmissions = [];

    this.Admissions = this.SelectedPackage.admissions;

    this.SelectedPackage.admissions.forEach(adm => {
      var newAdm: admission = {
        id: adm.id,
        admissionid: adm.admissionid,
        classid: adm.classid,
        noofpeople: adm.min,
        price: adm.price,
        name: adm.name,
        index: adm.index,
        max: adm.max,
        min: adm.min,
        venueid: adm.venueid,
        disabled: adm.disabled,
        admissionClass: adm.admissionClass,
        bookingwizardtext: adm.bookingwizardtext,
        fixedPrice: adm.fixedPrice,
        participant: adm.participant,
        mustinclude: adm.mustinclude,
        eventtickettype: adm.eventtickettype,
        counter: adm.counter,
        uselinkeddiscounts: adm.uselinkeddiscounts,
        linkedadmissions: adm.linkedadmissions,
        linkeddiscounts: adm.linkeddiscounts,
        uselinkedadmissions: adm.uselinkedadmissions,
        maxlinkeddiscounts: adm.maxlinkeddiscounts,
        maxlinkedadmissions:adm.maxlinkedadmissions,
        fixed: adm.fixed,
        onlysetmax: adm.onlysetmax
    }

    this.SelectedAdmissions.push(newAdm);
    });

    this.ShowAdmissions = true;

    this.SelectedPackage.resources.forEach(res => {

      let selectableinnerresources: any[] = [];
      if(res.selectableresources != null && res.selectableresources.length > 0)
      {
        res.selectableresources.forEach(innerRes => {
          selectableinnerresources.push(innerRes);
        });
      }

      var newRes: cResource = {
        groupname: uuidv4(),
        id: res.id,
        resourceId: res.resourceId,   
        name: res.name,
        useClasses: res.useClasses,
        exclusive: res.exclusive,
        eventid: res.eventid,
        concurrentUses: res.concurrentUses,
        maxConcurrent: res.maxConcurrent,
        disabled: res.disabled,
        venueId: res.venueId,
        colour: res.colour,
        orderindex: res.orderindex,
        length: res.length,
        classes:  res.classes,
        showonbooking:res.showonbooking,
        resources: selectableinnerresources,
        isresourcecontainer: res.isresourcecontainer,
        isconstructed: res.isconstructed,
        resourcegroupname: res.resourcegroupname,
        price: res.price,
        fixedprice: res.fixedprice,
        tags: res.tags,
        resourcegroup: res.resourcegroup,
        selectionmode: res.selectionmode,
        selectableresources: res.selectableresources,
        startwhen: res.startwhen,
        numofminutes: res.numofminutes,
        optional: res.optional,
        quantityselector: res.quantityselector,
        quantity: res.quantity,
        mode: res.mode,
        inline: res.inline,
        ignore: res.ignore,
        admissions: res.admissions,
        inProgress: res.inProgress,
        originatingid: res.originatingid,
        wasoptional: res.wasoptional,
        wasselected: res.wasselected
    }

    this.SelectedResources.push(newRes);
    });

    //Get day for selected date.
    let day = this.SelectedDate.getDay();

    this.packageExtras = [];

    if(this.SelectedPackage.extras != null)
    {
      switch (day)
      {
        case 0:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
          break;
        case 1:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.monday == true);
          break;
        case 2:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.tuesday == true);
          break;
        case 3:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.wednesday == true);
          break;
        case 4:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.thursday == true);
          break;
        case 5:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.friday == true);
          break;
        case 6:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.saturday == true);
          break;
        default:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
          break;
      }
    }

    this.packageExtras.forEach(ext => {
      ext.qty = 0;
     });

     if(this.packageExtras.length > 0)
        this.PackageHasExtras = true;

    this.SelectedResources.forEach(element => {
      if(element.mode == "COMPLEX")
      {
        this.includesComplexResources = true;
      }
      else{
        this.includesComplexResources = false;
      }
      if(element.selectionmode != "SYSTEMANY" && element.selectionmode != "SYSTEMLIST")
      {
        this.systemChoosesAllResources = false;
      }
      else
      {
        this.systemChoosesAllResources = true;
      }

      if(element.optional)
      {
        this.includesOptionalResources = true;
      }
    });

    if(!this.includesComplexResources || this.systemChoosesAllResources)
    {
      this.customerSelectedResources = this.SelectedPackage.resources;
    }

    if(this.includesComplexResources)
    {
      this.partyroomsVisible = true;
    }
    else
    {
      this.partyroomsVisible = false;
    }

    this.sumAdmissions();

    this.isTimeAvailable(this.NewBooking.startTime);

  }

  getAvailability(){
    let totalNoOfPeople: number = 0;

    const AvailabilityReq = {} as AvailabilityReq;

    AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
    
      AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
      
   });

    AvailabilityReq.packageId = this.SelectedPackage.packageId;
    AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    AvailabilityReq.venueid = this.VenueID;
    AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    AvailabilityReq.DiagnosticMode = false;
    AvailabilityReq.IgnoreExistingBooking = true;
    AvailabilityReq.ExistingBookingID = this.NewBooking.bookingId;
    AvailabilityReq.Resources = this.customerSelectedResources;

    this.http.post<AvailableTimes>(bmxurl + "/Availability", AvailabilityReq).subscribe(response => {
      this.startTimes =(response as AvailableTimes)
      let found: Boolean = false;

    this.startTimesFiltered = [];
      this.startTimes.forEach(element => {
        if(element.timeAvailable)
        {
          this.startTimesFiltered.forEach(innerelement => {
            if(innerelement.time == element.time)
              found = true;
          });

          if(!found)
          {
            this.startTimesFiltered.push(element);
          }
          found = false;
        }
      });
      console.log(response);
    }, error => {
      console.log(error);
  })

  }

  isTimeAvailable(inTime: Date) {

    this.busyService.busy();

    this.donePartyRooms();

    let totalNoOfPeople: number = 0;

    const AvailabilityReq = {} as AvailabilityReq;

    AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
      AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
   });

    AvailabilityReq.packageId = this.SelectedPackage.packageId;
    AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    AvailabilityReq.venueid = this.VenueID;
    AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    AvailabilityReq.DiagnosticMode = false;
    AvailabilityReq.IgnoreExistingBooking = true;
    AvailabilityReq.ExistingBookingID = this.NewBooking.bookingId;
    AvailabilityReq.Resources = this.customerSelectedResources;
    let found: boolean = false;

    this.http.post<AvailableTimes>(bmxurl + "/Availability", AvailabilityReq).subscribe(response => {
      let startTimeCheck:any =(response as AvailableTimes)

      if(startTimeCheck != null && startTimeCheck.length > 0)
      {
        startTimeCheck.forEach(element => {
          let elementTime = ('00' + new Date(element.time).getHours()).slice(-2) + ":" + ('00' + new Date(element.time).getMinutes()).slice(-2)
          let ChosenTime = ('00' + new Date(inTime).getHours()).slice(-2) + ":" + ('00' + new Date(inTime).getMinutes()).slice(-2)
          if(elementTime == ChosenTime)
          {
            if(element.timeAvailable)
            {
              found = true;
            }
          }
        });
      }
      
      if(found)
      {
        this.messageService.add({severity:'success', summary:'Time available', detail: 'The time previously chosen is still available for the date selected!'});
        this.ShowSaveButton = true;
        this.busyService.idle();
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Time unavailable', detail: 'The time previously chosen is no longer available, please select an alternative.'});
        this.getAvailability();
        this.busyService.idle();
      }

    }, error => {
      this.messageService.add({severity:'warn', summary:'Time unavailable', detail: 'The time previously chosen is no longer available, please select an alternative.'});
          this.getAvailability();
          this.busyService.idle();
    });
  }

  bookExistingSlotTime(inTime: Date) {

    this.busyService.busy();
    let totalNoOfPeople: number = 0;

    const AvailabilityReq = {} as AvailabilityReq;

    AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
      AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
   });

   if(totalNoOfPeople >  this.NewBooking.quw)
   {

   }

    AvailabilityReq.packageId = this.SelectedPackage.packageId;
    AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    AvailabilityReq.venueid = this.VenueID;
    AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    AvailabilityReq.DiagnosticMode = false;
    AvailabilityReq.IgnoreExistingBooking = true;
    AvailabilityReq.ExistingBookingID = this.NewBooking.bookingId;
    AvailabilityReq.Resources = this.customerSelectedResources;
    let found: boolean = false;

    this.http.post<AvailableTimes>(bmxurl + "/Availability", AvailabilityReq).subscribe(response => {
      let startTimeCheck:any =(response as AvailableTimes)

      let SlotTime;
      startTimeCheck.forEach(element => {
        let elementTime = ('00' + new Date(element.time).getHours()).slice(-2) + ":" + ('00' + new Date(element.time).getMinutes()).slice(-2)
        let ChosenTime = ('00' + new Date(inTime).getHours()).slice(-2) + ":" + ('00' + new Date(inTime).getMinutes()).slice(-2)
        if(elementTime == ChosenTime)
        {
          found = true;
          SlotTime = element;
        }
      });

      if(found)
      {
        this.bookSlot(SlotTime);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Cannot save time', detail: 'The Time selected could not be saved'});
        this.ShowSaveButton = true;
        this.busyService.idle();
      }

    }, error => {
      this.messageService.add({severity:'warn', summary:'Time unavailable', detail: 'The time previously chosen is no longer available, please select an alternative.'});
          this.getAvailability();
          this.busyService.idle();
    });
  }

  doLinkedDiscounts()
  {
    this.totalDiscount = 0;

    let totalNumberOfLinkedDiscounts = 0;

    this.SelectedAdmissions.forEach(adm => {

      if(adm.linkeddiscounts != null)
      {
        adm.linkeddiscounts.forEach(ladm => {
          let linkedDis = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedDis != null)
          {
            if(linkedDis.noofpeople >= ladm.threshold)
            {
              let numberOfPeopleForDiscount = Math.floor(linkedDis.noofpeople / ladm.threshold);

              if(adm.noofpeople < numberOfPeopleForDiscount)
              {
                numberOfPeopleForDiscount = adm.noofpeople;
              }

              this.totalDiscount += Number(adm.price * numberOfPeopleForDiscount) * Number(ladm.percentagediscount / 100);
            }
          }
        });
      }
    });
  }

  doLinkedAdmissions()
  {
    let totalNumberOfLinked = 0;

    this.SelectedAdmissions.forEach(adm => {

      let OldNoOfPeople = adm.min;

      if(adm.linkedadmissions != null)
      {
        adm.linkedadmissions.forEach(ladm => {
          let linkedAdm = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedAdm != null)
          {
            totalNumberOfLinked += Number(linkedAdm.noofpeople);
          }
        });

        if(adm.onlysetmax)
        {
          adm.max = Number(totalNumberOfLinked);
          if (adm.max < adm.min)
          {
            adm.max = adm.min;
          }
          if(Number(adm.noofpeople) > Number(totalNumberOfLinked))
          {
            if(Number(OldNoOfPeople) > Number(totalNumberOfLinked))
            {
              adm.noofpeople = Number(OldNoOfPeople);
            }
            else
            {
              adm.noofpeople = Number(totalNumberOfLinked);
            }
          }
          adm.fixed = false;
        }
        else
        {
          adm.noofpeople = Number(totalNumberOfLinked);
          adm.fixed = true;
        }
      }
    });

  }

  sumAdmissions(){

    this.doLinkedDiscounts();

    this.doLinkedAdmissions();

    this.totalAdmissions = this.SelectedAdmissions.map(a => a.noofpeople).reduce(function(a, b)
    {
      return Number(a) + Number(b);
    });

    if((this.totalAdmissions > this.SelectedPackage.max && this.SelectedPackage.max > 0) || (this.totalAdmissions < this.SelectedPackage.min && this.SelectedPackage.min > 0))
    {
      this.totalAdmissionInRange = false;
    }
    else
    {
      this.totalAdmissionInRange = true;
    }

    this.isTimeAvailable(this.NewBooking.startTime);
  }

  returnHome(){}

  searchCustomer(){
    this.http.get(bmxurl + "customer/search/" + this.VenueID + "/" + this.searchCriteria).subscribe(response => {
      this.customersResult = (response as customer[])
    }, error => {
      console.log(error);
    })

  }

  addselectedCustomer(passCustomer){
    this.searchCriteria = "";

    this.CustomerHeader = "Customer: " +  passCustomer.firstName + "  " + passCustomer.lastName;

    let newCustomer: Customer = {
      id: null,
      customerid: null,
      firstName: passCustomer.firstName,
      lastName: passCustomer.lastName,
      fullName: passCustomer.firstName + " " + passCustomer.lastName,
      address1: passCustomer.address1,
      address2: passCustomer.address2,
      townCity: passCustomer.townCity,
      postCode: passCustomer.postCode,
      email: passCustomer.email,
      mobile: passCustomer.mobile,
      dob: this.dob,
      venueId: this.VenueID,
      disabled: false,
      bookingid: null
    }

    this.NewBooking.customer = newCustomer;

    this.customersResult = null;
    this.searchCriteria = "";

  }

  addCustomer(){
    //Check Search field for postcode

    if(this.valid_postcode(this.searchCriteria))
    {
      this.postCode = this.searchCriteria
    }
    else if(this.valid_mobile(this.searchCriteria))
    {
      //Check Telephone number
      this.mobile = this.searchCriteria
    }
    else
    {
      this.lastName = this.searchCriteria
    }

    this.ShowCustomerDetails = true;
    this.ProvideMinimalPersonalData = true;

  }

  doneCustomer()
  {
    this.CustomerAllValid = true;

    if(this.ProvideMinimalPersonalData)
    {
      if (this.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameValid = false;
      }
      else {
        this.lastNameValid = true;
      }

      if (this.email == "") {
        this.CustomerAllValid = false;
        this.EmailValid = false;
      }
      else {
        this.EmailValid = true;
      }

      if (this.mobile == "") {
        this.CustomerAllValid = false;
        this.MobileValid = false;
      }
      else {
        this.MobileValid = true;
      }
    }
    else
    {
      if (this.firstName == "") {
        this.CustomerAllValid = false;
        this.firstNameValid = false;
      }
      else {
        this.firstNameValid = true;
      }

      if (this.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameValid = false;
      }
      else {
        this.lastNameValid = true;
      }

      if (this.address1 == "") {
        this.CustomerAllValid = false;
        this.Address1Valid = false;
      }
      else {
        this.Address1Valid = true;
      }

      if (this.town == "") {
        this.CustomerAllValid = false;
        this.TownValid = false;
      }
      else {
        this.TownValid = true;
      }

      if (this.postCode == "") {
        this.CustomerAllValid = false;
        this.PostCodeValid = false;
      }
      else {
        this.PostCodeValid = true;
      }

      if (this.email == "") {
        this.CustomerAllValid = false;
        this.EmailValid = false;
      }
      else {
        this.EmailValid = true;
      }

      if (this.mobile == "") {
        this.CustomerAllValid = false;
        this.MobileValid = false;
      }
      else {
        this.MobileValid = true;
      }
    }

    //Check for valid email and mobile

    if (this.email != "") {
      if (!this.validateEmail(this.email)) {
        this.CustomerAllValid = false;
        this.EmailFormatValid = false;
      }
      else {
        this.EmailFormatValid = true;
      }
    }

    if(this.mobile != ""){
      if (!this.validateMobile(this.mobile)) {
        this.CustomerAllValid = false;
        this.MobileFormatValid = false;
      }
      else {
        this.MobileFormatValid = true;
      }
    }

    if(this.CustomerAllValid == true)
    {
      this.CustomerHeader = "Customer: " + this.firstName + "  " + this.lastName;

      let newCustomer: Customer = {
        id: null,
        customerid: null,
        firstName: this.firstName,
        lastName: this.lastName,
        fullName: this.firstName + " " + this.lastName,
        address1: this.address1,
        address2: this.address2,
        townCity: this.town,
        postCode: this.postCode,
        email: this.email,
        mobile: this.mobile,
        dob: this.dob,
        venueId: this.VenueID,
        disabled: false,
        bookingid: null
      }

      this.NewBooking.customer = newCustomer;

      this.ShowCustomerDetails = false;
      this.ShowCustomerSearch = false;
      this.customersResult = null;
      this.searchCriteria = "";

      
    }

    this.dosummary();
  }

  updateCustomer()
  {
    this.CustomerAllValid = true;

    if(this.ProvideMinimalPersonalData)
    {
      if (this.NewBooking.customer.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameEditValid = false;
      }
      else {
        this.lastNameEditValid = true;
      }

      if (this.NewBooking.customer.email == "") {
        this.CustomerAllValid = false;
        this.EmailEditValid = false;
      }
      else {
        this.EmailEditValid = true;
      }

      if (this.NewBooking.customer.mobile == "") {
        this.CustomerAllValid = false;
        this.MobileEditValid = false;
      }
      else {
        this.MobileEditValid = true;
      }
    }
    else
    {
      if (this.NewBooking.customer.firstName == "") {
        this.CustomerAllValid = false;
        this.firstNameEditValid = false;
      }
      else {
        this.firstNameEditValid = true;
      }

      if (this.NewBooking.customer.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameEditValid = false;
      }
      else {
        this.lastNameEditValid = true;
      }

      if (this.NewBooking.customer.address1 == "") {
        this.CustomerAllValid = false;
        this.Address1EditValid = false;
      }
      else {
        this.Address1EditValid = true;
      }

      if (this.NewBooking.customer.townCity == "") {
        this.CustomerAllValid = false;
        this.TownEditValid = false;
      }
      else {
        this.TownEditValid = true;
      }

      if (this.NewBooking.customer.postCode == "") {
        this.CustomerAllValid = false;
        this.PostCodeEditValid = false;
      }
      else {
        this.PostCodeEditValid = true;
      }

      if (this.NewBooking.customer.email == "") {
        this.CustomerAllValid = false;
        this.EmailEditValid = false;
      }
      else {
        this.EmailEditValid = true;
      }

      if (this.NewBooking.customer.mobile == "") {
        this.CustomerAllValid = false;
        this.MobileEditValid = false;
      }
      else {
        this.MobileEditValid = true;
      }
    }

    //Check for valid email and mobile

    if (this.NewBooking.customer.email != "") {
      if (!this.validateEmail(this.NewBooking.customer.email)) {
        this.CustomerAllValid = false;
        this.EmailFormatEditValid = false;
      }
      else {
        this.EmailFormatEditValid = true;
      }
    }

    if(this.NewBooking.customer.mobile != ""){
      if (!this.validateMobile(this.NewBooking.customer.mobile)) {
        this.CustomerAllValid = false;
        this.MobileFormatEditValid = false;
      }
      else {
        this.MobileFormatEditValid = true;
      }
    }
    
    if(this.CustomerAllValid == true)
    {
      let newCustomer: Customer = {
        id: this.NewBooking.customer.id,
        customerid:  this.NewBooking.customer.customerid,
        firstName:  this.NewBooking.customer.firstName,
        lastName:  this.NewBooking.customer.lastName,
        fullName:  this.NewBooking.customer.firstName + " " + this.NewBooking.customer.lastName,
        address1:  this.NewBooking.customer.address1,
        address2:  this.NewBooking.customer.address2,
        townCity:  this.NewBooking.customer.townCity,
        postCode:  this.NewBooking.customer.postCode,
        email: this.NewBooking.customer.email,
        mobile: this.NewBooking.customer.mobile,
        dob: this.NewBooking.customer.dob,
        venueId: this.VenueID,
        disabled: false,
        bookingid: this.NewBooking.bookingId
      }

      this.http.put<string>(bmxurl + "/customer", newCustomer).subscribe(response => {
        this.BookingResponse = response 
        if(this.BookingResponse.succeeded)
        {
          this.NewBooking = this.BookingResponse.data;
          this.dosummary();

          this.messageService.add({severity:'success', summary:'Success', detail:'Customer updated' });

          this.ShowSaveButton = false;
        }
        else{
          this.messageService.add({severity:'error', summary:'Failure', detail:'This Customer could not be updated' });
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Failure', detail:'This Customer could not be updated' });
      })
    }
  }

  applyCustomer(passCustomer)
  {
    this.ShowCustomerDetails = true;
    this.firstName = passCustomer.firstName;
    this.lastName = passCustomer.lastName;
    this.address1 = passCustomer.address1;
    this.address2 = passCustomer.address2;
    this.town = passCustomer.townCity;
    this.postCode = passCustomer.postCode;
    this.email = passCustomer.email;
    this. mobile = passCustomer.mobile;

    this.customersResult = null;
    this.searchCriteria = "";

  }

  saveCustomer()
  {
    this.NewBooking.customer.bookingid = this.NewBooking.bookingId;

    this.http.post<any>(bmxurl + "/Customer", this.NewBooking.customer).subscribe(response => {
      if(response.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Customer Changed', detail:'Customer successfully changed to: ' + this.NewBooking.customer.fullName});
          this.dosummary();
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Failed to update customer', detail:'Failed to update customer.'});
        }
      }, error => {
        console.log(error);
    })
    this.ShowCustomerSearch = false;
    this.CustomerHasBeenEdited = false;
  }

  validateEmail(email){ 
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; 
    return re.test(email); 
  }

  validateMobile(mobile){ 
    var re = /^($|(^\\+)?[0-9()-]*)$/; 
    return re.test(mobile); 
  }

  valid_postcode(postcode) {
    postcode = postcode.replace(/\s/g, "");
    var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
    return regex.test(postcode);
  }

  valid_mobile(postcode) {
    postcode = postcode.replace(/\s/g, "");
    var regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regex.test(postcode);
  }

  doPaymentTypes()
  {
    this.http.get(bmxurl + "paymenttypes").subscribe(response => {
      this.PaymentTypes = (response as paymenttypes_returndata).data;

      this.PaymentTypes = this.PaymentTypes.filter(pt => pt.system == false);
      
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
    })
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.RefundPayment == null || this.RefundPayment == 0)
    {
      AllValid = false;
      this.RefundPaymentValid = false;
    }
    else
    {
      this.RefundPaymentValid = true;
    }

    if(this.RefundReason == null || this.RefundReason == "")
    {
      AllValid = false;
      this.RefundTextValid = false;
    }
    else
    {
      this.RefundTextValid = true;
    }

    return AllValid;
  }

  doRefund()
  {
    if (this.DoValidCheck())
    {
      let newRefund: cRefund={
        venueid: this.VenueID,
        paymentid: this.RefundPaymentId,
        reason: this.RefundReason,
        amount: this.RefundPayment,
        notes: "",
        disabled: false
      }

      this.busyService.busy();
      this.http.post<refund>(bmxurl + "/refund", newRefund).subscribe(response => {
        this.httpResponse = response

        if(this.httpResponse.succeeded)
        {
          this.http.get(bmxurl + "booking/" + this.VenueID + "/" + this.id).subscribe(response => {
            this.NewBooking = (response as booking_returndata).data;
            this.busyService.idle();
            this.displayModalRefund = false;

            this.dosummary();
            this.messageService.add({severity:'success', summary:'Saved', detail:'Refund successful, the refund has been passed to the card issuer and will be processed by them in accordance to their refund policy.'});
            this.RefundReason = "";
            
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting updated booking'});
            this.busyService.idle();
          })
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Not Saved', detail: this.httpResponse.messages[0]});
          this.busyService.idle();
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail: this.httpResponse.messages[0]});
        this.busyService.idle();
    })
    
    }
  }
  
  setupRefund(paymentID: string)
  {
    let payment: Payment;
    this.RefundPaymentId = paymentID;

    this.http.get(bmxurl + "Payment/" + this.VenueID + "/" + paymentID).subscribe(response => {
       payment = (response as payment_returndata).data;

       this.RefundPayment = payment.amount;
    }, error => {
      console.log(error);
    })

    this.displayModalRefund = true;
  }

  resetlock()
  {
    this.paymentButtonLocked = false;
  }

  makeNewPayment()
  {
    //Get number of people
    let NumPeople: number = 0;
    this.NewBooking.resources[0].admissions.forEach(adm => {
      NumPeople += adm.noofpeople;
    });

    this.AllValid = true;

    if (this.validPaymentAmount == null || (this.validPaymentAmount < 0)) {
      this.AllValid = false;
      this.paymentValueValid = false;
    }
    else {
      this.paymentValueValid = true;
      this.AllValid = true;
    }

    if (this.selectedPayment == null) {
      this.AllValid = false;
      this.paymentTypeValid = false;
    }
    else {
      this.paymentTypeValid = true;
      this.AllValid = true;
    }

    if(this.AllValid)
    {
      this.paymentButtonLocked = true;

      let newPayment: Payment = {
        id: null,
        PaymentId: null,
        BookingId: this.NewBooking.bookingId,
        bookingIdHR: this.NewBooking.bookingIdHR,
        method: this.selectedPayment.name,
        paymenttypeid: this.selectedPayment.id,
        amount: this.validPaymentAmount,
        paymentDate: new Date,
        disabled: false,
        venueid: this.VenueID,
        refunds: null,
        balance: 0
      }

      this.NewBooking.payments = [];
      this.NewBooking.payments.push(newPayment);
      this.NewBooking.inProgress = false;

      this.http.post<booking_returndata>(bmxurl + "/Payment", this.NewBooking).subscribe(response => {
        this.BookingResponse = (response as booking_returndata).data

        this.NewBooking =this.BookingResponse;
        
        if(response.succeeded == true)
        {
          this.BookingPayments = this.BookingResponse.payments;
          this.NewBooking.totaladmissions = this.BookingResponse.totaladmission;
  
          this.validPaymentAmount = this.NewBooking.outstanding;

          this.totalPayments = 0;
          this.BookingPayments.forEach(element => {
            this.totalPayments += element.amount;
          });

          this.selectedPayment = null;

          this.dosummary();
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail: response.messages});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail: error.message});
      })
    }
  }  

  bookSlot(timeslot: AvailableTimes)
  {
    this.busyService.busy();
    let found: boolean = false;

    var instanceID: any = "BMP1"
    var id:any = this.VenueID;
    var todaysDate: Date = new Date(timeslot.time);
    var thisYear: any = todaysDate.getFullYear();
    var thisMonth: any = ('00' + (todaysDate.getMonth() + 1)).slice(-2);
    var thisDay: any = ('00' + todaysDate.getDate()).slice(-2);
    var fullDate: any = thisYear + thisMonth + thisDay;
  
    //var venueDate: any =  id + "_" + fullDate;

    this.SelectedPackage.resources.forEach(adm => {
      adm.admissions = this.SelectedAdmissions;
   });

    let totalNoOfPeople: number = 0;

    //this.NewBooking.venuedate = venueDate
    this.NewBooking.packageId = this.SelectedPackage.packageId;
    this.NewBooking.guests =  totalNoOfPeople;
    this.NewBooking.startTime = timeslot.time;
    this.NewBooking.PackageName = this.SelectedPackage.name;
    this.NewBooking.venueId = this.VenueID;
    this.NewBooking.resources = timeslot.resources; //this.SelectedPackage.resources;
    this.NewBooking.Disabled = false;
    this.NewBooking.outstanding = 0;
    this.NewBooking.name = "";
    this.NewBooking.salestype = 0;

    this.http.put<booking_returndata>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.httpResponse = (response as booking_returndata);     

      this.BookingResponse = this.httpResponse.data
        this.NewBooking = this.BookingResponse.bookingObj

        let NumPeople: number = 0;
        this.NewBooking.resources[0].admissions.forEach(adm => {
          NumPeople += adm.noofpeople;
        });
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;
        //this.OutstandingBalance = this.BookingResponse.bookingObj.outstanding;

        this.validPaymentAmount = this.NewBooking.outstanding;


        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: this.CurrencySymbol});
  
        this.startTimes = null;
        this.startTimesFiltered = null;
  

      this.messageService.add({severity:'success', summary:'Success', detail:'Booking Saved, please check the details are correct on the right;' });
      this.dosummary();

      this.busyService.idle();

      this.ShowSaveButton = false;

      this.busyService.idle();

      this.loaddata();

    }, error => {
      console.log(error);
      this.busyService.idle();
    })
  }


  sendSMSConfirmation(){
    this.http.get<any>(bmxurl + "confirmation/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'SMS Message sent.'});
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not send SMS: ' + response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the SMS'});
    })
 }

 sendEmailConfirmation(){
    this.http.get<any>(bmxurl + "confirmationemail/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Email Message sent.'})
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not send Email: ' + response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })
 }

 loadTasks(){
  this.loaddata();
 }
  

  saveNotes(){
    let newNotes: any = {
      bookingid: this.NewBooking.bookingId,
      note: this.NewBooking.notes
    }

    this.http.post<string>(bmxurl + "/NotesUpdate", newNotes).subscribe(response => {
      this.BookingResponse = response 
      console.log(response);

      this.messageService.add({severity:'success', summary:'Success', detail:'Your notes have been saved.' });

    }, error => {
      console.log(error);
    })
  }

  addMs = (date: Date, ms: number): Date => {
    const result = new Date(date);
    result.setMilliseconds(result.getMilliseconds() + ms);
    return result;
  };

  subscribe()
  {
    this.router.navigateByUrl("/subscriptions");
  }

  
}


enum Sections {
  Date = 0,
  Package = 1,
  Admissions = 2,
  Partyroom = 3,
  Time = 4,
  Customer = 5,
  Extras = 6,
  Notes = 7,
  Payment = 8,
}


