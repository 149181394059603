<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Birthday Campaign
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">What would you like to call this Campaign?</label>
                    <input [(ngModel)]="TaskType.name" id="classname" type="text" [ngStyle]="{'border-color': (taskNameValid==false) ? '#FF0000':''}"  pInputText>
                </div>
                <div *ngIf="!taskNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Task Type name.</div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">You can add a description here, this may help you remember what the Campaign does in the future.</label>
                    <input [(ngModel)]="TaskType.desc" id="classname" type="text" [ngStyle]="{'border-color': (taskDescValid==false) ? '#FF0000':''}"  pInputText>
                </div>

                <div class="field mb-4 col-12">
                    <label for="dpStartTime" class="font-medium">Start Date</label>
                    <div style="max-width: 500px;">
                        <p-calendar [selectOtherMonths]="true" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="packagestartdate" [(ngModel)]="TaskType.startdate"></p-calendar>     
                      </div>
                  </div>
      
                  <div class="field mb-4 col-12">
                    <label for="dpEndTime" class="font-medium">End Date</label>
                    <div style="max-width: 500px;">
                        <p-calendar [selectOtherMonths]="true" [utc]="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="packageenddate" [(ngModel)]="TaskType.enddate"></p-calendar>     
                    </div>
                  </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">How many days after the booking would you like this sent?</label>
                    <p-inputNumber id="length" [style]="{'width': '300px'}"  [(ngModel)]="TaskType.noofdays" [showButtons]="true" max="365" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">What Email Template would you like to use for this Campaign?</label><br>
                    <p-dropdown [options]="EmailTemplates"  placeholder="Select a template" [showClear]="true" [(ngModel)]="TaskType.emailtemplate" optionLabel="name"></p-dropdown>
                </div>
                <div *ngIf="!taskEmailTemplateValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select an Email for this task.<br></div>
                <div style="width:100%; clear:both;"></div>
                
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">How many times would you like to send this reminder out (These will be sent regardless if this customer books) </label>
                    <p-inputNumber id="autogeneratenumber" [(ngModel)]="TaskType.noofdays" [style]="{'width': '300px'}" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div class="field mb-4 col-12">
                    <label for="discountType" class="font-medium">Would you like to give this customer a discount on their next visit?</label>
                    <p-selectButton id="discountType" [style]="{'width': '300px'}" [options]="stateOptionsDiscount" [(ngModel)]="TaskType.discounttype" ></p-selectButton>
                </div>

                <div *ngIf="TaskType.discounttype == 'FIXED'" class="field mb-4 col-12">
                    <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                    <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" mode="currency" [(ngModel)]="TaskType.discountamount" currency="{{CurrencySymbol}}" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div *ngIf="TaskType.discounttype == 'PERCENTAGE'" class="field mb-4 col-12">
                    <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                    <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" suffix="%" [(ngModel)]="TaskType.discountamount"  [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;margin-bottom: 20px;">
                    <div class="field mb-4 col-12">
                      <label for="" class="font-medium">Please select any Tags you would like to limit this Campaign to.</label>
                      <br />
                      <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="TaskType.tags" display="chip">
                        <ng-template let-value pTemplate="selectedItems">
                          <span *ngFor="let tag of TaskType.tags">
                            <p-chip label="{{tag.name}}" styleClass="mr-2"  [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                              <!-- <span class="p-chips-token-icon pi pi-cog" style="margin-right:8px"></span> -->
                            </p-chip>
                          </span>
                          <div *ngIf="!TaskType.tags || TaskType.tags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                        </ng-template>
                      </p-multiSelect>
                    </div>
                  </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    :host ::ng-deep .p-chip.custom-chip {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }
  </style>