import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { availablesubscriptions } from 'src/types/availablesubscriptions';
import { GlobalService } from '../_services/global.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { bmxurl, getAccess } from 'src/globals';
import { availablesubscriptions_returndata } from 'src/types/availablesubscriptions_returndata';
import { activesubscriptions } from 'src/types/activesubscriptions';
import { activesubscriptions_returndata } from 'src/types/activesubscriptions_returndata';
import { BusyService } from '../_services/busy.service';

@Component({
  selector: 'app-buycredits',
  templateUrl: './buycredits.component.html',
  styleUrls: ['./buycredits.component.css']
})
export class BuycreditsComponent {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  classNameValid: boolean = true;

  AccessAllowed: boolean = true;
  CompletedLoading: boolean = true;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  availableSubscriptions: availablesubscriptions[] = [];

  availableSubscriptionsAll: availablesubscriptions[] = [];

  activeSubscriptions: activesubscriptions[] = [];

  passBackSubscriptions: activesubscriptions[] = [];

  constructor(private http: HttpClient, 
      private router: Router, 
      private messageService: MessageService, 
      private globalService: GlobalService,
      private busyService: BusyService) {
      this.getSubscriptions();
  }   

  getSubscriptions() {
    this.busyService.busy();
    this.http.get<availablesubscriptions_returndata>(bmxurl + "availablesubscriptions").subscribe(Response => {
        this.availableSubscriptionsAll = Response.data;

        this.http.get<activesubscriptions_returndata>(bmxurl + "activesubscriptions/" + this.VenueID).subscribe(Response => {
          this.passBackSubscriptions = Response.data;
          
          if(this.passBackSubscriptions != null)
          {
            this.passBackSubscriptions.forEach(sub => {
              if(sub.disabled == false) {
                this.activeSubscriptions.push(sub);
              }
            });
          }
            this.availableSubscriptionsAll.forEach(sub => {
                let found = false;
                if(this.activeSubscriptions != null) 
                {
                  this.activeSubscriptions.forEach(active => {
                      if (sub.subscriptionid == active.subscriptionid && active.disabled == false) {
                        active.description = sub.description;
                        active.name = sub.name;
                        active.price = sub.price;
                        active.imageurl = sub.imageurl;

                        found = true;
                        }
                      });
                  if (!found) {
                    this.availableSubscriptions.push(sub);
                  }
                }
                else {
                  this.availableSubscriptions.push(sub);
                }
            }
            );

            this.busyService.idle();
        },
         error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Subscriptions' });
        });
    },
    error => {
      this.messageService.add({ severity: 'error', summary: 'Unable to get Subscriptions' });
    });
  }

  subscribe(subscription: availablesubscriptions) {
    this.busyService.busy();
    //window.location.href = bmxurl + "/CreateWBSubscription/" + this.VenueID + "/" + subscription.subscriptionid;

    window.open(
      bmxurl + "/CreateWBSubscription/" + this.VenueID + "/" + subscription.subscriptionid,
    '_blank'
    ).focus();

    this.router.navigateByUrl("/home");

    this.busyService.idle();
  }

  manageSubscription(subscription: activesubscriptions) {
    this.busyService.busy();
    //window.location.href = bmxurl + "/ManageWBSubscription/" + this.VenueID + "/" + subscription.stripeSubscriptionId;

    window.open(
      bmxurl + "/ManageWBSubscription/" + this.VenueID + "/" + subscription.stripeSubscriptionId,
    '_blank'
    ).focus();

    this.router.navigateByUrl("/home");

    this.busyService.idle();
  }

  cancelSubscription(subscription: activesubscriptions) {
    this.busyService.busy();
    this.http.get(bmxurl + "ManageWBSubscriptionCancel/" + this.VenueID + "/" + subscription.stripeSubscriptionId).subscribe(Response => {
      this.getSubscriptions();
      this.busyService.idle();
    },
    error => {
      this.messageService.add({ severity: 'error', summary: 'Unable to cancel Subscription' });
      this.busyService.idle();
    });
  }

  checkYear(inDate) {
    if (Number(new Date(inDate).getFullYear()) > 1) {
      return true;
    }
    else {
      return false;
    }
  }
  
}
