<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Link
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="linkame" class="font-medium">Link Name</label>
                    <input [(ngModel)]="Link.linkname" id="linkname" type="text" [ngStyle]="{'border-color': (LinkNameValid==false) ? '#FF0000':''}" pInputText>
                </div>
                <div *ngIf="!LinkNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Link name.</div>

                <div class="field mb-4 col-12">
                    <label for="pckorgroup" class="font-medium">Would you like this link to be to a specific Package or a Package Group?</label>
                    <p-selectButton id="pckorgroup" [style]="{'width': '470px'}" [options]="pkgStateOptions" [(ngModel)]="LinkType" ></p-selectButton>
                </div>
                
                <div *ngIf="LinkType=='PACKAGE'" class="field mb-4 col-12">
                    <label for="ddlPackage" class="font-medium">Select a Package</label><br>
                    <p-dropdown [options]="packages" [(ngModel)]="selectedpackage" placeholder="Select a Package" [showClear]="true" optionLabel="name"></p-dropdown>
                    <div *ngIf="!LinkPackageValid" class="text-danger" Style="margin-left:10px"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Package.</div>
                </div>
                
                <div *ngIf="LinkType=='PACKAGEGROUP'" class="field mb-4 col-12">
                    <label for="ddlPackageGroup" class="font-medium">Select a Package Group</label><br>
                    <p-dropdown [options]="packagegroups" [(ngModel)]="selectedpackagegroup" placeholder="Select a Package Group" [showClear]="true" optionLabel="packagegroupname"></p-dropdown>
                    <div *ngIf="!LinkPackageGroupValid" class="text-danger" Style="margin-left:10px"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Package Group.</div>
                </div>
                
                <div class="field mb-4 col-12">
                    <label for="pckorgroup" class="font-medium">Would you like select a Date for this link?</label>
                    <p-selectButton id="pckorgroup" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="LinkDate" ></p-selectButton>
                </div>

                <div *ngIf="LinkDate" class="field mb-4 col-12" style="max-width: 500px;">
                    <label for="venueclosingstartdate">What Date would you like this link to be for?</label>
                    <p-calendar [selectOtherMonths]="true" [(ngModel)]="Link.datetobook" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true" inputId="venueclosingstartdate"></p-calendar>
                    <div style="clear:both;"></div>
                    <div *ngIf="!LinkDateValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>The selected Date must be in the future and can't be earlier than the Package Start Date ({{MinDate | date:'dd/MM/yyyy'}}) or later than the Package End Date ({{MaxDate | date:'dd/MM/yyyy'}})</div>
                </div>

                <div style="clear:both;"></div>

                <div *ngIf="LinkType == 'PACKAGE'" style="width:100%;">
                    <div class="field mb-4 col-12">
                        <label for="pckorgroup" class="font-medium">Would you like select a Time for this link?</label>
                        <p-selectButton id="pckorgroup" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="LinkTime" ></p-selectButton>
                    </div>

                    <div *ngIf="LinkTime" class="field mb-4 col-12" style="max-width: 500px;">
                        <label for="venueclosingenddate">What Time would you like this link to be for?</label>
                        <br>
                        <p-dropdown [options]="linkTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="linkStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                        <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                        <p-dropdown [options]="linkTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="linkStartTimeMinutes" optionValue="code" optionLabel="name"></p-dropdown>
                        <div style="clear:both;"></div>
                    </div>
                </div>

                <div class="field mb-4 col-12">
                    <label for="pckorgroup" class="font-medium">Would you like this link to expire?</label>
                    <p-selectButton id="pckorgroup" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="LinkExpire" ></p-selectButton>
                </div>

                <div *ngIf="LinkExpire" class="field mb-4 col-12" style="max-width: 500px;">
                    <label for="venueclosingstartdate">What date would you like the link to expire?</label>
                    <p-calendar [selectOtherMonths]="true" [(ngModel)]="Link.expirydate" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true" inputId="venueclosingstartdate"></p-calendar>
                    <div style="clear:both;"></div>
                    <div *ngIf="!LinkExpiryValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>The selected Expiry Date must be in the future.</div>
                </div>
                
                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>