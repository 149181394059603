<div *ngIf="pageReady" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Edit a Gift Voucher
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Edit a gift voucher.
    </p>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                
                <label style="margin-bottom: 20px;" class="font-medium">Voucher Code/PIN</label>
                <div>
                    <div tyle="font-size: 20px;">
                        <strong>{{Voucher.vouchercode}}</strong>&nbsp;/&nbsp;<strong>{{Voucher.voucherpin}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                
                <label style="margin-bottom: 20px;" class="font-medium">Expiry Date</label>
                <div>
                    <div>
                        <p-calendar [selectOtherMonths]="true" [style]="{'margin-top' : '20px'}" [showIcon]="true" [showButtonBar]="true" [showOnFocus]="true" [showTime]="false" [readonlyInput]="true" [minDate]="minDate" [maxDate]="maxDate" [dateFormat]="'dd/mm/yy'" [locale]="en" [(ngModel)]="Voucher.expiry"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                
                <label style="margin-bottom: 20px;" class="font-medium">Value</label>
                <div>
                    <div>
                        <span style="font-size:20px">{{Voucher.balance| currency: CurrencySymbol}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label style="margin-bottom: 20px;" class="font-medium">Customer Information</label>
                <div>
                    <div class="field mb-4 col-12">
                        <label for="customername" class="font-medium">Your Name</label>
                        <input id="customername" type="text" pInputText autocomplete="family-name" [(ngModel)]="Voucher.customername">
                    </div>
                    <div *ngIf="!NameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                    <div style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Email</label>
                            <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.emailaddress">
                        </div>
                     
                        <div *ngIf="!EmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                        <div *ngIf="!EmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                        
                        <div class="field mb-4 col-12">
                            <label for="voucherForSomeoneElse" class="font-medium">Is this voucher is for someone else?</label>
                            <p-selectButton id="voucherForSomeoneElse" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Voucher.alternativerecipient"></p-selectButton>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="Voucher.alternativerecipient" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label style="margin-bottom: 20px;" class="font-medium">Recipient Information</label>
                <div>
                    <div class="field mb-4 col-12">
                        <label for="lastname" class="font-medium">Recipient's Name</label>
                        <input id="lastname" type="text" pInputText autocomplete="family-name"  [(ngModel)]="Voucher.recipientsname">
                    </div>
                    <div *ngIf="!RecipientNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                    <div style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Recipient's Email</label>
                            <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.recipientsemailsaddress">
                        </div>
                     
                        <div *ngIf="!RecipientEmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                        <div *ngIf="!RecipientEmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Message</label>
                        <textarea pInputText type="text" style="width:100%; height: 100px;" [(ngModel)]="Voucher.message"></textarea>
                    </div>
                    <div *ngIf="!messageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a message.</div>

                </div>
            </div>
        </div>
    </div>
    
    <div class="col-12">
        <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
        <span class="pi pi-pencil" style="margin-right:8px;"></span>
        Save Changes</button>
        &nbsp;
        <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
        <span class="pi pi-times" style="margin-right:8px;"></span>
        Cancel</button>
    </div>
</div>
