
<div class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3">
    <h3>All Tasks for Testing</h3>
    </div>

    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
    <span class="pi pi-info-circle" style="margin-right:2px;"></span> List of all task statuses.
    </p>

    <span class="p-input-icon-left ml-auto" style="margin-bottom:10px; "> 
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="taskTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        &nbsp;
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
            <span class="pi pi-trash" style="margin-right:8px;"></span>
            <span i18>Disable Selected</span>
        </button>
    </span>

  <div class="surface-card p-4 shadow-2 border-round">
  <p-table #taskTable
  [value]="taskList" sortField="name" [paginator]="true" [rows]="50"
  [columns]="cols"
  [showCurrentPageReport]="true" responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  [rowsPerPageOptions]="[10,25,50]"
  [globalFilterFields]="['email', 'bookingtasktypename', 'bookingidhr', 'taskstatus', 'senddate']"
  [(selection)]="selectedItems"
  responsiveLayout="scroll"
  [selectionPageOnly]="true">
    <ng-template pTemplate="caption">
        <div class="flex">
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="taskTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        </div>
    </ng-template>
      <ng-template pTemplate="header">
          <tr>
            <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th width="25%">
                Email Address
            </th>
            <th pSortableColumn="name" width="34%">Name
                <p-sortIcon [field]="bookingtasktypename"></p-sortIcon>
            </th>
            <th  width="14%">
                Booking ID
            </th>
            <th  width="10%">
                Status
            </th>
            <th  width="12%">
                Send Date
            </th>
            <th  width="5%">
                Sent?
            </th>
            <th  width="5%">

            </th>
            <th width="5%">

            </th>
              
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
            <td *ngIf="data.taskstatus !='10'">
                <p-tableCheckbox [value]="data"></p-tableCheckbox>
            </td>
            <td *ngIf="data.taskstatus =='10'">
                <p-tableCheckbox [value]="data" disabled="true"></p-tableCheckbox>
            </td>
            <td>{{data.email}}</td>
            <td>{{data.bookingtasktypename}}</td>
            <td>
                {{data.bookingidhr}}
            </td>

            <td *ngIf="data.taskstatus =='0'">Not Sent</td>
            <td *ngIf="data.taskstatus =='10'">Sent</td>
            <td *ngIf="data.taskstatus =='20'">Not Sent (Disabled)</td>
            <td *ngIf="data.taskstatus =='30'">Not Sent (Paid)</td>
            <td *ngIf="data.taskstatus =='40'">Not Sent (Time Out)</td>
        
            <td>{{data.senddate | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngIf="data.sent == true" style="color: #22c55e;">
                <span class="pi pi-check"></span>
            </td>
            <td *ngIf="data.sent == false" style="color: #ef4444;">
                <span class="pi pi-times"></span>
            </td>
            <td>
                <button style="width: 135px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="goToBooking(data.bookingid)">
                    <span>Go to Booking</span>
                </button>
            </td>
            <td>
                <button *ngIf="data.taskstatus !='10'" style="width: 135px;" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableTask(data.bookingtaskid, data.bookingid)">
                    <span class="pi pi-trash" style="margin-right:8px;"></span>
                    <span>Disable</span>
                </button>

                <button *ngIf="data.taskstatus =='10'" style="width: 135px;" disabled="true" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component">
                    <span class="pi pi-trash" style="margin-right:8px;"></span>
                    <span>Disable</span>
                </button>
            </td>
        </tr>
      </ng-template>
  </p-table>
  </div>
</div>
