<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Your Additional Services
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round" style="width:100%;">
            Additional services can be added to your account at any time and will enhance the power of WannaBook. You will need a credit card to add the
service and it can be cancelled at any time.
            <div style="clear:both;">
                &nbsp;
            </div>


            <div style="float:left;width: 100%;padding: 5px;" class="surface-card p-4 shadow-2 border-round">
                <h2>My Services</h2>
                <div style="float:left;width: 100%;margin-top: 20px">
                    <div *ngIf="activeSubscriptions == null || activeSubscriptions.length == 0"> 
                        <b>You currently have no additional services.</b>
                    </div>
                    <div *ngFor="let sub of activeSubscriptions" style="border: 2px solid #dfe7ef; border-radius: 5px; background-color: white;padding: 10px;margin-bottom: 10px;position: relative;">
                        <div>
                            <div style="width: 60%;float: left;font-size: 20px;margin-left: 15px;">
                                <p><b>{{sub.name}}</b></p>
                            </div>

                            <!-- <button *ngIf="!checkYear(sub.stripeCancelAt)" pButton type="button" class="p-button p-button-danger" style="width:200px;margin-left:5px;margin-right:5px;height:50px;float:right;" (click)="cancelSubscription(sub)">
                                Cancel
                            </button> -->

                            <!-- <button *ngIf="checkYear(sub.stripeCancelAt)" disabled="true" pButton type="button" class="p-button p-button-danger" style="width:200px;margin-left:5px;margin-right:5px;height:50px;float:right;" (click)="cancelSubscription(sub)">
                                Cancel
                            </button> -->

                            <button pButton type="button" class="p-button p-button-warning" style="width:200px;margin-left:5px;margin-right:5px;height:50px;float:right;" (click)="manageSubscription(sub)">
                                Manage
                            </button>
                        </div>
                        <div style="clear:both;">
                        </div>

                        <div *ngIf="checkYear(sub.stripeCancelAt)" style = "margin-left: 15px;float: left;color: #ef4444;margin-top: -20px;font-style: italic;">
                            This subscription is cancelled and will expire on {{sub.stripeCancelAt | date: 'dd/MM/yyyy'}}
                            <div style="height:20px;clear:both;">&nbsp;</div>

                        </div>
                    </div> 
                    <div style="clear:both;">
                        &nbsp;
                    </div>
                </div>
            </div>

            <div style="clear:both;">
                &nbsp;
            </div>

            <div  style="float:left;width: 100%;padding: 5px;">
                <div>
                    <h2>Available Additional Services</h2>
                    <div style="float:left;width: 100%;margin-top: 20px">
                        <div *ngFor="let sub of availableSubscriptions" style="border: 2px solid #dfe7ef; border-radius: 5px; background-color: white;padding: 10px;margin-bottom: 10px;position: relative;">
                            <div>
                                <div style="width:226px;float:left;">
                                    <div style="width:200px; margin: auto;margin-left: 5px;margin-bottom: 10px;text-align: center;">
                                        <img src="{{sub.imageurl}}" style="width: 100%;">
                                        <div style="width:100%;margin-top:10px;"></div>
                                    </div>

                                    <button pButton type="button" class="p-button p-button-success" style="position:absolute;width:200px;margin-left:5px;margin-right:5px;height:50px;bottom:15px;right:20px" (click)="subscribe(sub)">
                                        Subscribe
                                    </button>
                                </div>
                                <div style="width:60%;float:left;">
                                    <p><b>{{sub.name}}</b></p>
                                    <p [innerHTML]="sub.description"></p>
                                    <div style="font-size:20px; font-weight:bold;">{{sub.price | currency: CurrencySymbol}}&nbsp;+ VAT</div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                        </div>  
                        <div style="clear:both;">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>



            <div style="clear:both;">&nbsp;</div>
        </div>
    </div>
</div>