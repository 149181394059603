import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { discount_returndata } from 'src/types/discount_returndata';
import { cDiscount, discount } from 'src/types/discounts';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit {

  ListDiscounts: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledDiscount: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  Discount: cDiscount;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  cols: any[];

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
      //this.isRowSelectable = this.isRowSelectable.bind(this);

      
    }


  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");

    let userid = localStorage.getItem("SmartUserID");

      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("DISCOUNTS", user)){
            this.AccessAllowed = true;
            this.loadgrid(false);
          }
  
          this.CompletedLoading = true;

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });

      this.cols = [
        { field: 'name', header: 'Name' },
        { field: 'code', header: 'Code' },
        { field: 'discountamount', header: 'Discount Amount' },
        { field: 'discounttype', header: 'Discount Type' },
        { field: 'startdate', header: 'Start Date' },
        { field: 'enddate', header: 'End Date' },
        ];
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(DiscountID)
  {
    this.http.delete(bmxurl + "discount/" + this.VenueID + "/" + DiscountID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Discount successfully disabled'});
        this.router.navigateByUrl("/discount");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Discount'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "DiscountList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListDiscounts = (response as discount_returndata);

        this.ListDiscounts.data = this.ListDiscounts.data.filter((discount) => discount.isEarlyBird == false);

        if ((response as discount_returndata).messages.length > 0)
        {
          (response as discount_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Discounts, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Discounts, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let DiscountId = EditData;
    this.router.navigateByUrl("/discountedit/" + DiscountId);
  }


  copyData(CopyData)
  {
    let DiscountId = CopyData;

      this.http.get(bmxurl + "discount/" + this.VenueID + "/" + DiscountId).subscribe(response => {
        this.httpResponse = response;
        this.Discount = {
          id: null,
          discountid: null,
          autogeneratecodes: (this.httpResponse.data as cDiscount).autogeneratecodes,
          numberofautogeneratedcodes: (this.httpResponse.data as cDiscount).numberofautogeneratedcodes,
          name: "COPY - " + (this.httpResponse.data as cDiscount).name, 
          code: "",
          discounttype: (this.httpResponse.data as cDiscount).discounttype,
          discountamount: (this.httpResponse.data as cDiscount).discountamount,
          visibility: (this.httpResponse.data as cDiscount).visibility,
          startdate: new Date((this.httpResponse.data as cDiscount).startdate),
          enddate: new Date((this.httpResponse.data as cDiscount).enddate),
          availablealldays: (this.httpResponse.data as cDiscount).availablealldays,
          monday: (this.httpResponse.data as cDiscount).monday,
          tuesday: (this.httpResponse.data as cDiscount).tuesday,
          wednesday: (this.httpResponse.data as cDiscount).wednesday,
          thursday: (this.httpResponse.data as cDiscount).thursday,
          friday: (this.httpResponse.data as cDiscount).friday,
          saturday: (this.httpResponse.data as cDiscount).saturday,
          sunday: (this.httpResponse.data as cDiscount).sunday,
          numberofuses: (this.httpResponse.data as cDiscount).numberofuses,
          numberofused: (this.httpResponse.data as cDiscount).numberofused,
          useonallpackages: (this.httpResponse.data as cDiscount).useonallpackages,
          packages: (this.httpResponse.data as cDiscount).packages,
          disabled: (this.httpResponse.data as cDiscount).disabled,
          venueid: (this.httpResponse.data as cDiscount).venueid,
          redeemdate: (this.httpResponse.data as cDiscount).redeemdate,
          isgenerated: (this.httpResponse.data as cDiscount).isgenerated,
          isEarlyBird: (this.httpResponse.data as cDiscount).isEarlyBird
        }

        //Got Discount, now resave it.
        this.http.post<discount>(bmxurl + "/discount", this.Discount).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Discount successfully copied' });
            this.editData(this.httpResponse.data.discountid)
          }
          else
          {
            this.messageService.add({ severity: 'error', summary: 'Error Copying Discount', detail: this.httpResponse.messages[0] });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Error Copying Discount', detail: error.messages });
        })


      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error Copying Package'});
      });
      
  }













  listHistoryData(ListData)
  {}

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "discount/" + this.VenueID + "/" + element.classID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Discounts, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Discounts, error: ' + error.messages});
    })
    });
    
  }

  
  }

