<p-confirmDialog header="Are you sure?" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Email customers" [(visible)]="showEmailCustomers" [style]="{width: '75vw', height: '75vw'}" [baseZIndex]="10000"  [modal]="true">
    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:5px; height: calc(75vh - 50px);">
    <div class="field mb-4 col-12">
        <p>If you would like to email all customers that have booked this event to inform them of the cancellation, please choose a template below, fill out any information and click 'Email customers'. If you want to just cancel the event and not email customers, just click 'Don't email customers'</p>
    </div>
    <div class="field mb-4 col-12" style="margin-top: -40px; width:100%;">
        <label for="template" class="font-medium">Email Template</label>
        <br>
        <p-dropdown [style]="{'width': '100%'}" [options]="ListEmailTemplates" [(ngModel)]="SelectedEmailTemplate" optionLabel="name" placeholder="Select a template" (onChange)="changeTemplate()"></p-dropdown>
    </div>
    <div *ngIf="EmailContainsDynamicText" class="field mb-4 col-12" style="margin-top: -32px">
        <p-button [style]="{'width': '100%'}" icon="pi pi-pencil" label="{{DynamicContentButton}}" styleClass="p-button p-button-danger" (click)="AddDynamicText()"></p-button>
    </div>
    <div style="overflow-x: hidden; height: calc(75vh - 348px)">
        <div [innerHTML]="ExampleEmail | safe: 'html'">
        </div>
    </div>

    </div>
  <ng-template pTemplate="footer">

    <div *ngIf="!textValid" class="text-danger" Style="float:left; margin-top: 17px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your email must contain some text.</div>
    <div *ngIf="!textValidDT" class="text-danger" Style="float:left; margin-top: 17px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You complete all of your Dynamic Text areas.</div>

    <p-button icon="pi pi-check" label="Email customers" styleClass="p-button p-button-success" (click)="EmailCustomersAndCancelEvent(SelectedEvent, SelectedEventDate)"></p-button>
    <span *ngIf="!DontShowNoEmailButton">
        &nbsp;
        <p-button icon="pi pi-times" label="Don't email customers" styleClass="p-button p-button-danger"  (click)="CancelEvent(SelectedEvent, SelectedEventDate)"></p-button>
    </span>

    <span *ngIf="ShowCancelButton">
        &nbsp;
        <p-button icon="pi pi-times" label="Cancel" styleClass="p-button p-button-danger"  (click)="closeModal()"></p-button>
    </span>
</ng-template>
  
  </p-dialog>


  <p-dialog header="Complete Dynamic Text" [(visible)]="ShowDynamicTextEditor" [style]="{width: '75vw', height: '75vw'}" [baseZIndex]="10000"  [modal]="true">
    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:5px; height: calc(75vh - 50px);overflow-x: hidden;">

        <div class="field mb-4 col-12">
            <p>Please fill out the text for each of your dynamic text areas</p>
        </div>
        <div *ngFor="let c of dynamicTextArray"  class="field mb-4 col-12" style="margin-top: -40px; width:100%;">
            <label for="template" class="font-medium">Dynamic Text {{c.index}}</label>
            <br>
            <textarea pInputText [(ngModel)]="c.text" style="width:100%"></textarea>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" label="Save Text" styleClass="p-button p-button-success" (click)="SaveText()"></p-button>
        &nbsp;
        <p-button icon="pi pi-times" label="Cancel" styleClass="p-button p-button-danger"  (click)="CancelText()"></p-button>
    </ng-template>
  
  </p-dialog>

<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListEvents && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3">
        <h3>Events</h3>
      </div>
      
      <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span> Events is where you define ticketed events you sell.  This is the section that brings all the other elements together, allowing us to calculate availability and price for a booking.
      </p>
  <div style="text-align: right; margin-bottom: 20px;">

      <div style="width: 200px;float: left;">
            <p-dropdown [style]="{'width': '200px', 'text-align': 'left'}" [options]="ListEventDateFilter" [(ngModel)]="SelectedEventDateFilter" optionLabel="name" (onChange)="changeEventDateFilter()"></p-dropdown>
      </div>

      <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon ="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
      </p-toggleButton>

      <span class="p-input-icon-left ml-auto" style="margin-right:20px; ">
          <i class="pi pi-search"></i>
          <input *ngIf="ListEvents"  pInputText type="text" (input)="eventssTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
      </span>

      <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="addNewEvent()">
      <span class="pi pi-plus" style="margin-right:8px;"></span>
      <span>Add</span>
      </button>
      &nbsp;
      <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
      <span class="pi pi-trash" style="margin-right:8px;"></span>
      <span i18>Disable</span>
      </button>
  </div>
  <div class="surface-card p-4 shadow-2 border-round">
  <p-table #eventsTable
  [value]="ListEvents" [paginator]="true" [rows]="10"
  [showCurrentPageReport]="true" responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  [rowsPerPageOptions]="[10,25,50]"
  [globalFilterFields]="['name']"
  [(selection)]="selectedItems"
  responsiveLayout="scroll"
  datakey="TagID"
  [selectionPageOnly]="true">
      <ng-template pTemplate="header">
          <tr>
              <th>
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="name" width="80%">Name
              </th>
              <th width="20%"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
          <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
              <td>
                  <p-tableCheckbox [value]="data"></p-tableCheckbox>
              </td>
              <td>
                <div class="surface-card p-4 shadow-2 border-round">
                    <table style="width:100 !important">
                        <tr>
                            <td width="85%" style="text-align: center; padding-bottom:20px;" colspan="2">
                                <span style="font-weight:bold">{{data.name}}&nbsp;({{data.eventdates[0].startdatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}&nbsp;-&nbsp;{{data.eventdates[data.eventdates.length - 1].enddatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}})</span>
                            </td>
                            <td rowspan="2">
                                <div style="width:150px !important; height:50px !important;margin-top:-55px; margin-left:20px;">
                                    <p-chart type="pie" [data]="data.chartData" [options]="basicOptions"></p-chart>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: center">
                                <span style="font-size: 20px">Total Tickets</span>
                            </td>
                            <td style="text-align: center">
                                <span style="font-size: 20px">Tickets Sold</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: center">
                                <span style="font-size: 20px; font-weight:bold">{{data.totalnumberoftickets}}</span>
                            </td>
                            <td style="text-align: center">
                                <span style="font-size: 20px; font-weight:bold">{{data.totalnumberofticketssold}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
              </td>


              <td>
                <table style="width:100 !important">
                    <tr>
                        <td>
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width:150px;height:70px;margin-bottom:10px;margin-right:10px" (click)="editData(data.eventid);">
                                <span class="pi pi-pencil" style="margin-right:8px;"></span>
                                <span i18n>Edit</span>
                            </button>
                        </td>
                        <td>
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" style="width:150px;height:70px;margin-bottom:10px;margin-right:10px" (click)="copydata(data.eventid)">
                                <span class="pi pi-copy" style="margin-right:8px;"></span>
                                <span i18n>Copy</span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:150px;height:70px" (click)="disableData(data.eventid)">
                                <span class="pi pi-trash" style="margin-right:8px;"></span>
                                <span i18n>Disable</span>
                            </button>
                        </td>
                        <td>
                            <button *ngIf="!data.showinnerbookings" pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:150px;height:70px" (click)="openEvent(data)">
                                <span class="pi pi-chevron-down" style="margin-right:8px;"></span>
                                <span i18n>Show Events</span>
                            </button>

                            <button *ngIf="data.showinnerbookings" pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:150px;height:70px" (click)="openEvent(data)">
                                <span class="pi pi-chevron-up" style="margin-right:8px;"></span>
                                <span i18n>Hide Events</span>
                            </button>
                        </td>
                    </tr>
                </table>
              </td>
          </tr>
          <tr *ngIf="data.showinnerbookings">
            <td colspan="6">
            <p-table #subEventsTable
            [value]="data.eventdates" sortField="name" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            [rowsPerPageOptions]="[10,25,50]"
            [globalFilterFields]="['name']"
            [(selection)]="selectedItems"
            responsiveLayout="scroll"
            datakey="TagID"
            [selectionPageOnly]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th width="30%">From Date</th>
                        <th width="30%">To Date</th>
                        <th width="15%">Total Tickets</th>
                        <th width="15%">Tickets Sold</th>
                        <th width="5%">&nbsp;</th>
                        <th width="5%">&nbsp;</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-datesdata>
                    <tr *ngIf="!datesdata.cancelled" [ngStyle]="{'background-color': (datesdata.cancelled==true) ? '#fee' : '#ffffff'}">
                        <td>{{datesdata.startdatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</td>
                        <td>{{datesdata.enddatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</td>
                        <td>{{datesdata.nooftickets}}</td>
                        <td>{{datesdata.noofticketssold}}</td>
                        <td>
                            <button *ngIf="!datesdata.cancelled" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:113px;height:50px" (click)="confirm(data, datesdata)">
                                <span class="pi pi-times" style="margin-right:8px;"></span>
                                <span i18n>Cancel</span>
                            </button>

                            <button *ngIf="datesdata.cancelled" pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" style="width:113px;height:50px" (click)="resendEmail(data, datesdata)">
                                <span class="pi pi-send" style="margin-right:8px;"></span>
                                <span i18n>Resend</span>
                            </button>
                        </td>
                        <td *ngIf="!datesdata.showEventDateBookings">
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:170px;height:50px;margin-left:-20px;" (click)="openBookings(data, datesdata)">
                                <span class="pi pi-chevron-down" style="margin-right:8px;"></span>
                                <span i18n>Show Bookings</span>
                            </button>
                        </td>
                        <td *ngIf="datesdata.showEventDateBookings">
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:170px;height:50px;margin-left:-20px;" (click)="openBookings(data, datesdata)">
                                <span class="pi pi-chevron-up" style="margin-right:8px;"></span>
                                <span i18n>Hide Bookings</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="datesdata.cancelled" style="background-color:#fee">
                        <td><s>{{datesdata.startdatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</s></td>
                        <td><s>{{datesdata.enddatetime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</s></td>
                        <td><s>{{datesdata.nooftickets}}</s></td>
                        <td><s>{{datesdata.noofticketssold}}</s></td>
                        <td>
                            <button *ngIf="!datesdata.cancelled" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" disabled="true" style="width:113px;height:50px">
                                <span class="pi pi-times" style="margin-right:8px;"></span>
                                <span i18n>Cancel</span>
                            </button>

                            <button *ngIf="datesdata.cancelled" pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="resendEmail(data, datesdata)" style="width:113px;height:50px">
                                <span class="pi pi-send" style="margin-right:8px;"></span>
                                <span i18n>Resend</span>
                            </button>
                        </td>
                        <td *ngIf="!datesdata.showEventDateBookings">
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:170px;height:50px;margin-left:-20px;" (click)="openBookings(data, datesdata)">
                                <span class="pi pi-chevron-down" style="margin-right:8px;"></span>
                                <span i18n>Show Bookings</span>
                            </button>
                        </td>
                        <td *ngIf="datesdata.showEventDateBookings">
                            <button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="width:170px;height:50px;margin-left:-20px;" (click)="openBookings(data, datesdata)">
                                <span class="pi pi-chevron-up" style="margin-right:8px;"></span>
                                <span i18n>Hide Bookings</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="datesdata.showEventDateBookings">
                        <td colspan="6">
                            <p-table  #resourceTable 
                            [value]="datesdata.eventDateBookings" [paginator]="true" [rows]="10" 
                            [showCurrentPageReport]="true" responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                            [rowsPerPageOptions]="[10,25,50]" 
                            [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
                            [(selection)]="selectedItems"
                            responsiveLayout="scroll"
                            datakey="resourceID"
                            [selectionPageOnly]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th width="1%">
                                            &nbsp;
                                        </th>
                                        <th width="1%">
                                            &nbsp;
                                        </th>
                                        <th pSortableColumn="bookingId" width="12%">Sales ID
                                            
                                        </th>
                                        <th pSortableColumn="name" width="10%">Name
                                        
                                        </th>
                                        <th pSortableColumn="guests" width="10%">Guests
                                            
                                        </th>
                                        <th pSortableColumn="total" width="8%">Total
                                        
                                        </th>
                                        <th pSortableColumn="outstanding" width="8%">O/S
                                            
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr  onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                                        <td (click)="editdatanew(data.bookingid)" >
                                            <span *ngIf="data.publicbooking" class="pi pi-user"></span> 
                                        </td>
                                        <td (click)="editdatanew(data.bookingid)">
                                            <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                                        </td>
                                        <td (click)="editdatanew(data.bookingid)">{{data.bookingidhr}}</td>
                                        <td (click)="editdatanew(data.bookingid)">{{data.name}}</td>
                                        <td (click)="editdatanew(data.bookingid)">{{data.guests}}</td>
                                        <td (click)="editdatanew(data.bookingid)">{{data.total | currency: CurrencySymbol}}</td>
                                        <td (click)="editdatanew(data.bookingid)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                                        <td (click)="editdatanew(data.bookingid)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </td>
          </tr>
      </ng-template>
  </p-table>
  </div>
</div>
