import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { event_returndata, event_returndataChartData } from 'src/types/event_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { cEvent, datasets, datasetsimple, eventObj } from 'src/types/event';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { readiness_returndata } from 'src/types/readiness_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { BaseComponent } from '@fullcalendar/core/internal';
import { LocalStorage } from '@ng-idle/core';
import { emailtemplate_returndata } from 'src/types/emailtemplate_returndata';
import { class_returndata } from 'src/types/class_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  ListEvents: any = [];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  Event: any;
  DisabledEvent: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  EventObj: cEvent;
  httpResponse: any;
  id: string;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  basicOptions: any;
  full: boolean = false;
  cancelled: boolean = false;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  showEmailCustomers: boolean = false;
  EmailText: string = "";
  EmailSubject: string = "";
  SelectedEvent: any;
  SelectedEventDate: any;

  ScreenLayout: any[] = [];

  subjectValid: boolean = true;
  textValid:  boolean = true;
  textValidDT:  boolean = true;

  screenMemoryEvents: any[] = [];
  screenMemoryBookingss: any[] = [];
  SelectedEmailTemplate: any;
  ListEmailTemplates: any[] = [];
  ExampleEmail: string = "";
  EmailContainsDynamicText: boolean = false;
  DynamicContentButton: string = "";

  ShowDynamicTextEditor: boolean = false;
  dynamicTextArray: any[] = [];
  MasterCopy: string= "";
  ListEventDateFilter: any[] = [];
  SelectedEventDateFilter: any = "ALL";

  DontShowNoEmailButton: boolean = false;
  ShowCancelButton: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService,private confirmationService: ConfirmationService, private globalService: GlobalService) {
      //this.isRowSelectable = this.isRowSelectable.bind(this)

      this.ListEventDateFilter = [
        {name: 'All future', value: "ALL"},
        {name: 'Last 3 months', value: 'LAST3MONTHS'},
        {name: 'Last 6 months', value: 'LAST6MONTHS'},
        {name: 'Last 12 months', value: 'LAST12MONTHS'},
        {name: 'All', value: 'ALL'},
      ];
    }

  changeEventDateFilter()
  { this.screenMemoryEvents = JSON.parse(localStorage.getItem("screenMemoryEvents")!);
    this.screenMemoryBookingss = JSON.parse(localStorage.getItem("screenMemoryBookingss")!);

    if(this.SelectedEventDateFilter == "ALL")
    {
      this.getdata(false, this.SelectedEventDateFilter);
    }
    else
    {
      this.getdata(true, this.SelectedEventDateFilter);
    }
  }

  getdata(showHistoric: boolean, filter: any)
  {
    this.http.get(bmxurl + "eventlist/" + this.VenueID + "/false/" + showHistoric).subscribe(response => {
      if((response as event_returndata).succeeded == true)
      {
        this.ListEvents = (response as event_returndataChartData).data;

        //Filter out the events that are not in the date range
        if(filter.value == "LAST3MONTHS")
        {
          this.ListEvents = this.ListEvents.filter((event) => {
            return new Date(event.eventdates[0].startdatetime) > new Date(new Date().setMonth(new Date().getMonth() - 3));
          });

        }
        else if(filter.value == "LAST6MONTHS")
        {
          this.ListEvents = this.ListEvents.filter((event) => {
            return new Date(event.eventdates[0].startdatetime) > new Date(new Date().setMonth(new Date().getMonth() - 6));
          });

        }
        else if(filter.value == "LAST12MONTHS")
        {
          this.ListEvents = this.ListEvents.filter((event) => {
            return new Date(event.eventdates[0].startdatetime) > new Date(new Date().setMonth(new Date().getMonth() - 12));
          });
        }
        else if(filter.value == "ALL")
        {
          this.getallfuturedata();
        }

        this.ListEvents.forEach(thisevent => {
          let newData: number[] = [];
          let newBackgroundColors: string[] = [];
          let newLabels: string[] = [];

          thisevent.showinnerbookings = false;

          if(this.screenMemoryEvents != null)
          {
            this.screenMemoryEvents.forEach(element => {
              if(element == thisevent.eventid)
              {
                thisevent.showinnerbookings = true;
              }
            });
          }

          thisevent.totalnumberoftickets = 0;
          thisevent.totalnumberofticketssold = 0;

          thisevent.eventdates.forEach(thisdate => {
            newData.push(thisdate.noofticketssold);
            newLabels.push(new Date(thisdate.startdatetime).toLocaleString('en-GB'));
            thisevent.totalnumberoftickets += thisdate.nooftickets;
            thisevent.totalnumberofticketssold += thisdate.noofticketssold;

            thisdate.showEventDateBookings = false;

            if(this.screenMemoryBookingss != null)
            {
              this.screenMemoryBookingss.forEach(element => {
                if(element == thisdate.id)
                {
                  thisdate.showEventDateBookings = true;

                  let epoc: number = (new Date(thisdate.startdatetime).getTime()/1000);
                  this.http.get(bmxurl + "eventbookings/" + this.VenueID + "/" + thisevent.eventid + "/" + epoc).subscribe(response => {
                    if((response as event_returndata).succeeded == true)
                    {
                      thisdate.eventDateBookings = (response as event_returndata).data;
                    }
                  });

                }
              });
            }
          });

          let newDataSet: datasetsimple = {
            label: "Tickets Sold",
            data: newData
          }

          thisevent.chartData = {
            labels: newLabels,
            datasets: [newDataSet]
          }
        });


        if ((response as event_returndata).messages.length > 0)
        {
          (response as event_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
    }, error => {
    });
  }


  getallfuturedata()
  {
    this.http.get(bmxurl + "eventlist/" + this.VenueID + "/false/" + false).subscribe(response => {
      if((response as event_returndata).succeeded == true)
      {
        this.ListEvents = (response as event_returndataChartData).data;

        this.ListEvents.forEach(thisevent => {
          let newData: number[] = [];
          let newBackgroundColors: string[] = [];
          let newLabels: string[] = [];

          thisevent.showinnerbookings = false;

          if(this.screenMemoryEvents != null)
          {
            this.screenMemoryEvents.forEach(element => {
              if(element == thisevent.eventid)
              {
                thisevent.showinnerbookings = true;
              }
            });
          }

          thisevent.totalnumberoftickets = 0;
          thisevent.totalnumberofticketssold = 0;

          thisevent.eventdates.forEach(thisdate => {
            newData.push(thisdate.noofticketssold);
            newLabels.push(new Date(thisdate.startdatetime).toLocaleString('en-GB'));
            thisevent.totalnumberoftickets += thisdate.nooftickets;
            thisevent.totalnumberofticketssold += thisdate.noofticketssold;

            thisdate.showEventDateBookings = false;

            if(this.screenMemoryBookingss != null)
            {
              this.screenMemoryBookingss.forEach(element => {
                if(element == thisdate.id)
                {
                  thisdate.showEventDateBookings = true;

                  let epoc: number = (new Date(thisdate.startdatetime).getTime()/1000);
                  this.http.get(bmxurl + "eventbookings/" + this.VenueID + "/" + thisevent.eventid + "/" + epoc).subscribe(response => {
                    if((response as event_returndata).succeeded == true)
                    {
                      thisdate.eventDateBookings = (response as event_returndata).data;
                    }
                  });

                }
              });
            }
          });

          let newDataSet: datasetsimple = {
            label: "Tickets Sold",
            data: newData
          }

          thisevent.chartData = {
            labels: newLabels,
            datasets: [newDataSet]
          }
        });


        if ((response as event_returndata).messages.length > 0)
        {
          (response as event_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
    }, error => {
    });
  }


  validateEmail()
  {
    let AllValid = true;

    if(this.ExampleEmail.length > 0)
    {
      this.textValid = true;
    }
    else
    {
      AllValid = false; 
      this.textValid = false;
    }

    if(this.ExampleEmail.indexOf("{{DYNAMICTEXT}}") < 0)
    {
      this.textValidDT = true;
    }
    else
    {
      AllValid = false; 
      this.textValidDT = false;
    }

    return AllValid;
  }

  confirm(inEvent, inIndividaulEvent) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to cancel this event?',
        accept: () => {
          this.showEmailCustomers = true;
          this.DontShowNoEmailButton = false;
          this.ShowCancelButton = false;

          this.SelectedEventDate = inIndividaulEvent;
          this.SelectedEvent = inEvent;
        }
    });
  }

  closeModal()
  {
    this.showEmailCustomers = false;
    this.EmailText = "";
    this.EmailSubject = "";
    this.ShowCancelButton = false;
  }

  resendEmail(inEvent, inIndividualEvent) 
  {
    this.showEmailCustomers = true;
    this.DontShowNoEmailButton = true;
    this.ShowCancelButton = true;
  }

  EmailCustomersAndCancelEvent(inEvent, inIndividualEvent) {
    this.textValid = true;
    this.textValidDT = true;



    if(this.validateEmail())
    {
      this.messageService.add({severity:'success', summary:'Cancelled', detail:'Event successfully cancelled'});
      inIndividualEvent.cancelled = true;
      inIndividualEvent.cancelledsubject = this.EmailSubject;
      inIndividualEvent.cancelledcontent  = this.EmailText;

      let eventdatetocancel: any = {
        venueid: this.VenueID,
        eventid: inEvent.eventid,
        eventdate: inIndividualEvent
      }

      this.http.post<eventObj>(bmxurl + "/event/canceldate", eventdatetocancel).subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded) {
          this.showEmailCustomers = false;
          inEvent = this.httpResponse.data;
          this.loadgrid(false);
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Error Saving Event', detail: this.httpResponse.messages[0] });
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error Saving Event', detail: error.messages });
      })
    }
  }

  subscribe()
  {
    this.router.navigateByUrl("/subscriptions");
  }
  
  CancelEvent(inEvent, inIndividaulEvent){
    this.textValid = true;
    this.textValidDT = true;

    //if(this.validateEmail())
    //{
      this.messageService.add({severity:'success', summary:'Cancelled', detail:'Event successfully cancelled'});
      //inEvent.cancelled = true;
      inIndividaulEvent.cancelled = true;

      let eventdatetocancel: any = {
        venueid: this.VenueID,
        eventid: inEvent.eventid,
        eventdate: inIndividaulEvent
      }

      this.http.post<eventObj>(bmxurl + "/event/canceldate", eventdatetocancel).subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded) {
          inEvent = this.httpResponse.data;
          this.showEmailCustomers = false;
          this.loadgrid(false);
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Error Saving Event', detail: this.httpResponse.messages[0] });
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Error Saving Event', detail: error.messages });
      })
    //}
  }

  openBookings(inEvent, inEventadHocDate){

    inEventadHocDate.showEventDateBookings = !inEventadHocDate.showEventDateBookings;

    let found = false;
    let index = 0;

    if(this.screenMemoryBookingss == null)
    {
      this.screenMemoryBookingss = [];
    }

    if(this.screenMemoryBookingss != null)
    {
      this.screenMemoryBookingss.forEach(element => {
        if(element == inEventadHocDate.id)
        {
          found = true;
          this.screenMemoryBookingss.splice(index, 1);
        }
        index++;
      });

      if(!found)
      {
        this.screenMemoryBookingss.push(inEventadHocDate.id);
      }
      
      localStorage.setItem("screenMemoryBookingss", JSON.stringify(this.screenMemoryBookingss));
    }

    if(inEventadHocDate.showEventDateBookings)
    {
      let epoc: number = (new Date(inEventadHocDate.startdatetime).getTime()/1000);
      this.http.get(bmxurl + "eventbookings/" + this.VenueID + "/" + inEvent.eventid + "/" + epoc).subscribe(response => {
        if((response as event_returndata).succeeded == true)
        {
          inEventadHocDate.eventDateBookings = (response as event_returndata).data;
        }
      });
    }
  }

  openEvent(inEvent) {
    inEvent.showinnerbookings = !inEvent.showinnerbookings;

    let found = false;
    let index = 0;

    if(this.screenMemoryEvents == null)
    {
      this.screenMemoryEvents = [];
    }

    if(this.screenMemoryEvents != null)
    {
      this.screenMemoryEvents.forEach(element => {
        if(element == inEvent.eventid)
        {
          found = true;
          this.screenMemoryEvents.splice(index, 1);
        }
        index++;
      });

      if(!found)
      {
        this.screenMemoryEvents.push(inEvent.eventid);
      }
      
      localStorage.setItem("screenMemoryEvents", JSON.stringify(this.screenMemoryEvents));
    }
  }

  AddDynamicText()
  {
    this.textValid = true;
    this.textValidDT = true;
    
    this.ExampleEmail = this.MasterCopy;
    this.ShowDynamicTextEditor = !this.ShowDynamicTextEditor;
  }

  changeTemplate()
  {
    this.textValid = true;
    this.textValidDT = true;

    this.http.get(bmxurl + "/emailtemplateexample/" + this.VenueID + "/" + this.SelectedEmailTemplate.emailtemplateid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ExampleEmail = (response as any).data; 
        this.MasterCopy = this.ExampleEmail;

        let i = this.getNumberOfDynamicElements(this.ExampleEmail, "{{DYNAMICTEXT}}");

        if(i > 0)
        {
          if(i == 1)
          {
            this.DynamicContentButton = "You have a dynamic text area, please click here to edit it.";
          }
          else
          {
            this.DynamicContentButton = "You have " + i + " dynamic text areas, please click here to edit them.";
          }
          
          this.EmailContainsDynamicText = true;

          let indexid = 1
          for(let x = 0; x < i; x++)
          { 
            this.dynamicTextArray.push({text: "", index: indexid});
            indexid++;
          }
        }
        else
        {
          this.DynamicContentButton = "";
          this.EmailContainsDynamicText = false;
        }

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates Example, error: ' + error.messages});
    });
  }

  CancelText()
  {
    this.ShowDynamicTextEditor = false;
  }

  SaveText(){
    this.ShowDynamicTextEditor = false;

    this.dynamicTextArray.forEach(element => {
      this.ExampleEmail = this.ExampleEmail.replace("{{DYNAMICTEXT}}", element.text);
    });

    this.EmailText = this.ExampleEmail;
  }

  getNumberOfDynamicElements(InString, InWord)
  {
    // let count = 0;
    // let index = 0;

    // while (index != -1) {
    //   index = InString.indexOf(InWord, index + 1);
    //   count++;
    // }

    // return count;

    let count = 0;
    let position = InString.indexOf(InWord);

    while (position !== -1) {
      count++;
      position = InString.indexOf(InWord, position + 1);
    }
    return count;
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
    let userid = localStorage.getItem("SmartUserID");

    this.basicOptions = {
      plugins: {
          legend: {
              display: false
          }
      },
      responsive: true,
      scales: {
          y: {
              ticks: {
                  display: false,
              },
              grid: {
                  display: false
              }
          },
          x: {
              ticks: {
                  display: false,
              },
              grid: {
                  display: false
              }
          }
      }
    };

    this.http.get(bmxurl + "/EmailTemplateList/" + this.VenueID + "/false").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListEmailTemplates = (response as emailtemplate_returndata).data;
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates, error: ' + error.messages});
    });

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EVENT", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  copydata(id)
  {
      this.http.get(bmxurl + "event/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = response;
        this.EventObj = {
          id: null,
          eventid: null,
          name: (this.httpResponse.data as cEvent).name,
          description: (this.httpResponse.data as cEvent).description,
          imageurl: (this.httpResponse.data as cEvent).imageurl,
          message: (this.httpResponse.data as cEvent).message,
          disabled: (this.httpResponse.data as cEvent).disabled,
          venueid: (this.httpResponse.data as cEvent).venueid,
          resources: (this.httpResponse.data as cEvent).resources,
          admissions: (this.httpResponse.data as cEvent).admissions,
          eventlength: (this.httpResponse.data as cEvent).eventlength,
          questionnaire: (this.httpResponse.data as cEvent).questionnaire,
          min: (this.httpResponse.data as cEvent).min,
          max: (this.httpResponse.data as cEvent).max,
          tags: (this.httpResponse.data as cEvent).tags,
          visibility: (this.httpResponse.data as cEvent).visibility,
          colour: (this.httpResponse.data as cEvent).colour,
          publicdepositpolicy: (this.httpResponse.data as cEvent).publicdepositpolicy,
          venuedepositpolicy: (this.httpResponse.data as cEvent).venuedepositpolicy,
          confirmation:  (this.httpResponse.data as cEvent).confirmation,
          warning:  (this.httpResponse.data as cEvent).warning,
          eventdates:  (this.httpResponse.data as cEvent).eventdates,
          eventdateexceptions:  (this.httpResponse.data as cEvent).eventdateexceptions,
          originatingid: id,
          nooftickets: (this.httpResponse.data as cEvent).nooftickets,
          noofticketssold: (this.httpResponse.data as cEvent).noofticketssold,
          eventtickettypes: (this.httpResponse.data as cEvent).eventtickettypes,
          discounttype: (this.httpResponse.data as cEvent).discounttype,
          discountamount: (this.httpResponse.data as cEvent).discountamount,
          discountcode: (this.httpResponse.data as cEvent).discountcode,
          discountstart: (this.httpResponse.data as cEvent).discountstart,
          discountend: (this.httpResponse.data as cEvent).discountend,
          earlybirddiscount: (this.httpResponse.data as cEvent).earlybirddiscount,
          discountrangetype: (this.httpResponse.data as cEvent).discountrangetype,
          discountdaysbefore: (this.httpResponse.data as cEvent).discountdaysbefore,
          imageguid: (this.httpResponse.data as cEvent).imageguid,
          bookingscannotbemadewithinxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadewithinxminutes,
          bookingscannotbemadeafterxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadeafterxminutes,
          cannotmakebookingstoday: (this.httpResponse.data as cEvent).cannotmakebookingstoday,
        }

        //Got Event, now resave it.
        this.http.post<eventObj>(bmxurl + "/event", this.EventObj).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Event successfully copied' });
            this.editData(this.httpResponse.data.eventid)
          }
          else
          {
            this.messageService.add({ severity: 'error', summary: 'Error Copying Event', detail: this.httpResponse.messages[0] });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Error Copying Event', detail: error.messages });
        })


      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error Copying Event'});
      });
      
  }

  addNewEvent(){

    let readiness;
    let allowEventAdd = false;

    this.http.get(bmxurl + "readiness/" + this.VenueID).subscribe(response => {
      if((response as readiness_returndata).succeeded == true)
      {
        readiness = (response as readiness_returndata).data;
        if(readiness.admissions == true && readiness.confirmations == true && readiness.deposits == true && readiness.resources == true && readiness.venuestarttimes == true)
        {
          this.router.navigateByUrl("/eventedit/0");
        }
        else{
          if(readiness.admissions == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create admissions before you can create a event'});
          if(readiness.confirmations == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create confirmations before you can create a event'});
          if(readiness.deposits == false)   
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create deposits before you can create a event'});
          if(readiness.resources == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create resources before you can create a event'});
          if(readiness.venuestarttimes == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create venuestarttimes before you can create a event'});
        }
      }
    }, error => {
    });
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(EventID)
  {
    if (confirm("Are you sure you want to disable this event?"))
    {
      this.http.delete(bmxurl + "event/" + this.VenueID + "/" + EventID).subscribe(response => {
        this.CreateUpdateResponse = response
        if(this.CreateUpdateResponse.succeeded)
        {
          this.messageService.add({severity:'success', summary:'Disabled', detail:'Event successfully disabled'});
          this.loadgrid(false);
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Event'});
      })
    }
  }

  loadgrid(showdisabled: boolean)
  {
    this.screenMemoryEvents = JSON.parse(localStorage.getItem("screenMemoryEvents")!);
    this.screenMemoryBookingss = JSON.parse(localStorage.getItem("screenMemoryBookingss")!);

    this.http.get(bmxurl + "eventlist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      if((response as event_returndata).succeeded == true)
      {
        this.ListEvents = (response as event_returndataChartData).data;

        this.ListEvents.forEach(thisevent => {
          let newData: number[] = [];
          let newBackgroundColors: string[] = [];
          let newLabels: string[] = [];

          thisevent.showinnerbookings = false;

          if(this.screenMemoryEvents != null)
          {
            this.screenMemoryEvents.forEach(element => {
              if(element == thisevent.eventid)
              {
                thisevent.showinnerbookings = true;
              }
            });
          }

          thisevent.totalnumberoftickets = 0;
          thisevent.totalnumberofticketssold = 0;

          thisevent.eventdates.forEach(thisdate => {
            newData.push(thisdate.noofticketssold);
            newLabels.push("Tickets Sold: " + new Date(thisdate.startdatetime).toLocaleString('en-GB'));
            thisevent.totalnumberoftickets += thisdate.nooftickets;
            thisevent.totalnumberofticketssold += thisdate.noofticketssold;

            thisdate.showEventDateBookings = false;

            if(this.screenMemoryBookingss != null)
            {
              this.screenMemoryBookingss.forEach(element => {
                if(element == thisdate.id)
                {
                  thisdate.showEventDateBookings = true;

                  let epoc: number = (new Date(thisdate.startdatetime).getTime()/1000);
                  this.http.get(bmxurl + "eventbookings/" + this.VenueID + "/" + thisevent.eventid + "/" + epoc).subscribe(response => {
                    if((response as event_returndata).succeeded == true)
                    {
                      thisdate.eventDateBookings = (response as event_returndata).data;
                    }
                  });

                }
              });
            }
          });

          newData.push(thisevent.totalnumberoftickets - thisevent.totalnumberofticketssold);
          newLabels.push("Tickets Not Sold:");

          let newDataSet: datasetsimple = {
            label: "",
            data: newData
          }

          thisevent.chartData = {
            labels: newLabels,
            datasets: [newDataSet]
          }
        });


        if ((response as event_returndata).messages.length > 0)
        {
          (response as event_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
    }, error => {
    });
  }

  editData(EditData)
  {
    let EventID = EditData;
    this.router.navigateByUrl("/eventedit/" + EventID);
  }

  disableMulti()
  {
    if (confirm("Are you sure you want to disable these events?"))
      {
        this.selectedItems.forEach(element => {

        this.http.delete(bmxurl + "Event/" + this.VenueID + "/" + element.EventID).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded)
          {
            this.loadgrid(false);
          }
        }, error => {

        })
        });
      }

  }

  editdatanew(ID){
    localStorage.setItem("FromEvents", "TRUE");
    this.router.navigateByUrl("/editbooking/" + ID);
  }
  
  }
