<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Confirmation
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round" style="width: 50%; float:left;">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Confirmation Name</label>
                    <input [(ngModel)]="Confirmation.name" id="classname" type="text" [ngStyle]="{'border-color': (confirmationNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!confirmationNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Confirmation name.</div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Email Subject</label>
                    <input [(ngModel)]="Confirmation.subject" id="classname" type="text" [ngStyle]="{'border-color': (subjectValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!subjectValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Subject.</div>


                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show Confirmation message</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showconfirmationmessage" ></p-selectButton>
                </div>

                <div *ngIf="Confirmation.showconfirmationmessage" class="field mb-4 col-12">
                    <p-editor [(ngModel)]="Confirmation.confirmationmessage" [style]="{'height':'320px'}">
                        <ng-template pTemplate="header">
                            <span class="ql-formats">
                                <p-header>
                                    <span class="ql-formats">
                                      <select class="ql-size">
                                        <option value="small">Small</option>
                                        <option selected value="normal">Normal</option>
                                        <option value="large">Sub Heading</option>
                                        <option value="huge">Heading</option>
                                      </select>

                                      <select class="ql-font">
                                        <option selected value="san serif">San-Serif</option>
                                        <option value="monospace">Monos</option>
                                        <option value="serif">Serif</option>
                                      </select>
                                    </span>
                                    <span class="ql-formats">
                                      <button class="ql-bold" aria-label="Bold"></button>
                                      <button class="ql-italic" aria-label="Italic"></button>
                                      <button class="ql-underline" aria-label="Underline"></button>
                                      <button class="ql-strike" aria-label="Strike"></button>
                                      <button class="ql-script" value="sub" title="Subscript"></button>
                                      <button class="ql-script" value="super" title="Superscript"></button>
                                      <button class="ql-indent" value="-1" title="Indent"></button>
                                      <button class="ql-indent" value="+1" title="Outdent"></button>
                                    </span>
                                    <span class="ql-formats">
                                      <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                                      </select>
                                      <span class="ql-format-separator"></span>
                                      <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                                      </select>
                                  </span>
                                    <span class="ql-formats">
                                      <button class="ql-list" title="Ordered" value="ordered"></button>
                                      <button class="ql-list" title="Bullet" value="bullet"></button>

                                      <select title="Text Alignment" class="ql-align" >
                                        <option label="Left"></option>
                                        <option value="center" label="Center"></option>
                                        <option value="right" label="Right"></option>
                                        <option value="justify" label="Justify"></option>
                                      </select>            
                                    </span> 
                                    <span class="ql-formats">
                                      <button aria-label="Link" class="ql-link"></button>
                                    </span>
                                  </p-header>
                            </span>
                        </ng-template>
                    </p-editor>
                    <!--<textarea pInputText type="text" [(ngModel)]="Confirmation.confirmationmessage" style="width:100%; height: 100px;" [ngStyle]="{'border-color': (confirmationMessageValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()"></textarea>-->
                </div>
                <div *ngIf="!confirmationMessageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Confirmation message.</div>

                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Payment message</label>
                    <p-selectButton  [options]="PaymentMessage" [(ngModel)]="Confirmation.paymentmessagetype" (onChange)="DoValidCheck()"></p-selectButton>
                </div>

                
                
                <div *ngIf="Confirmation.paymentmessagetype != 'NONE'" class="field mb-4 col-12">
                    <textarea pInputText type="text" [(ngModel)]="Confirmation.paymentmessage" style="width:100%; height: 100px;" [ngStyle]="{'border-color': (paymentmessageValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()"></textarea>
                </div>
                <div *ngIf="!paymentmessageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Payment message.</div>


                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">How to find us</label>
                    <p-selectButton [options]="HowToFindUs" [(ngModel)]="Confirmation.howtofindustype" (onChange)="DoValidCheck()" ></p-selectButton>
                </div>

                <div *ngIf="Confirmation.howtofindustype != 'NONE'" class="field mb-4 col-12">  
                    <p-editor [(ngModel)]="Confirmation.howtofindustext" [style]="{'height':'320px'}">
                        <ng-template pTemplate="header">
                            <span class="ql-formats">
                                <p-header>
                                    <span class="ql-formats">
                                      <select class="ql-size">
                                        <option value="small">Small</option>
                                        <option selected value="normal">Normal</option>
                                        <option value="large">Sub Heading</option>
                                        <option value="huge">Heading</option>
                                      </select>

                                      <select class="ql-font">
                                        <option selected value="san serif">San-Serif</option>
                                        <option value="monospace">Monospace</option>
                                        <option value="serif">Serif</option>
                                      </select>
                                    </span>
                                    <span class="ql-formats">
                                      <button class="ql-bold" aria-label="Bold"></button>
                                      <button class="ql-italic" aria-label="Italic"></button>
                                      <button class="ql-underline" aria-label="Underline"></button>
                                      <button class="ql-strike" aria-label="Strike"></button>
                                      <button class="ql-script" value="sub" title="Subscript"></button>
                                      <button class="ql-script" value="super" title="Superscript"></button>
                                      <button class="ql-indent" value="-1" title="Indent"></button>
                                      <button class="ql-indent" value="+1" title="Outdent"></button>
                                    </span>
                                    <span class="ql-formats">
                                      <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                                      </select>
                                      <span class="ql-format-separator"></span>
                                      <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                                      </select>
                                  </span>
                                    <span class="ql-formats">
                                      <button class="ql-list" title="Ordered" value="ordered"></button>
                                      <button class="ql-list" title="Bullet" value="bullet"></button>

                                      <select title="Text Alignment" class="ql-align" >
                                        <option label="Left"></option>
                                        <option value="center" label="Center"></option>
                                        <option value="right" label="Right"></option>
                                        <option value="justify" label="Justify"></option>
                                      </select>            
                                    </span> 
                                    <span class="ql-formats">
                                      <button aria-label="Link" class="ql-link"></button>
                                    </span>
                                  </p-header>
                            </span>
                        </ng-template>
                    </p-editor>
                </div>
                <div *ngIf="!howtofindustextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a How to find us message.</div>

                <div *ngIf="Confirmation.howtofindustype == 'TEXTONLYLINK'" class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Web address for map</label>
                    <input [(ngModel)]="Confirmation.webaddressformap" id="classname" type="text" [ngStyle]="{'border-color': (webaddressformapValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!webaddressformapValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Web address for the map.</div>
                
                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show Itinerary</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showitinerary" (onChange)="DoValidCheck()"></p-selectButton>
                </div>
                
                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show End Time</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showendtime" (onChange)="DoValidCheck()"></p-selectButton>
                </div>
                
                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Show customer services</label>
                    <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showcustomerservices" (onChange)="DoValidCheck()"></p-selectButton>
                </div>

                <div *ngIf="Confirmation.showcustomerservices" class="surface-card p-4 shadow-2 border-round" style="width:100%">
                    <div class="field mb-5 col-12">
                        <label for="venueclosingstartdate">Show email</label>
                        <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showemail"  (onChange)="DoValidCheck()"></p-selectButton>
                    </div>

                    <div *ngIf="Confirmation.showemail" class="field mb-5 col-12">
                        <input [(ngModel)]="Confirmation.emailtext" id="classname" type="text" [ngStyle]="{'border-color': (emailtextValid==false) ? '#FF0000':''}" pInputText (keyup)="DoValidCheck()">
                    </div>
                    <div *ngIf="!emailtextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>

                    <div class="field mb-5 col-12">
                        <label for="venueclosingstartdate">Show phone number</label>
                        <p-selectButton [style]="{'width': '450px'}" [options]="YesNo" [(ngModel)]="Confirmation.showphone"  (onChange)="DoValidCheck()"></p-selectButton>
                    </div>

                    <div *ngIf="Confirmation.showphone" class="field mb-5 col-12">
                        <input [(ngModel)]="Confirmation.phonetext" id="classname" type="text" [ngStyle]="{'border-color': (phonetextValid==false) ? '#FF0000':''}" pInputText (keyup)="DoValidCheck()">
                    </div>
                    <div *ngIf="!phonetextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Phone number.</div>

                </div>
                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>

        <div class="surface-card p-4 shadow-2 border-round" style="width: 49%; float:left; margin-left:1%;font-size: 17px;">
            <span style="font-weight:bold;">Preview</span>

            <div style="font-size: 25px;text-align: center;">
                {{VenueName}}
            </div>

            <div style="text-align: center;">
                <img width="{{PublicImageWidth}}" height="{{PublicImageHeight}}" src="{{PublicImageURL}}" />
            </div>

            <div style="text-align: center;">
                <img width="250px;" src="assets/images/QRCode.png" />
            </div>
            <div>
                <table width="95%" style="margin: auto; border: 1px solid #EEEEEE; border-collapse: collapse; padding: 5px;">
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:bold">ID</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:bold">TST-12345-WANNA</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Name</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Smith</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Booking Date</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">13/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Time</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">10:00</span>
                        </td>
                    </tr>
                    <tr *ngIf="Confirmation.showendtime">
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">End Time</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">12:00</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Package</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">General Admission</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Guests</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">3 Children<br>2 Adults</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Extras</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">3 Pizzas - Extra Cheese<br>2 Hotdogs</span>
                        </td>
                    </tr>

                    <tr *ngIf="Confirmation.showitinerary">
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Itinerary</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Play Area: 09:00 - 5 People</span><br />
                            <span style="font-weight:normal">Table 1 (6): 11:00 - 5 People</span><br />
                            <span style="font-weight:normal">End: 12:00</span>
                        </td>
                    </tr> 

                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Total Cost</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">{{37.54 | currency:CurrencySymbol}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="30%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">Outstanding Balance</span>
                        </td>
                        <td width="70%" style="border: 1px solid #DDDDDD; border-collapse: collapse; padding: 5px;">
                            <span style="font-weight:normal">{{22.54 | currency:CurrencySymbol}}</span>
                        </td>
                    </tr>
                </table>
            </div>
            <hr />
            <div *ngIf="Confirmation.showconfirmationmessage" style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">Confirmation message</div>
                <div [innerHTML]="Confirmation.confirmationmessage"></div>
            </div>
            <div *ngIf="Confirmation.paymentmessagetype != 'NONE'" style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">Payment message</div>
                <div [innerHTML]="Confirmation.paymentmessage"></div>
                <div *ngIf="Confirmation.paymentmessagetype == 'TEXTONLYOB'" style="text-align:right;">
                    <button pButton pRipple type="button" class="p-button p-element p-button-info w-auto mt-3">
                    <span class="pi pi-pound" style="margin-right:8px;"></span>
                    Pay £22.54</button>
                </div>
            </div>
            <div *ngIf="Confirmation.howtofindustype != 'NONE'"  style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">How to find us</div>
                <div [innerHTML]="Confirmation.howtofindustext"></div>
                <div *ngIf="Confirmation.howtofindustype == 'TEXTONLYLINK'"><a href="#">Click here to find us</a></div>
            </div>
            <div *ngIf="Confirmation.showcustomerservices"  style="margin:20px;">
                <div style="font-weight: bold;margin-bottom: 10px;">Customer Services</div>

                <div *ngIf="Confirmation.showemail" style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Email</div>
                    {{Confirmation.emailtext}}
                </div>

                <div *ngIf="Confirmation.showphone" style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Phone</div>
                    {{Confirmation.phonetext}}
                </div>

                <div  style="margin:20px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">Terms & Conditions</div>
                    <a href="#">Click here to view Terms and Conditions</a>
                </div>
                
            </div>

        </div>
    </div>
</div>