import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl } from 'src/globals';
import { getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../_services/busy.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';
import { emailtemplate_returndata } from 'src/types/emailtemplate_returndata';
import { activesubscriptions_returndata } from 'src/types/activesubscriptions_returndata';


@Component({
  selector: 'app-bookingblock',
  templateUrl: './bookingblock.component.html',
  styleUrls: ['./bookingblock.component.css']
})

export class BookingblockComponent implements OnInit {
  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  BookingDate: Date;
  PreviousBookingDate: Date;
  hubConnection: HubConnection;
  httpResponse: any;
  navSubscription: Subscription = null;
  bookingsInProgress: number = 0;
  totalBookingsToday: number = 0;
  inProgress: boolean = false;
  startdate: string = "";
  displayDate: string = "";

  emailTemplateDate: string = "";
  emailTemplateTime: string = "";

  bcItems: MenuItem[];
  home: MenuItem;
  PreviousHubs: string[] = [];
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  useCounters: boolean = false;

  EmailTemplates: any[] = [];
  selectedEmailTemplate: any;

  templateValid: boolean = true;

  PeopleAdmitted: boolean = false;

  cols: any[];

  showSubscriptionlWarning: boolean = false;

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("HOME", user)){
          this.AccessAllowed = true;
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
    }

  showInProgress(){
    this.inProgress = true;
    this.loadgrid(false);
  }

  unshowInProgress(){ 
    this.inProgress = false;
    this.loadgrid(false);
  }

  viewcounters(bookingId){

    let dStartDate = new Date(this.startdate);
    let BookingDateYear = dStartDate.getFullYear();
    let BookingDateMonth = ('00' + (dStartDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + dStartDate.getDate()).slice(-2);
    
    let NewDate: string = BookingDateDay + BookingDateMonth + BookingDateYear;

    this.router.navigateByUrl("/counterslist/" + NewDate + "/" + bookingId);
  }

  DatifyString(date: string): Date
  {
    let returnDate = new Date();

    returnDate.setUTCFullYear(Number(date.substring(0, 4)));
    returnDate.setUTCMonth(Number(date.substring(5, 7)) - 1);
    returnDate.setUTCDate(Number(date.substring(8, 10)));
    returnDate.setUTCHours(0);
    returnDate.setUTCMinutes(0);
    returnDate.setUTCSeconds(0);

    return returnDate;
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
    this.useCounters = localStorage.getItem("UseCounters") == "true" ? true : false;
    this.startdate = this.route.snapshot.paramMap.get('startdate');

    let NewDate = new Date(this.startdate);
    
    this.displayDate = ('00' + (NewDate.getDate())).slice(-2) + "/" + ('00' + (NewDate.getMonth() + 1)).slice(-2) + "/" + NewDate.getFullYear() + " " + ('00' + NewDate.getHours()).slice(-2) + ":" + ('00' + NewDate.getMinutes()).slice(-2);

    this.emailTemplateDate = ('00' + (NewDate.getDate())).slice(-2) + ('00' + (NewDate.getMonth() + 1)).slice(-2) + NewDate.getFullYear().toString().substr(-2);//NewDate.getFullYear();
    this.emailTemplateTime = ('00' + NewDate.getHours()).slice(-2) + ('00' + NewDate.getMinutes()).slice(-2);

    this.http.get(bmxurl + "/EmailTemplateList/" + this.VenueID + "/false").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.EmailTemplates = (response as emailtemplate_returndata).data;
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates, error: ' + error.messages});
    });

    this.BookingDate = new Date();
    this.loadgrid(false);

    this.cols = [
      { field: 'bookingIdHR', header: 'Sales ID' },
      { field: 'startTime', header: 'Start Time</th>' },
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email' },
      { field: 'phone', header: 'Phone' },
      { field: 'packageName', header: 'Package' },
      { field: 'guests', header: 'Guests' },
      { field: 'total', header: 'Total' },
      { field: 'outstanding', header: 'Outstanding' },
      { field: 'paymentlinksentdate', header: 'Payment Link Sent' },
      { field: 'extraslist', header: 'Extras' },
      { field: 'allergy', header: 'Allergy' },
      ];

  }

  sendEmailToAllBookings()
  {
    //check to see if you have an active subscription 
    this.http.get<activesubscriptions_returndata>(bmxurl + "activesubscriptions/" + this.VenueID).subscribe(Response => {
      let activeSubscriptions = Response.data;

      let hasEmailSubscription = false;
      if(activeSubscriptions != null && activeSubscriptions.length != 0)
      {
          activeSubscriptions.forEach(element => {
          {
            if(element.type == "EMAIL" && element.disabled == false)
            {
              hasEmailSubscription = true;
            }
          }
        });
      }

    if(hasEmailSubscription)
    {
    this.templateValid = true

    if(this.selectedEmailTemplate == null)
      {
        this.templateValid = false
      }
      else
      {
        this.templateValid = true

        if (confirm("Are you sure you want to send this email to all bookings?"))
        {
          this.http.get(bmxurl + "sendtemplate/" + this.VenueID + "/" + this.selectedEmailTemplate.emailtemplateid + "/" + this.emailTemplateDate + "/" + this.emailTemplateTime).subscribe(response => {
            this.CreateUpdateResponse = response
            if(this.CreateUpdateResponse.succeeded = true)
            {
              this.messageService.add({severity:'success', summary:'Email Sent', detail:'Email successfully sent to all bookings'});
              this.selectedEmailTemplate = null
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Sent', detail: 'An error occurred sending email to all bookings'});
          })
        }
      }
    }
    else
    {
      //show popup to ask to subcribe
      this.showSubscriptionlWarning = true;
    }
    },
      error => {
      this.messageService.add({ severity: 'error', summary: 'Unable to get Subscriptions' });
    });

  }

  returnToList(){
    this.router.navigateByUrl("/ataglance/" + this.startdate);
  }

  admitall(Id)
  {
    this.http.get(bmxurl + "BookingCheckin/" + this.VenueID + "/" + Id).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Admitted', detail:'All customers admitted for this booking.'});
        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Admitted', detail: 'An error occurred admitting all customers for this booking'});
    })
  }

  leaveall(Id)
  {
    this.http.get(bmxurl + "BookingCheckout/" + this.VenueID + "/" + Id).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Admitted', detail:'All customers admitted for this booking.'});
        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Admitted', detail: 'An error occurred admitting all customers for this booking'});
    })
  }

  PeopleAreAdmitted(inBooking)
  {
    let foundAdmitted = false
    inBooking.counteradmissions.forEach(element => {
      if(element.admitted == true)
      {
        foundAdmitted = true
      }
    });

    this.PeopleAdmitted =  foundAdmitted;
  }

  editdata(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

  editdatanew(ID){
    this.router.navigateByUrl("/editbooking/" + ID + "/" + this.startdate);
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(BookingID)
  {
    if (confirm("Are you sure you want to disable this booking?"))
     {
        this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + BookingID).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded = true)
          {
            this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking successfully disabled'});
            this.loadgrid(false);
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking'});
        })
      }
  }

  emailConf(BookingID)
  {
    this.http.get<any>(bmxurl + "confirmationemail/" + this.VenueID + "/" + BookingID).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Sent Booking Confirmation.'})
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not send Booking Confirmation: ' + response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send Booking Confirmation'});
    })
  }

  loadgrid(showdisabled: boolean)
  {
    let newDate = new Date(this.startdate);

    let BookingDateYear = newDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (newDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + newDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    let timeChosen = ('00' + newDate.getHours()).slice(-2) + ('00' + newDate.getMinutes()).slice(-2);

    //this.busyService.busy();
    this.http.get(bmxurl + "BookingListTimeDateMin/" + this.VenueID + "/" + dateChosen + "/" + timeChosen + "/" + showdisabled).subscribe(response => {
      try{
        this.CreateUpdateResponse = (response as any);

        //Get VenueDate
        this.ListBookingsGlobal = this.CreateUpdateResponse.data;

        var thisYear: any = newDate.getFullYear();
        var thisMonth: any = ('00' + (newDate.getMonth() + 1)).slice(-2);
        var thisDay: any = ('00' + newDate.getDate()).slice(-2);
        var fullDate: any = thisYear + thisMonth + thisDay;

        let venueDate: string =  this.VenueID + "_" + fullDate;

        if(this.inProgress)
        {
          this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.venuedate == venueDate && obj.inProgress == true);

          this.ListBookings.forEach(element => {
          
          let extraslist: string = "";

          if(element.extras != null)
            {
              if(element.extras.length > 0)
              { 
                element.extras.forEach(extra => {
                  if(extra.qty > 0)
                    extraslist += extra.name + " (" + extra.qty + "), ";
                });
              }

              if(extraslist.length > 2)
                element.extraslist = extraslist.substring(0, extraslist.length - 2);
            }
          });
        }
        else
        {
          this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.venuedate == venueDate && obj.inProgress == false);
          
          this.ListBookings.forEach(element => {
          let extraslist: string = "";
          if(element.extras != null)
            {
              if(element.extras.length > 0)
              { 
                element.extras.forEach(extra => {
                  if(extra.qty > 0)
                    extraslist += extra.name + " (" + extra.qty + "), ";
                });
              }

              if(extraslist.length > 2)
                element.extraslist = extraslist.substring(0, extraslist.length - 2);
            }
          });
        }

        this.bookingsInProgress = this.ListBookingsGlobal.filter(obj => obj.inProgress == true).length;
        this.totalBookingsToday = this.ListBookingsGlobal.filter(obj => obj.inProgress == false).length;

        this.ListBookings.forEach(element => {
          if(element.admitted != null)
            element.admitted = this.PeopleAreAdmitted(element);
        });
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  disableMulti()
  
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + element.bookingId).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.loadgrid(false);
      }
    }, error => {

    })
    });

  }

  doneDate(){

    this.inProgress = false;

    this.loadgrid(false)

    this.PreviousHubs.forEach(element => {
      this.hubConnection.off(element);
    });

    this.PreviousHubs = [];

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.PreviousHubs.push(BookingEventDate);

    this.hubConnection.on(BookingEventDate, data => {
      console.log(data);
      this.loadgrid(false);
    });

  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.BookingDate = this.addDays(new Date(this.BookingDate), num);
    this.doneDate();
  }

  subscribe()
  {
    this.router.navigateByUrl("/subscriptions");
  }
  }

