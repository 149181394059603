<div *ngIf="UseCounters && ShowCounterStrip" class="counterwidth">
    <div style="width:880px;margin: auto;">
      <div style="height:82px;background-color: #22c55e;border-radius: 5px;text-align: center;float: left;margin-right: 20px;width: 160px;padding-top: 5px;padding-bottom: 5px;">
          <div style="font-size: 20px;color: white;margin-bottom: 8px;margin-top: 3px;">Participants</div>
          <div style="font-size:42px;color:white;">{{Counters.participants}}</div>
      </div>
      <div style="height:82px;background-color: #f59e0b;border-radius: 5px;text-align: center;float: left;margin-right: 20px;width: 160px;padding-top: 5px;padding-bottom: 5px;">
        <div style="font-size: 20px;color: white;margin-bottom: 8px;margin-top: 3px;">Non-partipants</div>
        <div style="font-size:42px;color:white;">{{Counters.nonparticipants}}</div>
      </div> 
      <div style="height:82px;background-color: #64748b;border-radius: 5px;text-align: center;float: left;margin-right: 20px;width: 160px;padding-top: 5px;padding-bottom: 5px;">
        <div style="font-size: 20px;color: white;margin-bottom: 8px;margin-top: 3px;">Time Remaining</div>
        <div style="font-size:42px;color:white;">{{Counters.timeremaining}}</div>
      </div>
      <div style="height:82px;background-color: #ef4444;border-radius: 5px;text-align: center;float: left;margin-right: 20px;width: 160px;padding-top: 5px;padding-bottom: 5px;">
        <div style="font-size: 20px;color: white;margin-bottom: 8px;margin-top: 3px;">Time Used Up</div>
        <div style="font-size:42px;color:white;">{{Counters.timeusedup}}</div>
      </div>
      <div style="height:82px;background-color: #3b82f6;border-radius: 5px;text-align: center;float: left;width: 160px;padding-top: 5px;padding-bottom: 5px;">
        <div style="font-size: 20px;color: white;margin-bottom: 8px;margin-top: 3px;">Total</div>
        <div style="font-size:42px;color:white;">{{Counters.total}}</div>
      </div>
    </div>
  </div>

<div *ngIf="bookingnumbers" style="padding: 1rem">

<h3 style="margin-bottom: -38px" i18n>Bookings for</h3>
<div style="width: 100%; float: left; margin-left: 180px; margin-bottom:20px;">
    <button pButton type="button"  pTooltip="Previous day"  style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
        <span class="pi pi-angle-left" style="margin-right:8px;"></span>
    </button>
    <div style="float:left; width: 200px;">
        <p-calendar  [selectOtherMonths]="true" [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()" (onChange)="doneDate()"></p-calendar>  
    </div> 
    <button pButton type="button" pTooltip="Next day" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
        <span class="pi pi-angle-right" style="margin-right:8px;"></span>
    </button>
    &nbsp;
    <p-toggleButton pTooltip="Show started sessions" *ngIf="!inProgress" class="p-button-sm p-button-danger p-component"  onLabel="Hide Started Sessions" offLabel="Show Started Sessions" [(ngModel)]="showDisabled" offIcon="pi pi-stop" onIcon="pi pi-play" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleStarted()">
    </p-toggleButton>

    <!-- <div style="float:right; width: 300px;text-align: right;">
        <button pTooltip="Bookings in progress" pButton type="button" style="height: 50px; margin-right:10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component">
            <span class="pi pi-clock" style="margin-right:8px; font-size: 16px;"></span>
            <span style="font-size:17px;">{{bookingsInProgress}}</span>
        </button>
        
        <button pTooltip="Bookings made today" pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component">
            <span class="pi pi-check-circle" style="margin-right:8px; font-size: 16px;"></span>
            <span style="font-size:17px;">{{totalBookingsToday}}</span>
        </button>
    </div> -->
</div>

<div *ngIf="bookingnumbers == null || bookingnumbers.length == 0" style="width:100%">
    <p style="text-align:center; font-size:24px;">There are currently no bookings for this date</p>
</div>
<div  *ngIf="bookingnumbers != null && bookingnumbers.length > 0" style="width:100%">
    <p-card *ngFor="let bk of bookingnumbersfiltered" [style]="{'width': '373px', 'display': 'block', 'float': 'left', 'margin-right': '20px', 'margin-bottom': '20px', 'border': 'solid #dedede 2px', 'border-radius': '10px'}">
        <ng-template pTemplate="header">
            <div style="width: 100%;text-align: center;font-size: 75px;padding-top: 35px;margin-bottom: -43px; font-weight: lighter;">{{bk.time}}</div>
        </ng-template>
        <hr>
        <div style="height:200px !important; padding: -.25rem !important">
            <div style="width:50%; border-right:1px solid black;float:left;margin-bottom:20px;">
                <div style="text-align: center;width:100%;font-size: 20px;">Bookings</div>
                <div style="text-align: center;width:100%;font-size: 40px;">{{bk.bookings}}</div>
            </div>
            <div style="width:50%;;float:left;;margin-bottom:20px;">
                <div style="text-align: center;width:100%;font-size: 20px;">People</div>
                <div style="text-align: center;width:100%;font-size: 40px;">{{bk.admissions}}</div>
            </div>
            <div style="float:left; width:50%;padding-right: 4px;padding-bottom:4px;">
                <button pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="bookingblock(bk.time, bk.id, bk.started)">
                    <span class="pi pi pi-eye" style="margin-right:8px;"></span>
                    <span>View Bookings</span>
                </button>
            </div>
            <div *ngIf="UseCounters && (bk.checkedInGuests == 0 && bk.checkedIn == 0)" style="float:left; width:50%;padding-left: 4px;padding-bottom:4px;">
                <button pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="admitAll(bk.time, bk.id, bk.started)">
                    <span class="pi pi pi-sign-in" style="margin-right:8px;"></span>
                    <span>Admit All</span>
                </button>
            </div>

            <div *ngIf="UseCounters && (bk.checkedInGuest > 0 || bk.checkedIn > 0)" style="float:left; width:50%;padding-left: 4px;padding-bottom:4px;">
                <button pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-danger p-element p-ripple p-button p-component" (click)="leaveAll(bk.time, bk.id, bk.started)">
                    <span class="pi pi pi-sign-in" style="margin-right:8px;"></span>
                    <span>Leave All</span>
                </button>
            </div>

            <div *ngIf="!UseCounters" style="float:left; width:50%;padding-left: 4px;padding-bottom:4px;">
                <button pButton type="button" style="height: 50px; width:100%;" disabled="true" class="p-button-sm p-button-success p-element p-ripple p-button p-component">
                    <span class="pi pi pi-sign-in" style="margin-right:8px;"></span>
                    <span>Admit All</span>
                </button>
            </div>
            <div style="clear:both;"></div>
            <div style="float:left; width:50%;padding-right: 4px;padding-top:4px;">
                <button pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-info p-element p-ripple p-button p-component" (click)="bookSession(bk.time)">
                    <span class="pi pi pi-clock" style="margin-right:8px;"></span>
                    <span>Book this time</span>
                </button>
            </div>
            <div style="float:left; width:50%;padding-left: 4px;padding-top:4px;">
                <div *ngIf="bk.started != null">
                    <button *ngIf="!bk.started" pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-warning p-element p-ripple p-button p-component" (click)="startSession(bk.time, bk.id, bk.started)">
                        <span class="pi pi pi-play" style="margin-right:8px;"></span>
                        <span>Start Session</span>
                    </button>

                    <button *ngIf="bk.started" pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-danger p-element p-ripple p-button p-component" (click)="startSession(bk.time, bk.id, bk.started)">
                        <span class="pi pi pi-stop" style="margin-right:8px;"></span>
                        <span>Stop Session</span>
                    </button>
                </div>
                <div *ngIf="bk.started == null">
                    <button *ngIf="!bk.started" pButton type="button" style="height: 50px; width:100%;" class="p-button-sm p-button-warning p-element p-ripple p-button p-component" (click)="startSession(bk.time, bk.id, bk.started)">
                        <span class="pi pi pi-play" style="margin-right:8px;"></span>
                        <span>Start Session</span>
                    </button>
                </div>
            </div>
        </div>
    </p-card>
    <div style="height:80px; width:100%;clear:both;">
       &nbsp;
    </div>
</div>
</div>

<style>
    :host ::ng-deep .p-card {
        background-color: #efefef;
    }

    .p-card .p-card-body {
        padding: 1rem !important;
    }

    :host ::ng-deep .p-button-icon {
        color: white !important;
    }
  </style>


