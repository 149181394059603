import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl, ConvertDateFromDB, ConvertDateToDB } from 'src/globals';
import { getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../_services/busy.service';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';
import { tag } from 'src/types/tag';
import { tag_returndata } from 'src/types/tag_returndata';

@Component({
  selector: 'app-bookinglist',
  templateUrl: './bookinglist.component.html',
  styleUrls: ['./bookinglist.component.css']
})
export class BookinglistComponent  implements OnInit {
  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  BookingDate: Date;

  BookingDateEnd: Date;

  PreviousBookingDate: Date;
  httpResponse: any;
  navSubscription: Subscription = null;
  bookingsInProgress: number = 0;
  totalBookingsToday: number = 0;
  inProgress: boolean = false;

  bcItems: MenuItem[];
  home: MenuItem;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "-";
  //PageLinks: any;
  //currentPageIndex: number = 4;

  cols: any[];
  mode: any = "StartDate";
  chosentags: tag[] = [];
  tags: tag[] = [];

  BookingsTitle: string = "";
  criteria: boolean = false;

  showCriteriaButtonText: string = "Show Criteria";

  //@ViewChild('resourceTable') dataTable: any;
  //@ViewChild('table', { static: true }) dataTable!: any;
  
  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService,
    private route: ActivatedRoute) {
      
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("HOME", user)){
          this.AccessAllowed = true;
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  showBookings(){

    this.busyService.busy();
    //this.isDisabled = false;

    if(this.BookingDate.getTime() == this.BookingDateEnd.getTime())
    {
      this.BookingsTitle = "Bookings for " + this.BookingDate.toLocaleDateString('en-GB');
    }
    else
    {
      this.BookingsTitle = "Bookings between " + this.BookingDate.toLocaleDateString('en-GB') + " and " + this.BookingDateEnd.toLocaleDateString('en-GB');
    }
    this.loadgrid(false);
  }

  showCriteria(){
    this.criteria = !this.criteria;
    this.showCriteriaButtonText = this.criteria ? "Hide Criteria" : "Show Criteria";
  }

  hideCriteria(){
    this.criteria = false;
    this.showCriteriaButtonText = this.criteria ? "Hide Criteria" : "Show Criteria";
  }

  ngOnInit(): void {
    this.globalService.setSmallScreenMenu(false);

    this.BookingDate = new Date();
    this.BookingDateEnd = new Date();

    this.BookingsTitle = "Bookings for " + this.BookingDate.toLocaleDateString('en-GB')

    if(this.route.snapshot.paramMap.get('startdate') != null && this.route.snapshot.paramMap.get('startdate') != 'null')
    {
      this.BookingDate = new Date(this.route.snapshot.paramMap.get('startdate'));
    }

    this.loadTags(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let menuItems: MenuItem[];
    let userid = localStorage.getItem("SmartUserID");

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.busyService.busy();
    
        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          if(this.httpResponse.succeeded)
          {
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
            this.httpResponse.data.venueSettings.forEach(element => {
              localStorage.setItem(element.name, element.value);
            });

            this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
            this.loadgrid(false, false);
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });


    this.http.get(bmxurl + "bookingfee/" + this.VenueID).subscribe(response => {
      this.httpResponse = (response as bookingfee_returndata)
      if (this.httpResponse.succeeded)
      {
        if(this.httpResponse.data != null)
        {
          let BookingFee: cBookingfee = {
            id: (this.httpResponse.data as cBookingfee).id,
            venueid: (this.httpResponse.data as cBookingfee).venueid,
            disabled: (this.httpResponse.data as cBookingfee).disabled,
            bfid: (this.httpResponse.data as cBookingfee).bfid,
            bfpublicapply: (this.httpResponse.data as cBookingfee).bfpublicapply,
            bfadminapply: (this.httpResponse.data as cBookingfee).bfadminapply,
            bfpublicshowtext: (this.httpResponse.data as cBookingfee).bfpublicshowtext,
            bfpublictext: (this.httpResponse.data as cBookingfee).bfpublictext,
            bfpublictype: (this.httpResponse.data as cBookingfee).bfpublictype,
            bfadmintype: (this.httpResponse.data as cBookingfee).bfadmintype,
            bfpublicfee: (this.httpResponse.data as cBookingfee).bfpublicfee,
            bfpublicmin: (this.httpResponse.data as cBookingfee).bfpublicmin,
            bfpublicmax: (this.httpResponse.data as cBookingfee).bfpublicmax,
            bfadminfee: (this.httpResponse.data as cBookingfee).bfadminfee,
            bfadminmin: (this.httpResponse.data as cBookingfee).bfadminmin,
            bfadminmax: (this.httpResponse.data as cBookingfee).bfadminmax
          };

          localStorage.setItem("VenueBookingFee", JSON.stringify(BookingFee));
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee, error:' + error.messages[0]});
    });


    this.cols = [
      { field: 'bookingIdHR', header: 'Sales ID' },
      { field: 'startTime', header: 'Start Time</th>' },
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email' },
      { field: 'phone', header: 'Phone' },
      { field: 'packageName', header: 'Package' },
      { field: 'guests', header: 'Guests' },
      { field: 'total', header: 'Total' },
      { field: 'outstanding', header: 'Outstanding' },
      { field: 'paymentlinksentdate', header: 'Payment Link Sent' },
      { field: 'extraslist', header: 'Extras' },
      { field: 'allergy', header: 'Allergy' },
      ];
  }

  loadTags(showdisabled: boolean) {
    this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.tags = (response as tag_returndata).data;
      console.log(this.tags);
    }, error => {
    });
  }

  editdata(ID){
    localStorage.addItem("listScreenBookings", JSON.stringify(this.ListBookings));
    localStorage.addItem("listScreen", "TRUE");
    localStorage.addItem("homeScreenNumberOfRecords", 0);
    localStorage.addItem("homeScreenCurrentPage", );
    localStorage.addItem("startdate", this.BookingDate);
    localStorage.addItem("enddate", this.BookingDateEnd);

    this.router.navigateByUrl("/editbooking/" + ID);
  }

  editdatanew(ID){
    localStorage.setItem("listScreenBookings", JSON.stringify(this.ListBookings));
    localStorage.setItem("listScreen", "TRUE");
    localStorage.setItem("startdate", this.BookingDate.toJSON());
    localStorage.setItem("enddate", this.BookingDateEnd.toJSON());
    localStorage.setItem("ListTitle", this.BookingsTitle);
    localStorage.setItem("ListTags", JSON.stringify(this.chosentags));

    this.router.navigateByUrl("/editbooking/" + ID);
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled, false);
  }

  disableData(BookingID)
  {
    if (confirm("Are you sure you want to disable this booking?"))
     {
        this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + BookingID).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded = true)
          {
            this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking successfully disabled'});
            //this.loadgrid(false);
            this.busyService.idle();
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking'});
        })
      }
  }

  undisableData(BookingID)
  {
    this.http.get(bmxurl + "enablebooking/" + this.VenueID + "/" + BookingID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Enabled', detail:'Booking successfully enabled'});
        //this.loadgrid(false);
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred enabling this Booking'});
    })
  }

  abandonBooking(BookingID)
  {
    let abandonBooking: any = {
      bookingid: BookingID,
      jwt: null
    }
    //Booking Exists, abandon it
    this.http.post(bmxurl + "abandonbooking/", abandonBooking).subscribe(response => {
      this.messageService.add({severity:'success', summary:'Abandoned', detail:'Booking abandoned successfully.'});
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to abandon booking.'});
    });
  }

  loadgrid(showdisabled: boolean, runClose: boolean = true)
  {
    this.busyService.busy();

    if (localStorage.getItem("startdate") != null && localStorage.getItem("startdate") != "")
    {
      this.BookingDate = new Date(localStorage.getItem("startdate"));
      localStorage.removeItem("startdate");
    }

    if (localStorage.getItem("enddate") != null && localStorage.getItem("enddate") != "")
    {
      this.BookingDateEnd = new Date(localStorage.getItem("enddate"));
      localStorage.removeItem("enddate");
    }

    if (localStorage.getItem("ListTitle") != null && localStorage.getItem("ListTitle") != "")
    {
      this.BookingsTitle = localStorage.getItem("ListTitle")!;
      localStorage.removeItem("ListTitle");
    }

    if (localStorage.getItem("ListTags") != null && localStorage.getItem("ListTags") != "")
    {
      this.chosentags = (JSON.parse((localStorage.getItem("ListTags"))) as tag[]);
      localStorage.removeItem("ListTags");
    }

    localStorage.removeItem("listScreen") 


    let searchCrit = {
      venueid: this.VenueID,
      startdate: ConvertDateToDB(this.BookingDate),
      enddate: ConvertDateToDB(this.BookingDateEnd),
      tags: this.chosentags,
      disabled: showdisabled,
      created: true
    }

    //this.busyService.busy();
    //this.http.get(bmxurl + "BookingCreatedMin/" + this.VenueID + "/" + dateChosen + "/" + showdisabled).subscribe(response => {
    this.http.post(bmxurl + "/BookingList",  searchCrit).subscribe(response => {
      try{
        this.CreateUpdateResponse = (response as booking_returndata);

        //Get VenueDate
        this.ListBookingsGlobal = this.CreateUpdateResponse.data;

        var thisYear: any = this.BookingDate.getFullYear();
        var thisMonth: any = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
        var thisDay: any = ('00' + this.BookingDate.getDate()).slice(-2);
        var fullDate: any = thisYear + thisMonth + thisDay;

        let venueDate: string =  this.VenueID + "_" + fullDate;

        this.hideCriteria();

        this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.inProgress == false);
        this.ListBookings.forEach(element => {

          let extraslist: string = "";

          if(element.extras != null)
            {
              if(element.extras.length > 0)
              { 
                element.extras.forEach(extra => {
                  if (extra.qty > 0)
                    extraslist += extra.name + " (" + extra.qty + "), ";
                });
              }

              if(extraslist.length > 2)
                element.extraslist = extraslist.substring(0, extraslist.length - 2);
            }
        });

        this.bookingsInProgress = this.ListBookingsGlobal.filter(obj => obj.inProgress == true).length;
        this.totalBookingsToday = this.ListBookingsGlobal.filter(obj => obj.inProgress == false).length;;

        //this.setCurrentPage(2);
        this.busyService.idle();
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  emailConf(BookingID)
  {
    this.http.get<any>(bmxurl + "confirmationemail/" + this.VenueID + "/" + BookingID).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Sent Booking Confirmation.'})
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: 'Could not send Booking Confirmation: ' + response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send Booking Confirmation'});
    })
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + element.bookingId).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        //this.loadgrid(false);
        this.busyService.idle();
      }
    }, error => {

    })
    });

  }

  doneDate(){

    this.inProgress = false;

    //this.loadgrid(false);
    this.busyService.idle();

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.BookingDate = this.addDays(new Date(this.BookingDate), num);
    this.doneDate();
  }

  exportData()
  {


  }

}

