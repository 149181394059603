<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="timearrayfull && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-bottom: -38px" i18n>Day Sheet for</h3>
    <div style="float: left; margin-left: 180px;">

        <button pButton type="button" style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-7);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-double-left" style="margin-right:8px;"></span>
        </button>
        <button pButton type="button" style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-left" style="margin-right:8px;"></span>
        </button>
        <div style="float:left; width: 200px;">
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="chosenDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="getResourcesForId()" (onInput)="getResourcesForId()"></p-calendar>  
        </div> 
        <button pButton type="button" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-right" style="margin-right:8px;"></span>
        </button>
        <button pButton type="button" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(7);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-double-right" style="margin-right:8px;"></span>
        </button>

        <button style="height: 50px; margin-left: 5px;float:left;" pButton pRipple type="button" class="p-button-sm p-element p-ripple p-button p-component" (click)="bookDateChosen(chosenDate)">
            <span class="pi pi-calendar" style="margin-right:8px;"></span>
            Book this Date
        </button>

    </div>

    <div  class="surface-card p-4 shadow-2 border-round" style="margin-top:20px;">
        <div style="width:75%; float:left;">
            <p-tabView (onChange)="getResourcesForId()" [(activeIndex)]="selectedIndex">
                <p-tabPanel header="{{res.name}}" *ngFor="let res of ListResources">
                    <div id="dsMainDiv" *ngIf="timeArray" style="overflow: scroll; height: calc(100vh - 270px);" (scroll)="doScrollyStuff($event)">

                        <table class="" style="background-color:#eeeeee;width: calc(100vw - 853px);display: block;">
                            <tr>
                                <th style="background-color:#ffffff"><b>&nbsp;</b></th>
                                <th style="background-color:#ffffff; text-align: center;"><b>Used</b></th>
                                <th style="background-color:#ffffff">&nbsp;</th>
                                <th style="background-color:#ffffff"><b>Rem</b></th>
                            </tr>
                            <tr *ngFor="let row of (timeArray)" style="height:100%;">
                                <td *ngIf="row[0].exceeded" width="3%" style="height:100%;background-color:#ffffff; font-weight:bold; color:red; text-align:center">{{row[0].time}}</td>
                                <td *ngIf="!row[0].exceeded" width="3%" style="height:100%;background-color:#ffffff; text-align:center">{{row[0].time}}</td>
                                <td width="5%" style="height:100%;background-color:#ffffff; text-align:center; font-weight:bold">{{row[0].rowQty}}</td>
                                <td width="100%" style="height:100%">
                                   <table style="width:100%">
                                        <tr>
                                            <td *ngFor="let td of (row) ">

                                                <button *ngIf="td.closing && td.index == 0 && !td.inprogress" [style.backgroundColor]="td.colour" style="border-bottom:15px solid black;border-top:0px;border-left:0px;border-right:0px;float:left;width:100%;height:30px;background-image: url('https://dev.wannabook.online/assets/images/dot.png');background-repeat: no-repeat;background-position: center;background-size: 10px,10px;" (click)="showBookingData(td);"></button>
                                                <button *ngIf="td.closing && td.index != 0 && !td.inprogress" [style.backgroundColor]="td.colour" style="border-bottom:15px solid black;border-top:0px;border-left:0px;border-right:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                            
                                                <button class="stripeswithblob" *ngIf="td.closing && td.index == 0 && td.inprogress" [style.backgroundColor]="td.colour" style="border-bottom:15px solid black;border-top:0px;border-left:0px;border-right:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                                <button class="stripes" *ngIf="td.closing && td.index != 0 && td.inprogress" [style.backgroundColor]="td.colour" style="border-bottom:15px solid black;border-top:0px;border-left:0px;border-right:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                            
                                                <!------------------------>

                                                <button *ngIf="!td.closing && td.index == 0 && !td.inprogress" [style.backgroundColor]="td.colour" style="border:0px;float:left;width:100%;height:30px;background-image: url('https://dev.wannabook.online/assets/images/dot.png');background-repeat: no-repeat;background-position: center;background-size: 10px,10px;" (click)="showBookingData(td);"></button>
                                                <button *ngIf="!td.closing && td.index != 0 && !td.inprogress" [style.backgroundColor]="td.colour" style="border:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                            
                                                <button class="stripeswithblob" *ngIf="!td.closing && td.index == 0 && td.inprogress" [style.backgroundColor]="td.colour" style="border:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                                <button class="stripes" *ngIf="!td.closing && td.index != 0 && td.inprogress" [style.backgroundColor]="td.colour" style="border:0px;float:left;width:100%;height:30px;" (click)="showBookingData(td);"></button>
                                            

                
                                            </td>
                                        </tr>
                                   </table>
                                </td>
                                <td style="background-color:#ffffff; text-align:center" width="5%">{{row[0].maxQty - row[0].rowQty}}</td>
                            </tr>
                        </table>

                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>

        <div style="height: calc(100vh - 261px)">
        <!--<div class="surface-card p-4 shadow-2 border-round" style="width: 24%;float: left;margin-left: 13px;margin-top: 56px;margin-right: -88px;height: 33%;overflow-y: scroll;">
            <span class="p-input-icon-left ml-auto" style="margin-right:20px;width:100% "> 
                <div style="margin-bottom:10px;"><b>Time Selected: {{chosenTime}}</b></div>
                <i class="pi pi-search" style="margin-top: 10px;"></i>
                <input pInputText type="text" (input)="daysheetTable.filterGlobal($event.target.value, 'contains')" style="height: 29px;padding-left:33px; width:100%;" />
            </span>
            <div>
                <p-table  #daysheetTable 
                    [value]="" sortField="name" 
                    [globalFilterFields]="['name', 'bookingIdHR']"
                    responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr (click)="viewData(data.bookingId)" style="cursor:pointer">
                        <td *ngIf="data.notes != '' && data.notes != null" width="30px;">
                            <span style="color:red" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                        </td>
                        <td *ngIf="data.notes == '' || data.notes == null" width="30px;">
                            <span style="color:#dfdfdf" class="pi pi-exclamation-circle"></span> 
                        </td>
                        <td style="padding: 10px 0px 10px 0px; font-size:14px;" width="60%">
                            {{data.bookingIdHR}}
                        </td>
                        <td style="padding: 10px 0px 10px 0px; font-size:14px;" width="60%">
                            {{data.customer.fullName}}
                        </td>
                        <td style="padding: 10px 0px 10px 0px; font-size:14px;" width="15%">
                            {{data.bookingdata.numofpeople}}
                        </td>
                        <td *ngIf="data.bookingdata.outstanding > 0" style="padding: 10px 0px 10px 0px; font-size:14px;"  width="5%" >
                            <b style="color:red;">{{data.outstanding | currency:CurrencySymbol}}</b>
                        </td>
                        <td *ngIf="data.bookingdata.outstanding <= 0" style="padding: 10px 0px 10px 0px; text-align:right; font-size:14px;"  width="5%" >
                            {{data.outstanding | currency:CurrencySymbol}}
                        </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>-->
        <div class="surface-card p-4 shadow-2 border-round" style="width: 24%;float: left;margin-left: 13px;margin-top: 56px;margin-right: -88px;height: 108%; overflow-y: scroll;">
            <div *ngIf="bookingFromClick">
                <p style="font-weight:bold;">Summary</p>
                <div>
                    <button *ngIf="!bookingFromClick.inprogress" style="margin-top: -6px !important; width: 100% !important; margin-bottom: 20px;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="editData(bookingFromClick.bookingdata.bookingid);">
                        <span class="pi pi-pencil" style="margin-right:8px;"></span>
                        Edit this booking
                    </button>
                    <div *ngIf="bookingFromClick.customer">
                        <div style="width:100%; margin-bottom: 15px;">
                            <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer</b> : {{bookingFromClick.customer.fullName != null ? bookingFromClick.customer.fullName + " " + bookingFromClick.customer.address1 + ", " + bookingFromClick.customer.address2 + ", " + bookingFromClick.customer.townCity + ", " + bookingFromClick.customer.postCode : ""}}
                        </div>
                    </div>

                    <div *ngIf="bookingFromClick.bookingdata.outstanding" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : <span style="color: red; font-weight: bold;">{{bookingFromClick.bookingdata.outstanding | currency:CurrencySymbol }}</span>
                        <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="editbookingpayoff(bookingFromClick.bookingdata.bookingid)">
                            <span class="pi pi-pound" style="margin-left: -8px; font-size: 15px;"></span>
                        </button>
                    </div>
                    <div *ngIf="bookingFromClick.bookingdata.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : {{bookingFromClick.bookingdata.outstanding | currency:CurrencySymbol }}
                    </div>

                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-users" style="margin-right: 10px;"></span><b>Admissions</b> : {{bookingFromClick.bookingdata.admissions}}
                        <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component">
                            <span class="pi pi-users" style="margin-left: -8px; font-size: 15px;" (click)="editbookingadmissions(bookingFromClick.bookingdata.bookingid)"></span>
                        </button>
                    </div>

                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Start Date/Time</b> : {{bookingFromClick.bookingdata.bookingstart | date:'HH:mm' : 'UTC'}}
                    </div>

                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-box" style="margin-right: 10px;"></span><b>Package</b> : {{bookingFromClick.bookingdata.packagename}}
                    </div>

                    <div *ngIf="bookingFromClick.bookingdata.extras != ''" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-star" style="margin-right: 10px;"></span><b>Extras</b> : {{bookingFromClick.bookingdata.extras}}
                    </div>


                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Sales ID</b> : {{bookingFromClick.bookingdata.humanreadableid}}
                    </div>
<!-- 
                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>End Date/Time</b> : {{bookingFromClick.bookingdata.bookingend| date:'HH:mm' : 'UTC'}}
                    </div>
                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Arrival Date/Time</b> : {{bookingFromClick.bookingdata.arrival| date:'HH:mm' : 'UTC'}}
                    </div> -->

                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Sales Value</b> : {{bookingFromClick.bookingdata.salesvalue | currency:CurrencySymbol }}
                    </div>
                

                    <div *ngIf="bookingFromClick.bookingdata.totaldiscount > 0" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Discounts</b> : {{bookingFromClick.bookingdata.discounts | currency:CurrencySymbol }}
                    </div>
                
                    <div *ngIf="bookingFromClick.bookingdata.bookingfee > 0" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Booking Fee</b> : {{bookingFromClick.bookingdata.bookingfee | currency:CurrencySymbol }}
                    </div>
                
                    <div *ngIf="bookingFromClick.bookingdata.outstanding" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : <span>{{bookingFromClick.bookingdata.paid | currency:CurrencySymbol }}</span>
                    </div>
                    <div *ngIf="bookingFromClick.bookingdata.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : {{bookingFromClick.bookingdata.paid | currency:CurrencySymbol }}
                    </div>
                
                    <!-- <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-list" style="margin-right: 10px;"></span><b>Itinerary</b> : {{bookingFromClick.bookingdata.itinerary}}
                    </div> -->


                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-list" style="margin-right: 10px;"></span><b>Itinerary</b>:
        
                        <div style="margin-top:5px; margin-bottom:10px;">
                            <table [cellPadding]="3" >
                                <tr style="border-bottom: 1px solid #dddddd">
                                    <td style="width:5%">&nbsp;</td>
                                    <td style="width:50%">
                                        <b>Arrival:</b>
                                    </td>
                                    <td style="width:20%">
                                        {{bookingFromClick.bookingdata.arrival| date: 'HH:mm' : 'UTC'}}
                                    </td >
                                    <td style="width:20%">&nbsp;</td>
                                </tr>
        
                                <tr style="border-bottom: 1px solid #dddddd" *ngFor="let itin of (bookingFromClick.bookingdata.itineraryEX)">
                                    <td>&nbsp;</td>
                                    <td>
                                       <b>{{itin.resource}}</b>
                                    </td>
                                    <td>
                                        {{itin.starttime}}
                                    </td>
                                    <td >
                                        {{itin.people}}&nbsp;people
                                    </td>
        
                                </tr>
        
                                <tr style="border-bottom: 1px solid #dddddd">
                                    <td>&nbsp;</td>
                                    <td>
                                        <b>End:</b>
                                    </td>
                                    <td>
                                        {{addMs(bookingFromClick.bookingdata.bookingend, 1)| date:'HH:mm' : 'UTC'}}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </table>
                    </div>

                </div>
                   
                    

                    <div *ngIf="bookingFromClick.customer">
                        <div style="width:100%; margin-bottom: 15px;">
                            <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Email</b> : {{bookingFromClick.customer.email != null ? bookingFromClick.customer.email : ""}}
                            <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="sendanemail(bookingFromClick.bookingdata.bookingid)">
                                <span class="pi pi-envelope" style="margin-left: -8px; font-size: 15px;"></span>
                            </button>
                        </div>
                        <div style="width:100%; margin-bottom: 15px;">
                            <span class="pi pi-mobile" style="margin-right: 10px;"></span><b>Mobile</b> : {{bookingFromClick.customer.mobile != null ? bookingFromClick.customer.mobile : ""}}
                            <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="makeacall(bookingFromClick.bookingdata.bookingid)">
                                <span class="pi pi-mobile" style="margin-left: -8px; font-size: 15px;"></span>
                            </button>
                        </div>
                    </div>

                    <div style="width:100%; margin-bottom: 15px;">
                        <span class="pi pi-file" style="margin-right: 10px;"></span><b>Notes</b> : {{bookingFromClick.bookingdata.notes}}
                    </div>
                </div>
            </div>
        </div>
        </div>
       
    </div>
</div>