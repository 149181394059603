import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingComponent } from './booking/booking.component';
import { ResourceComponent } from './resource/resource.component';
import { HomeComponent } from './home/home.component';
import { RestrictionComponent } from './restriction/restriction.component';
import { PackageruleComponent } from './packagerule/packagerule.component';
import { PackageruleeditComponent } from './packagerule/packageruleedit.component';
import { ClassComponent } from './class/class.component';
import { RestrictioneditComponent } from './restriction/restrictionedit.component';
import { VenueclosingsComponent } from './venueclosings/venueclosings.component';
import { VenueclosingsEditComponent } from './venueclosings/venueclosingsedit.component';
import { ClasseditComponent } from './class/classedit.component';
import { ResourceeditComponent } from './resource/resourceedit.component';
import { AtaglanceComponent } from './ataglance/ataglance.component';
import { MakeabookingComponent } from './makeabooking/makeabooking.component';
import { tagComponent } from './tag/tag.component';
import { TageditComponent } from './tag/tagedit.component';
import { extraComponent } from './extra/extra.component';
import { ExtraeditComponent } from './extra/extraedit.component';
import { extraoptionsComponent } from './extraoption/extraoption.component';
import { ExtraOptioneditComponent } from './extraoption/extraoptionedit.component';
import { PackageeditComponent } from './package/packageedit.component';
import { packageComponent } from './package/package.component';
import { VenuesettingsComponent } from './venuesettings/venuesettings.component';
import { AdmissionsComponent } from './admissions/admissions.component';
import { AdmissioneditComponent } from './admissions/admissionedit.component';
import { PackagestarttimesComponent } from './packagestarttimes/packagestarttimes.component';
import { TimesetsComponent } from './timesets/timesets.component';
import { TimeseteditComponent } from './timesets/timesetedit.component';
import { PackageextrasComponent } from './packageextras/packageextras.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { TimesetviewComponent } from './timesets/timesetview.component';
import { DepositpoliciesComponent } from './depositpolicies/depositpolicies.component';
import { DepositpolicieseditComponent } from './depositpolicies/depositpoliciesedit.component';
import { EditbookingComponent } from './editbooking/editbooking.component';
import { PaymentTestComponent } from './payment-test/payment-test.component';
import { StaffloginComponent } from './stafflogin/stafflogin.component';
import { BookingcalendarComponent } from './bookingcalendar/bookingcalendar.component';
import { TillComponent } from './till/till.component';
import { TilldepartmentsComponent } from './tilldepartments/tilldepartments.component';
import { TilldepartmentseditComponent } from './tilldepartments/tilldepartmentsedit.component';
import { TillproductsComponent } from './tillproducts/tillproducts.component';
import { TillproductseditComponent } from './tillproducts/tillproductsedit.component';
import { ResourcegroupnameComponent } from './resourcegroupname/resourcegroupname.component';
import { ResourcegroupnameeditComponent } from './resourcegroupname/resourcegroupnameedit.component';
import { BookingfeeeditComponent } from './bookingfee/bookingfeeedit.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { BusinessComponent } from './business/business.component';
import { PinpadComponent } from './pinpad/pinpad.component';
import { ReportsComponent } from './reports/reports.component';
import { CashupComponent } from './cashup/cashup.component';
import { DiscountsEditComponent } from './discounts/discountsedit.component';
import { ConfirmationeditorComponent } from './confirmationeditor/confirmationeditor.component';
import { PackagegroupsComponent } from './packagegroups/packagegroups.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { ConfirmationComponent } from './confirmationeditor/confirmation.component';
import { PackagegroupeditComponent } from './packagegroups/packagegroupedit.component';
import { ImageuploadtestComponent } from './imageuploadtest/imageuploadtest.component';
import { UsersComponent } from './users/users.component';
import { UsereditComponent } from './users/useredit.component';
import { PublictermsandconditionsComponent } from './publictermsandconditions/publictermsandconditions.component';
import { DaysheetStyle1Component } from './daysheetStyle1/daysheetStyle1.component';
import { SearchComponent } from './search/search.component';
import { BookingquestionsComponent } from './bookingquestions/bookingquestions.component';
import { BookingquestioneditComponent } from './bookingquestions/bookingquestionedit.component';
import { BookingquestionnaireComponent } from './bookingquestionnaire/bookingquestionnaire.component';
import { BookingquestionnaireeditComponent } from './bookingquestionnaire/bookingquestionnaireedit.component';
import { ResourceclosingsComponent } from './resourceclosings/resourceclosings.component';
import { ResourceclosingseditComponent } from './resourceclosings/resourceclosingsedit.component';
import { CashuplistComponent } from './cashup/cashuplist.component';
import { EventsComponent } from './events/events.component';
import { EventeditComponent } from './events/eventedit.component';
import { ResourceadjustmentsComponent } from './resourceadjustments/resourceadjustments.component';
import { ResourceadjustmenteditComponent } from './resourceadjustments/resourceadjustmentedit.component';
import { EmailtemplateComponent } from './emailtemplate/emailtemplate.component';
import { EmailtemplateeditComponent } from './emailtemplate/emailtemplateedit.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BookingblockComponent } from './bookingblock/bookingblock.component';
import { CounterslistComponent } from './counterslist/counterslist.component';
import { BuyvoucherComponent } from './buyvoucher/buyvoucher.component';
import { VoucherlistComponent } from './voucherlist/voucherlist.component';
import { VouchereditComponent } from './voucherlist/voucheredit.component';
import { TasktypelistComponent } from './tasktype/tasktypelist.component';
import { TasktypeeditComponent } from './tasktype/tasktypeedit.component';
import { CreatenewcustomerComponent } from './createnewbusinessuser/createnewcustomer.component';
import { AddnewvenuetobusinessComponent } from './createnewbusinessuser/addnewvenuetobusiness.component';
import { ExtrasreportComponent } from './extrasreport/extrasreport.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { BookinganswersreportComponent } from './bookinganswersreport/bookinganswersreport.component';
import { MarketingComponent } from './marketing/marketing.component';
import { MarketingbirthdayComponent } from './marketing/marketingbirthday/marketingbirthday.component';
import { MarketingabandonedComponent } from './marketing/marketingabandoned/marketingabandoned.component';
import { MarketinggroupComponent } from './marketing/marketinggroup/marketinggroup.component';
import { MarketingmissingComponent } from './marketing/marketingmissing/marketingmissing.component';
import { MarketingbirthdayeditComponent } from './marketing/marketingbirthday/marketingbirthdayedit.component';
import { MarketingemailsComponent } from './marketing/marketingemails/marketingemails.component';
import { MarketingrevenueComponent } from './marketing/marketingrevenue/marketingrevenue.component';
import { WarningsComponent } from './warnings/warnings.component';
import { WarningeditComponent } from './warnings/warningedit.component';
import { TestingtasklistComponent } from './testingtasklist/testingtasklist.component';
import { BuycreditsComponent } from './buycredits/buycredits.component';
import { LinkmanagerComponent } from './linkmanager/linkmanager.component';
import { LinkeditorComponent } from './linkmanager/linkeditor.component';
import { BookinglistComponent } from './bookinglist/bookinglist.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'home/:startdate',
    component: HomeComponent,
  },
  {
    path: 'bookinglist',
    component: BookinglistComponent,
  },
  {
    path: 'pinpad',
    component: PinpadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'useredit/:id',
    component: UsereditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingquestions',
    component: BookingquestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingblock/:startdate',
    component: BookingblockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingquestionedit/:id',
    component: BookingquestioneditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingquestionnaire',
    component: BookingquestionnaireComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingquestionnaireedit/:id',
    component: BookingquestionnaireeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'discounts',
    component: DiscountsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fileupload',
    component: ImageuploadtestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'discountedit/:id',
    component: DiscountsEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmationeditor/:id',
    component: ConfirmationeditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmations',
    component: ConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'counterslist/:date/:id',
    component: CounterslistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'emailtemplates',
    component: EmailtemplateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'emailtemplateeditor/:id',
    component: EmailtemplateeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packagegroups',
    component: PackagegroupsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packagegroupedit/:id',
    component: PackagegroupeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'business',
    component: BusinessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'terms',
    component: TermsandconditionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cashup/:id',
    component: CashupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cashuplist',
    component: CashuplistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ataglance',
    component: AtaglanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ataglance/:startdate',
    component: AtaglanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'daysheetstyle1',
    component: DaysheetStyle1Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'booking/:id',
    component: MakeabookingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingcalendar',
    component: BookingcalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'stafflogin',
    component: StaffloginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editbooking/:id',
    component: EditbookingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editbooking/:id/:startdate',
    component: EditbookingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resource',
    component: ResourceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourcegroupnames',
    component: ResourcegroupnameComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourcegroupnameedit/:id',
    component: ResourcegroupnameeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admission',
    component: AdmissionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admissionedit/:id',
    component: AdmissioneditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookingfeeedit',
    component: BookingfeeeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tag',
    component: tagComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tagedit/:id',
    component: TageditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourceedit/:id',
    component: ResourceeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'restriction',
    component: RestrictionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packagerule',
    component: PackageruleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packageruleedit/:id',
    component: PackageruleeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'package',
    component: packageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eventedit/:id',
    component: EventeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packagestarttimes/:id',
    component: PackagestarttimesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packageedit/:id',
    component: PackageeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourceadjustments',
    component: ResourceadjustmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourceadjustmentedit/:id',
    component: ResourceadjustmenteditComponent,
    canActivate: [AuthGuard],
  },
  {
  path: 'tilldepartments',
  component: TilldepartmentsComponent,
  canActivate: [AuthGuard],
  },
  {
    path: 'tilldeptartmentedit/:id',
    component: TilldepartmentseditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tillproducts/:id',
    component: TillproductsComponent,
    canActivate: [AuthGuard],
  },
  {
      path: 'tillproductedit/:id',
      component: TillproductseditComponent,
      canActivate: [AuthGuard],
    },
  {
    path: 'restrictionedit/:id',
    component: RestrictioneditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'venueclosings',
    component: VenueclosingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'venueclosingsedit/:id',
    component: VenueclosingsEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourceclosings',
    component: ResourceclosingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resourceclosingsedit/:id',
    component: ResourceclosingseditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'class',
    component: ClassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'classedit/:id',
    component: ClasseditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extra',
    component: extraComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extraedit/:id',
    component: ExtraeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extraoptions',
    component: extraoptionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extraoptionedit/:id',
    component: ExtraOptioneditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'venuesettings',
    component: VenuesettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sellavoucher',
    component: BuyvoucherComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voucheredit/:id',
    component: VouchereditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voucherlist',  
    component: VoucherlistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timesets',
    component: TimesetsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timesetedit/:id',
    component: TimeseteditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'depositpolicies',
    component: DepositpoliciesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'depositpoliciesedit/:id',
    component: DepositpolicieseditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extrasreport',
    component: ExtrasreportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookinganswersreport',
    component: BookinganswersreportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'qrcode',
    component: QrcodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketing',
    component: MarketingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingbirthday',
    component: MarketingbirthdayComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingbirthdayedit/:id',
    component: MarketingbirthdayeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingabandoned',
    component: MarketingabandonedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketinggroup',
    component: MarketinggroupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingmissing',
    component: MarketingmissingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingemails',
    component: MarketingemailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketingrevenue',
    component: MarketingrevenueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timesetview/:id',
    component: TimesetviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packageextras/:id',
    component: PackageextrasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'paymenttest',
    component: PaymentTestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tasktypelist',
    component: TasktypelistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tasktypeedit/:id',
    component: TasktypeeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'testtask',
    component: TestingtasklistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'till',
    component: TillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'publictandcs',
    component: PublictermsandconditionsComponent
  },
  {
    path: 'warnings',
    component: WarningsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'warningedit/:id',
    component: WarningeditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptions',
    component: BuycreditsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'linkmanager',
    component: LinkmanagerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'linkeditor/:id',
    component: LinkeditorComponent,
    canActivate: [AuthGuard],
  },
  
  // {
  //   path: 'create',
  //   component: CreatenewcustomerComponent
  // },
  // {
  //   path: 'addvenuetobusiness',
  //   component: AddnewvenuetobusinessComponent
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      //scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
