<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListBookings && AccessAllowed" class="p-3 flex flex-column flex-auto">

    <h3 style="margin-bottom: -40px" i18n>{{BookingsTitle}}
        <button pTooltip="Show critieria" pButton type="button" style="height: 50px;width: 180px;margin-left:10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="showCriteria()">
            <span class="pi pi-sliders-h" style="margin-right:8px; font-size: 16px;"></span>
            <span style="font-size:17px;">{{showCriteriaButtonText}}</span>
        </button>
    </h3>

    <div style="text-align: right;margin-top: -10px;">

        <button style="height: 50px;"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="resourceTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        
        <p-toggleButton pTooltip="Show disabled bookings" class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" offIcon="pi pi-eye-slash" onIcon="pi pi-eye" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>
    </div>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:13px;" *ngIf="criteria">
    <div style="margin-bottom:15px;">
        <span style="float: left; margin-top: 10px; margin-right: 10px;">From:</span>
        <div style="float:left; width: 200px;">
            <p-calendar  [selectOtherMonths]="true" [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [utc]="false" [showIcon]="true"></p-calendar>  
        </div> 
        <span style="float: left; margin-top: 10px; margin-right: 10px;margin-left:10px">To:</span>
        <div style="float:left; width: 200px;">
            <p-calendar  [selectOtherMonths]="true" [(ngModel)]="BookingDateEnd" firstDayOfWeek="1" dateFormat="dd/mm/yy" [utc]="false" [showIcon]="true"></p-calendar>  
        </div> 

    </div>
    <br>
    <!-- <div class="field mb-4 col-12" style="width: 100%; margin-top: 20px;margin-left: -10px;display:block;">
        <label for="" class="font-medium">Select Tags (Selecting no tags will return all data regardless of tag)</label>
        <br>
        <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="chosentags" display="chip" style="min-width: 400px;height:50px;margin-top:10px;">
          <ng-template let-value pTemplate="selectedItems">
            <span *ngFor="let tag of chosentags">
              <p-chip label="{{tag.name}}" styleClass="mr-2" [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
              </p-chip>
            </span>
            <span>&nbsp;</span>
          </ng-template>
        </p-multiSelect>
    </div> -->

    <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;margin-bottom: 20px;margin-top:20px">
        <div class="field mb-4 col-12">
          <label for="" class="font-medium">Select Tags (Selecting no tags will return all data regardless of tag)</label>
          <br />
          <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="chosentags" display="chip">
            <ng-template let-value pTemplate="selectedItems">
              <span *ngFor="let tag of chosentags">
                <p-chip label="{{tag.name}}" styleClass="mr-2"  [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                </p-chip>
              </span>
              <div *ngIf="!chosentags || chosentags.length === 0" class="country-placeholder"> Select Tag(s) </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>

    <button pTooltip="Get Bookings" pButton type="button" style="float: right; height: 50px; margin-right: 10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="showBookings()">
        <span class="pi pi-search" style="margin-right:8px; font-size: 16px;"></span>
        <span style="font-size:17px;">Search</span>
    </button>

    </div>
    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:20px;">
    <p-table  #resourceTable (onPage)="paginate($event)"
    [columns]="cols"
    [value]="ListBookings" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    [selectionPageOnly]="true">

        <ng-template pTemplate="header">
            <tr>
                <th width="1%">
                    &nbsp;
                </th>
                <th width="1%">
                    &nbsp;
                </th>
                <th pSortableColumn="bookingId" width="8%">Sales ID
                    <p-sortIcon [field]="bookingIdHR"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="10%">Start Date
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="8%">Start
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="8%">Name
                    <p-sortIcon [field]="name"></p-sortIcon>
                </th>
                <th pSortableColumn="packageName" width="15%">Package
                    <p-sortIcon [field]="packageName"></p-sortIcon>
                </th>
                <th pSortableColumn="guests" width="6%">Guests
                    <p-sortIcon [field]="guests"></p-sortIcon>
                </th>
                <th pSortableColumn="total" width="5%">Total
                    <p-sortIcon [field]="total"></p-sortIcon>
                </th>
                <th pSortableColumn="outstanding" width="5%">O/S
                    <p-sortIcon [field]="outstanding"></p-sortIcon>
                </th>
                <th width="20%">
                    Extras
                </th>
                <th width="5%">
                    Allergy
                </th>
                <th width="5%"></th>
                <th width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}"  *ngIf="!data.inProgress && !inProgress" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td (click)="editdatanew(data.bookingId)" >
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">{{data.bookingIdHR}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.name}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.packageName}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.guests}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.total | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                <td (click)="editdatanew(data.bookingId)">{{data.extraslist}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.allergy}}</td>

                <td *ngIf="!data.disabled"><button  pTooltip="Send Booking Confirmation" pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="emailConf(data.bookingId)" i18>
                    <span class="pi pi-envelope"></span>
                </button></td>

                <td *ngIf="!data.disabled"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableData(data.bookingId)" i18>
                    <span class="pi pi-trash"></span>
                </button></td>

                <td *ngIf="data.disabled"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="undisableData(data.bookingId)" i18>
                    <span class="pi pi-check-circle"></span>
                </button></td>

                <td *ngIf="data.disabled">&nbsp;</td>
            </tr>

            <!-- <tr *ngIf="data.disabled" style="background-color: #FCE1B5;">
                <td>
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td>
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td style="font-style: italic;">{{data.bookingIdHR}}</td>
                <td style="font-style: italic;">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td style="font-style: italic;">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td style="font-style: italic;">{{data.name}}</td>
                <td style="font-style: italic;">{{data.packageName}}</td>
                <td style="font-style: italic;">{{data.guests}}</td>
                <td style="font-style: italic;">{{data.total | currency: CurrencySymbol}}</td>
                <td style="font-style: italic;">{{data.outstanding | currency: CurrencySymbol}}</td>
                
                <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') != '01/01/01 00:00'" style="font-style: italic; text-align: centre;">{{data.paymentlinksentdate | date:'dd/MM/yy HH:mm'}}</td>
                <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') == '01/01/01 00:00'" style="font-style: italic; text-align: centre;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--</td>

                <td style="font-style: italic;">{{data.expiry | date:'HH:mm:ss'}}</td>

                <td>
                    <td *ngIf="!data.publicBooking"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="abandonBooking(data.bookingId)" i18>
                        <span class="pi pi-times"></span>
                    </button>
                </td>
                <td *ngIf="data.publicBooking">
                </td>
            </tr> -->
        </ng-template>
    </p-table>

        <!-- <button pButton type="button" class="p-button-sm p-element p-ripple p-button p-component" (click)="exportData()" i18>
            <span class="pi pi-plus"></span> Export this data
        </button> -->
    </div>
</div>
