import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { cVenueclosings, venueclosings } from 'src/types/venueclosings';
import { venueclosings_returndata } from 'src/types/venueclosings_returndata';

import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-venueclosingsedit[vcStartDate]',
  templateUrl: './venueclosingsedit.component.html',
  styleUrls: ['./venueclosingsedit.component.css']
})
export class VenueclosingsEditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  VenueClosing: cVenueclosings;
  httpResponse: any;
  id: string;
  vcStartDate: Date;
  vcEndDate: Date;

  venueNameValid: boolean = true;
  venueStartDateValid: boolean = true;
  venueEndDateValid: boolean = true;
  venueDateLoginValid: boolean = true;

  venueStartTimeHours: any[];
  venueStartTimeMins: any[];

  venueEndTimeHours: any[];
  venueEndTimeMins: any[];
  
  venueSelectedStartTimeHours: string;
  venueSelectedStartTimeMins: string;

  venueSelectedEndTimeHours: string;
  venueSelectedEndTimeMins: string;
  
  pageReady: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  newStartDate: Date;
  newEndDate: Date;

  closedallday: boolean = false;

  stateOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService,private globalService: GlobalService) { 
    this.VenueClosing = {
      id: null,
      venueClosingId: null,
      name: "",
      disabled: false,
      venueId: this.VenueID,
      startDate: null,
      endDate: null,
      publicMessage: null,
      venueMessage: null,
      closedallday: false
    }

    this.venueStartTimeHours = [
      {name: '00', code: '00'},
      {name: '01', code: '01'},
      {name: '02', code: '02'},
      {name: '03', code: '03'},
      {name: '04', code: '04'},
      {name: '05', code: '05'},
      {name: '06', code: '06'},
      {name: '07', code: '07'},
      {name: '08', code: '08'},
      {name: '09', code: '09'},
      {name: '10', code: '10'},
      {name: '11', code: '11'},
      {name: '12', code: '12'},
      {name: '13', code: '13'},
      {name: '14', code: '14'},
      {name: '15', code: '15'},
      {name: '16', code: '16'},
      {name: '17', code: '17'},
      {name: '18', code: '18'},
      {name: '19', code: '19'},
      {name: '20', code: '20'},
      {name: '21', code: '21'},
      {name: '22', code: '22'},
      {name: '23', code: '23'}
      ];
   
    this.venueStartTimeMins = [
        {name: '00', code: '00'},
        {name: '05', code: '05'},
        {name: '10', code: '10'},
        {name: '15', code: '15'},
        {name: '20', code: '20'},
        {name: '25', code: '25'},
        {name: '30', code: '30'},
        {name: '35', code: '35'},
        {name: '40', code: '40'},
        {name: '45', code: '45'},
        {name: '50', code: '50'},
        {name: '55', code: '55'},
      ];

  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("VENUECLOSINGS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "VenueClosing/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as venueclosings_returndata)
              if (this.httpResponse.succeeded)
              {
                this.VenueClosing = {
                  id: (this.httpResponse.data as cVenueclosings).id,
                  venueClosingId: (this.httpResponse.data as cVenueclosings).venueClosingId,
                  name: (this.httpResponse.data as cVenueclosings).name,
                  disabled: (this.httpResponse.data as cVenueclosings).disabled,
                  venueId: (this.httpResponse.data as cVenueclosings).venueId,
                  startDate: (this.httpResponse.data as cVenueclosings).startDate,
                  endDate: (this.httpResponse.data as cVenueclosings).endDate,
                  publicMessage: (this.httpResponse.data as cVenueclosings).publicMessage,
                  venueMessage: (this.httpResponse.data as cVenueclosings).venueMessage,
                  closedallday: (this.httpResponse.data as cVenueclosings).closedallday || false
                };
      
                this.newStartDate = new Date(Number(this.VenueClosing.startDate.substring(0,4)), Number(this.VenueClosing.startDate.substring(5,7)) - 1, Number(this.VenueClosing.startDate.substring(8,10)));
                this.newEndDate = new Date(Number(this.VenueClosing.endDate.substring(0,4)), Number(this.VenueClosing.endDate.substring(5,7)) - 1, Number(this.VenueClosing.endDate.substring(8,10)));

                this.venueSelectedStartTimeHours = this.VenueClosing.startDate.substring(11,13);
                this.venueSelectedStartTimeMins = this.VenueClosing.startDate.substring(14,16);
        
                this.venueSelectedEndTimeHours = this.VenueClosing.endDate.substring(11,13);
                this.venueSelectedEndTimeMins = this.VenueClosing.endDate.substring(14,16);
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Venue Closings'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Venue Closings, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else
          {
            this.pageReady = true;
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if ([this.DoDateValidCheck(), this.DoValidCheck(), this.DoDateCheck()].every(x => x == true))
    {
      if(this.id=="0")
      {
        this.http.post<venueclosings>(bmxurl + "/VenueClosing", this.VenueClosing).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Venue Closing successfully created'});
            this.router.navigateByUrl("/venueclosings");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })
      }
      else
      {
        this.VenueClosing.disabled = false;
        
        this.http.put<venueclosings>(bmxurl + "/VenueClosing", this.VenueClosing).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Venue Closing successfully updated'});
            this.router.navigateByUrl("/venueclosings");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoDateCheck()
  {
    let AllValid = true;

     let startdatestring = "";
    
    startdatestring = this.stringifyDate(new Date(this.newStartDate).getFullYear(), new Date(this.newStartDate).getMonth(), new Date(this.newStartDate).getDate(), Number(this.venueSelectedStartTimeHours), Number(this.venueSelectedStartTimeMins));

    this.VenueClosing.startDate = startdatestring;

     let enddatestring = "";

     enddatestring = this.stringifyDate(new Date(this.newEndDate).getFullYear(), new Date(this.newEndDate).getMonth(), new Date(this.newEndDate).getDate(), Number(this.venueSelectedEndTimeHours), Number(this.venueSelectedEndTimeMins));

    this.VenueClosing.endDate = enddatestring;

    if(this.VenueClosing.startDate > this.VenueClosing.endDate)
    {
      AllValid = false;
      this.venueDateLoginValid = false;
    }
    else
    {
      this.venueDateLoginValid = true;
    }

    return AllValid
  }

  stringifyDate(Year: number, Month: number, Day: number, Hour: number, Mins: number): string
  {
    let returnString = "";

    returnString = Year + "-" +  ('00' + (Month + 1).toString()).slice(-2) + "-" +  ('00' + Day.toString()).slice(-2)+ "T" + ('00' + Hour.toString()).slice(-2) + ":" + ('00' + Mins.toString()).slice(-2) + ":00.0000Z";

    return returnString;
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.VenueClosing.name == null || this.VenueClosing.name == "")
    {
      AllValid = false;
      this.venueNameValid = false;
    }
    else
    {
      this.venueNameValid = true;
    }

    return AllValid;
  }

  DoDateValidCheck()
  {
    let AllValid = true;

    if(this.VenueClosing.startDate == null)
    {
      AllValid = false;
      this.venueStartDateValid = false;
    }
    else
    {
      this.venueStartDateValid = true;
    }

    if(this.VenueClosing.endDate == null)
    {
      AllValid = false;
      this.venueEndDateValid = false;
    }
    else
    {
      this.venueEndDateValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/venueclosings");
  }
}