<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="TimeSet && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Time Set
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Think of time sets as the name of your price lists. You  have your default price list that you run on for most of the year but then the school holidays you operate on a different price list and opening hours. Time sets all you to specify the dates when you switch over to the 'Holiday' prices and times. When a customer selects a date to book the system will check what prices list to use and what times to offer based on that date. 
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Time Set Name</label><a href="#"><span style="margin-left:5px;" class="pi pi-question-circle"></span></a>
                    <input [(ngModel)]="TimeSet.name" id="classname" type="text" [ngStyle]="{'border-color': (timesetNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!timesetNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Time Set name.</div>

                <div class="field mb-4 col-12">
                    <label for="isDefault" class="font-medium">Default</label>
                    <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="TimeSet.isdefault" ></p-selectButton>
                </div>

                <div style="clear:both;"></div>

                <div *ngIf="!TimeSet.isdefault" class="surface-card p-4 shadow-2 border-round" style="width: 100%;">
                    <div class="field mb-4 col-12">
                        <div style="width:45%; margin-right:10px; float: left; margin-bottom:20px;">
                            <label for="holidayopeningname" class="font-medium">Start Date</label>
                            <p-calendar [selectOtherMonths]="true" [(ngModel)]="holidayOpeningStartDate" [locale]="en-GB" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="holidayOpeningStartDate"></p-calendar> 
                        </div>
                        <div style="width:45%; margin-right:10px; float: left; margin-bottom:20px;">
                            <label for="holidayopeningname" class="font-medium">End Date</label>
                            <p-calendar [selectOtherMonths]="true" [(ngModel)]="holidayOpeningEndDate" [locale]="en-GB" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" inputId="holidayOpeningEndDate"></p-calendar> 
                        </div>
                        <div style="width:5%; float: left; margin-top: 31px;">
                            <button pButton type="button" style="height: 50px;width: 90px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="insertDate();">
                                <span class="pi pi-plus" style="margin-right:8px;"></span>
                                <span i18>Add</span>
                            </button>
                        </div>
                        <div *ngIf="!timesetDatesValid" style="clear: both">
                            <div class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>End Date must be after Start Date.</div>
                        </div>
                    </div>
                <div style="clear:both;"></div>
                <p-table #HolidayOpeningsTable 
                [value]="TimeSet.holidayopenings"
                responsiveLayout="scroll"
                datakey="id"
                [selectionPageOnly]="true"
                [reorderableColumns]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th width="40%">Start Date</th>
                            <th width="40%">End Date</th>
                            <th width="5%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-index="rowIndex">
                        <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                            <td>{{data.dateFrom | date : 'dd/MM/yyyy'}}</td>
                            <td>{{data.dateTo | date : 'dd/MM/yyyy'}}</td>
                            <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="deleteData(data.id)" i18>
                                <span class="pi pi-trash" style="margin-right:8px;"></span>
                                <span i18n>Delete</span>
                            </button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>